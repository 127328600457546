<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Exposure</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt="" />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="brightness"
            rangeSliderLabel="Brightness"
            rangeSliderClass="range-blue"
            :minValue="-100"
            :maxValue="100"
            @update="ubrig"
          />
        </div>
        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="contrast"
            rangeSliderLabel="Contrast"
            rangeSliderClass="range-blue"
            :minValue="-100"
            :maxValue="100"
            @update="ucont"
          />
        </div>
        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="highlights"
            rangeSliderLabel="Highlights"
            rangeSliderClass="range-blue"
            :minValue="-100"
            :maxValue="100"
            @update="uhighlights"
          />
        </div>
        <div class="panel_row margin_bottom_15">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="shadows"
            rangeSliderLabel="Shadows"
            rangeSliderClass="range-blue"
            :minValue="-100"
            :maxValue="100"
            @update="ushadows"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div class="half">
            <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Adjust <i>Brightness</i> to increase the overall <br />
            exposure on your photo
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Crank up <i>Contrast</i> to increase both<br />
            the lights and darks and make your photo <br />
            really pop
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            <i>Highlights</i> adjust the details in the<br />
            brighter parts of your photo, while <br />
            <i>Shadows</i> in the darker parts
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import RangeLib from "@/components/RangeLib.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditExposurePanel",
  components: {
    RangeLib,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    return {
      tipsText:
        "Adjusting exposure is the process of making the image brighter or darker.",
      helpIcon: require("@/assets/img/help-icon.svg"),
      brightness: 0,
      contrast: 0,
      shadows: 0,
      highlights: 0,
      exsposure: false,
    };
  },

  beforeDestroy() {
    this.closeCurrentEditor();
  },

  methods: {
    async applyIt() {
      await this.$lib._cache.addToBgCacheUpdating(
        this.$lib.CONST.MAP_CHANGES.BG_EXPOSURE_EFFECT,
        {},
        true,
        { isThumb: true }
      );

      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
    },

    closeIt() {
      this.closeCurrentEditor(this.$lib);
    },
    ubrig(v) {
      this.brightness = v;
      this.updateExposure();
    },
    ucont(v) {
      this.contrast = v;
      this.updateExposure();
    },
    ushadows(v) {
      this.shadows = v;
      this.updateExposure();
    },
    uhighlights(v) {
      this.highlights = v;
      this.updateExposure();
    },
    updateExposure() {
      this.exsposure.setupEffect({
        shadows: this.shadows / 100,
        highlights: this.highlights / 100,
        contrast: this.contrast / 100,
        brightness: this.brightness / 100,
      });
    },
    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },
  },
  mounted() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.exsposure = new this.$lib.effects.ExposureEffect(
      this.$lib.app.stage.children[0]
    );
  },
  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
  },
};
</script>
