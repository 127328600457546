<template>
  <div class="popup" v-custom-drag>
    <div class="popup_header">
      <h3>SHADOW</h3>
      <a class="close_popup_btn" v-on:click="closePopups">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <ColorIput textLabel="Color" :defColor="shadow.color" :changeColorValue="setColor" />
        </div>
        <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="shadow.blur"
            rangeSliderLabel="Blur"
            :changeValue="setBlur"
            @dragEnd="setBlur($event, true)"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :defaultValue="50"
          />
        </div>
        <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="shadow.intensity"
            :changeValue="setIntensity"
            rangeSliderLabel="Intensity"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :defaultValue="100"
          />
        </div>
        <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :changeValue="setOffsetY"
            @dragEnd="setOffsetY($event, true)"
            :rangeSliderValue="shadow.offsetY"
            rangeSliderLabel="Vertical Position"
            rangeSliderClass="range-blue"
            :minValue="-50"
            :maxValue="50"
            :defaultValue="0"
          />
        </div>
        <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :changeValue="setOffsetX"
            :rangeSliderValue="shadow.offsetX"
            @dragEnd="setOffsetX($event, true)"
            rangeSliderLabel="Horizontal Position"
            rangeSliderClass="range-blue"
            :minValue="-50"
            :maxValue="50"
            :defaultValue="0"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ColorIput from "@/components/ColorInput";
import Range from "@/components/Range.vue";

export default {
  name: "PopupTextShadow",
  components: {
    Range,
    ColorIput
  },
  data: () => {
    return {};
  },
  props: {
    popUpVisible: Boolean,
    popUpPropChange: Function,
    popUpLabel: String,
    closePopups: Function,
    shadowChanges: Function,
    shadow: Object
  },
  methods: {
    // changeShadow

    setChanges(toCache) {
      if (toCache) {
        this.$emit("changeShadow", this.shadow);
      } else {
        this.shadowChanges(this.shadow);
      }
    },

    setBlur(value, toCache) {
      value = Number(value);
      this.shadow.blur = value;

      this.setChanges(toCache);
    },

    setOffsetX(value, toCache) {
      value = Number(value);
      this.shadow.offsetX = value;

      this.setChanges(toCache);
    },

    setOffsetY(value, toCache) {
      value = Number(value);
      this.shadow.offsetY = value;

      this.setChanges(toCache);
    },

    setColor(color) {
      this.shadow.color = color.hex;

      this.setChanges();
    },
    
    setIntensity(intensity) {
      this.shadow.intensity = intensity;

      this.setChanges();
    }
  }
};
</script>