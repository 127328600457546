<template>
    <div class="zoom_block zoom_block_component" :class="{ [zoomBlockClass]: true, lock_zoom: lockZoom }">
        <button class="minus_btn" :class="outState" v-on:click="zoomOut" v-tooltip="'Zoom Out'">
            <MinusIcon/>
        </button>
        <button class="percent_btn" v-on:click="toogleZoomList" v-tooltip="'Zoom'">{{ getZoomValue }} %</button>
        <button class="plus_btn" :class="inState" v-on:click="zoomIn" v-tooltip="'Zoom In'">
            <PlusIcon/>
        </button>
        <ul class="hover_dropdown_list zoom-list" v-if="zoomState">
            <li>
                <a v-on:click="setZoomFit('full-screen')">
                    Full Screen
                    <span v-if="!isPremiumAccount" class="inch-icon inch-plus-membership-icon"></span>
                </a>
            </li>
            <li>
                <a v-on:click="setZoom('fit-to-screen')">Fit to Screen</a>
            </li>
            <li v-for="(item, index) in zoomRange" v-bind:key="index">
                <a v-on:click="setZoom(item)">{{ item }}%</a>
            </li>
        </ul>
        <div class="default-closer" @click="zoomState = false" v-if="zoomState"></div>
    </div>
</template>

<script>
    import MinusIcon from "@/assets/img/minus.svg?inline";
    import PlusIcon from "@/assets/img/plus.svg?inline";

    export default {
        name: "ZoomBlock",
        components: {
            MinusIcon,
            PlusIcon,
        },
        props: {
            zoomBlockClass: String,
        },
        data: function () {
            return {
                zoomState: false,
                zoomValue: 100,
                zoomFit: false,
                outState: "enabled",
                inState: "enabled",
                minZoom: 25,
                // lastRenderTimeRequest: Date.now(),
                // timeout: null,
                maxZoom: 400,
                stepZoom: 10,
                zoomRange: [400, 300, 200, 150, 100, 50, 33, 25],
            };
        },
        computed: {
            lockZoom() {
                return this.$store.state.lockZoom;
            },

            isPremiumAccount() {
                return this.$store.state.isPremiumAccount;
            },

            getZoomState() {
                return this.zoomState;
            },

            getZoomStateDashboard() {
                return this.$store.state.global.zoomDashboardCounter;
            },

            getZoomValue() {
                return this.zoomValue;
            },

            fitToScreenComp() {
                return this.$store.state.fitToScreenComp;
            }
        },

        watch: {
            fitToScreenComp(value) {
                this.setZoom('fit-to-screen');
            },

            getZoomStateDashboard() {
                const zoom = this.$store.state.global.zoomDashboard;
                this.zoomValue = Math.floor(zoom * 100);
                this.setGlobalZoom(this.zoomValue);

                // this.$store.state.global.zoom = this.zoomValue;
                // this.checkImagePosition();
            },
        },
        methods: {
            async zoomOut() {
                this.enableButtons();
                if (this.zoomValue > this.minZoom) {
                    this.outState = "enabled";
                    this.zoomValue =
                        this.zoomValue - this.stepZoom < this.minZoom
                            ? this.minZoom
                            : this.zoomValue - this.stepZoom;
                } else {
                    this.outState = "disabled";
                }

                this.setGlobalZoom(this.zoomValue, true);
            },

            zoomIn() {
                this.enableButtons();
                if (this.zoomValue < this.maxZoom) {
                    this.inState = "enabled";
                    this.zoomValue =
                        this.zoomValue + this.stepZoom > this.maxZoom
                            ? this.maxZoom
                            : this.zoomValue + this.stepZoom;
                } else {
                    this.inState = "disabled";
                }

                this.setGlobalZoom(this.zoomValue, true);
            },

            toogleZoomList() {
                this.zoomState = !this.zoomState;
            },

            getZoomFitScreen(offset = 0.9) {
                const el = document.querySelector(".drag-wrapper");
                const width = this.$lib.app.stage.width / this.$lib.app.zoomProcentage;
                const height = this.$lib.app.stage.height / this.$lib.app.zoomProcentage;

                return Math.floor(
                    100 *
                    this.$lib.app.getScaleToSize(
                        width, height,
                        el.clientWidth * offset,
                        el.clientHeight * offset
                    )
                );
            },

            checkImagePosition(isRe = false) {
                if (!isRe) return;

                $('.centered-content').css({left: 0, top: 0});
                this.$store.state.currentZoomPosition = {
                    x: 0, y: 0, zoom: this.$store.state.global.zoom / 100
                };
                // const imageEl = document.querySelector(".editing_img_wrpr");
                // const centerY = (this.$lib.app.stage.height - imageEl.clientHeight) / 2;
                // const centerX = (this.$lib.app.stage.width - imageEl.clientWidth) / 2;
                // let top = Number((imageEl.left || "0px").replace("px", ""));
                // let left = Number((imageEl.top || "0px").replace("px", ""));

                // if (centerY <= 0 || centerX <= 0) {
                //   left = null;
                //   top = null;
                // } else {
                //   if (
                //     top >= imageEl.clientHeight / 2 + centerY ||
                //     top <= imageEl.clientHeight / 2 - centerY
                //   ) {
                //     top = imageEl.offsetTop;
                //   }

                //   if (
                //     left >= imageEl.clientWidth / 2 + centerX ||
                //     left <= imageEl.clientWidth / 2 - centerX
                //   ) {
                //     left = imageEl.offsetLeft;
                //   }
                // }

                // imageEl.setAttribute(
                //   "style", `${top ? "top: " + top + "px; " : ""} ${
                //     left ? "left: " + left + "px; " : ""
                //   }`
                // );
            },

            setZoom(value) {
                if (value == "fit-to-screen") {
                    value = this.getZoomFitScreen();
                    // return;
                }

                this.zoomValue = value;
                value == "25"
                    ? (this.outState = "disabled")
                    : (this.outState = "enabled");
                value == "400" ? (this.inState = "disabled") : (this.inState = "enabled");
                this.setGlobalZoom(value, true);
            },

            enableButtons() {
                this.outState = "enabled";
                this.inState = "enabled";
            },

          setFabricZoom(value) {
                let zoom = _LIB.app.zoomProcentage;// + icr / 100;

                if (zoom != 1) {
                    _LIB.app.scaleStage(1);
                    _LIB.app.setZoomProcentage(1, true);
                }

                let imageEl = $(".centered-content");
                imageEl.css({'transform': `scale(${value})`});

                this.$lib.app.setFabricZoom(value);
            },

            setGlobalZoom(value, isRest = false) {
                this.$store.dispatch("zoomToggle", value);
                this.$lib.app.zoomPercentage = value
                const zoom = this.zoomValue / 100;
                if (isRest) {
                    this.setFabricZoom(zoom);
                    // if(zoom >= 1) {
                    //   this.setCssZoom(zoom);
                    // } else {
                    //   this.$lib.app.scaleStage(zoom);
                    //   this.$lib.app.setZoomProcentage(zoom, true);
                    //   $(".centered-content").css({ 'transform': `` });
                    // }
                }

                // if(isRender) console.log('REWNDER');

                // this.lastRenderTimeRequest = Date.now();
                // clearTimeout(this.timeout);
                // if(!isRender) {
                //   this.timeout = setTimeout(() => this.setGlobalZoom(value, isRest), 6);
                // }

                const fitScreenZoom = this.getZoomFitScreen(1);

                this.$lib.fabric.changeZoomMode(fitScreenZoom < value);
                this.checkImagePosition(isRest);
            },

            async setZoomFit(value) {
                if (value == "full-screen") {

                    let is_iframe = (window.location != window.parent.location);

                    try {
                        var ev_data = {
                            cartoon_event: "cartoonize_requst_fullscreen",
                            fullscreen: document.fullscreenElement ? true : false
                        }
                        var location_url = (window.location != window.parent.location) ? document.referrer : document.location;
                        if (window.parent) {
                            window.parent.postMessage(ev_data, location_url);
                        }
                    } catch (e) {
                        console.error(e);
                    }

                    const isPremium = await this.$store.dispatch("isPremiumCurrentUser");
                    if (isPremium) {
                        if (!document.fullscreenElement) {
                            this.requestFullscreen();
                        } else {
                            this.exitFullscreen();
                        }
                    } else {
                        // if (window.abVariant && window.abVariant == 2) {
                            this.$store.dispatch("popupsToggle", {
                                property: "stripeCheckoutPopup",
                                value: true,
                            });
                        // }
                        // else{
                        //     this.$store.dispatch("popupsToggle", {
                        //         property: "upgradePopup",
                        //         value: true,
                        //     });
                        // }
                    }
                }

                this.zoomFit = value;
                this.setGlobalZoom(value);
            },

            requestFullscreen() {
                const elem = document.body;

                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            },

            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE/Edge */
                    document.msExitFullscreen();
                }
            },
        },
    };
</script>

<style scoped>
    .lock_zoom {
        pointer-events: none;
        opacity: .7;
    }
</style>
