<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Face to Sticker</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    Face to Sticker
                </a>
            </div>

            <PanelAccordionAi accordionTitle="Advanced" :accordActiveClass="false" accordAdditionalClass="with-border" accordBodyClass="pb-8">
                <template v-slot:panel_accordion_body>
                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Prompt</label>
                            <input type="text" class="text_input input_width_height" v-model="payload.prompt"/>
                        </div>
                    </div>

                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Negative Prompt</label>
                            <input type="text"  class="text_input input_width_height" v-model="payload.negative_prompt"/>
                        </div>
                    </div>

                    <div class="d-flex between two-column panel_row">
                        <div class="half">
                            <label class="text_label has_mb">Width</label>
                            <div class="btns_wrpr">
                                <InputWidthHeight
                                    :isChangeInputValueIncrement="true"
                                    :inputValue="payload.width"
                                    :maxValue="1024" :minValue="24"
                                    @change="changeRange('width')(parseInt($event.value))"
                                    inputName="width"
                                />
                            </div>
                        </div>

                        <div class="half">
                            <label class="text_label has_mb">Height</label>
                            <div class="btns_wrpr">
                                <InputWidthHeight
                                    :isChangeInputValueIncrement="true"
                                    :inputValue="payload.height"
                                    :maxValue="1024" :minValue="24"
                                    @change="changeRange('height')(parseInt($event.value))"
                                    inputName="height"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Steps</label>
                            <div class="btns_wrpr">
                                <div class="input_width_height_wrpr">
                                    <InputWidthHeight
                                        :isChangeInputValueIncrement="true"
                                        :inputValue="payload.steps"
                                        :maxValue="30" :minValue="1"
                                        @change="changeRange('steps')($event.value)"
                                        inputName="steps"
                                    />
                                </div>
                            </div>
                            <!-- <input type="number" min="1" class="text_input input_width_height" v-model="payload.steps"/> -->
                        </div>
                    </div>
                    
                    <!-- <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Seed</label>
                            <input type="number" min="0" class="text_input input_width_height" v-model="payloadTemp.seed"/>
                        </div>
                    </div> -->
                    
                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Prompt Strength</label>
                            <div class="btns_wrpr">
                                <div class="input_width_height_wrpr">
                                    <InputWidthHeight
                                        :isChangeInputValueIncrement="true"
                                        :inputValue="payload.prompt_strength"
                                        :maxValue="20" :minValue="1"
                                        @change="changeRange('prompt_strength')($event.value)"
                                        inputName="prompt_strength"
                                    />
                                </div>
                            </div>

                            <!-- <input type="number" min="1" class="text_input input_width_height" v-model="payload.prompt_strength"/> -->
                        </div>
                        
                        <span class="custom-label-text">Higher CFG scale numbers strengthen the prompt, while lower numbers maintain original likeness.</span>
                    </div>

                    <div class="panel_row">
                        <Range
                            rangeSliderId="instant_id_strength"
                            :rangeSliderValue="payload.instant_id_strength"
                            rangeSliderLabel="Instant ID Strength"
                            rangeSliderClass="range-blue"
                            :minValue="0.1" :stepSlider="0.01" :maxValue="1"
                            @dragEnd="changeRange('instant_id_strength')($event)"
                            :changeValue="changeRange('instant_id_strength')"
                        />
                        <span class="custom-label-text">How strong the InstantID will be.</span>
                    </div>
                    
                    <div class="panel_row">
                        <Range
                            rangeSliderId="ip_adapter_weight"
                            :rangeSliderValue="payload.ip_adapter_weight"
                            rangeSliderLabel="IP Adapter Weight"
                            rangeSliderClass="range-blue"
                            :minValue="0.1" :stepSlider="0.01" :maxValue="1"
                            @dragEnd="changeRange('ip_adapter_weight')($event)"
                            :changeValue="changeRange('ip_adapter_weight')"
                        />
                        <span class="custom-label-text">How will the IP adapter affect the image.</span>
                    </div>
                    
                    <div class="panel_row">
                        <Range
                            rangeSliderId="ip_adapter_noise"
                            :rangeSliderValue="payload.ip_adapter_noise"
                            rangeSliderLabel="IP Adapter Noise"
                            rangeSliderClass="range-blue"
                            :minValue="0.1" :stepSlider="0.01" :maxValue="1"
                            @dragEnd="changeRange('ip_adapter_noise')($event)"
                            :changeValue="changeRange('ip_adapter_noise')"
                        />
                        <span class="custom-label-text">Noise added to the IP adapter input.</span>
                    </div>
					<!--
                    <div class="panel_row margin_bottom_15">
                        <ToggleCheck
                            toggleCheckLabel="Upscale"
                            :thisToggleCheckChecked="payload.upscale"
                            @toggleCheckHandle="checkToggle('upscale', $event)"
                        />

                        <span class="custom-label-text">2x upscale the sticker</span>
                    </div>
                    -->

                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Upscale Steps</label>
                            <div class="btns_wrpr">
                                <div class="input_width_height_wrpr">
                                    <InputWidthHeight
                                        :isChangeInputValueIncrement="true"
                                        :inputValue="payload.upscale_steps"
                                        :maxValue="20" :minValue="1"
                                        @change="changeRange('upscale_steps')($event.value)"
                                        inputName="upscale_steps"
                                    />
                                </div>
                            </div>

                            <!-- <input type="number" min="1" class="text_input input_width_height" v-model="payload.upscale_steps"/> -->
                        </div>
                    </div>
                </template>
            </PanelAccordionAi>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Convert facial images into fun, customizable stickers.']"
            filename="Face_to_Sticker"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
          payloadTemp: {
            seed: 0,
          },
          payload: {
            "steps": 20,
            "width": 1024,
            "height": 1024,
            "prompt": "a person",
            "upscale": false,
            "upscale_steps": 10,
            "negative_prompt": "",
            "prompt_strength": 7,
            "ip_adapter_noise": 0.5,
            "ip_adapter_weight": 0.2,
            "instant_id_strength": 1
        }
      };
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.FaceToStickerAPI;
        }
    },
    
    methods: {

    	async validateFaces(){
		    let blob = await this.$aitool.getCurrentBlobImage();
		    let b64 = await this.blobToB64(blob);
		    let faces = await this.detectFaces(b64);
		    if(faces>1){
				return {success: false, error: "More than one face detected in the image. Please upload an image that contains exactly one face."};
		    }
		    


			return {success: true};
    	},


        async generatePayload(image) {
            // Math.floor(Math.random() * (2 ** 31)) - (2 ** 31) / 2
            return { ...this.payload, image, seed: 0 };
        },
    }
  };
</script>
