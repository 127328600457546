<template>
    <div class="popup masks_popup" v-if="popUpVisible" v-custom-drag>
        <div class="popup_header">
            <h3>{{popUpLabel}}</h3>
            <a class="close_popup_btn" v-on:click="popUpPropChange(popUpLabel)">
                <span class="inch-icon inch-close-icon"></span>
            </a>
        </div>
        <div class="popup_body">
            <div class="popup_body_row">
                <div class="popup_body_row_item">
                    <ColorInput
                            :changeColorValue="changeColor"
                            :defColor="popMaskConfig.color || `#FFFFFF`"
                            textLabel="Color Overlay"
                            :checkmarkDisabled="true"
                            :suckerHide="true"
                    />
                </div>
                <div class="popup_body_row_item">
                    <Range
                            rangeSliderId="editOpacity"
                            :changeValue="changeBlur"
                            :rangeSliderValue="popMaskConfig.blur || 0"
                            rangeSliderLabel="Blur"
                            rangeSliderMeasure="%"
                            rangeSliderClass="range-blue"
                            :minValue="0"
                            :defaultValue="freezePopMaskConfig.blur || 0"
                            :maxValue="100"
                    />
                </div>
                <div class="popup_body_row_item">
                    <Range
                            rangeSliderId="editOpacity"
                            :changeValue="changeSize"
                            :rangeSliderValue="popMaskConfig.size || 0"
                            rangeSliderLabel="Size"
                            rangeSliderMeasure="%"
                            rangeSliderClass="range-blue"
                            :minValue="0"
                            :defaultValue="freezePopMaskConfig.size || 0"
                            :maxValue="100"
                    />
                </div>
                <div class="popup_body_row_item" v-if="popMaskConfig.mode != 'pattern'">
                    <Range
                            rangeSliderId="editOpacity"
                            :changeValue="changeRotation"
                            :rangeSliderValue="popMaskConfig.rotation || 0"
                            rangeSliderLabel="Rotation"
                            rangeSliderMeasure="°"
                            rangeSliderClass="range-blue"
                            :minValue="0"
                            :defaultValue="freezePopMaskConfig.rotation || 0"
                            :maxValue="360"
                    />
                </div>
                <div class="popup_body_row_item">
                    <div class="btns-group mt0">
                        <a tabIndex="0" class="grey_btn" v-on:click="popUpPropChange(popUpLabel)">Cancel</a>
                        <a tabIndex="0" @click="$emit('applyMask')" class="blue_btn">Apply</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Range from "@/components/Range.vue";
    import ColorInput from "@/components/ColorInput.vue";

    export default {
        name: "PopupMasks",
        props: {
            popUpVisible: Boolean,
            popUpPropChange: Function,
            popUpLabel: String,
            popMaskConfig: Object,
            freezePopMaskConfig: Object
        },
        components: {
            Range,
            ColorInput
        },
        created: function () {
            console.log(this.popMaskConfig.size)
        },
        methods: {
            emitChanges() {
                this.$emit("setupMaskParams", this.popMaskConfig);
            },

            changeColor({hex}) {
                this.popMaskConfig.color = hex;

                this.emitChanges();
            },

            changeOpacity(opacity) {
                this.popMaskConfig.opacity = opacity;

                this.emitChanges();
            },

            changeBlur(blur) {
                this.popMaskConfig.blur = blur;

                this.emitChanges();
            },

            changeSize(size) {
                this.popMaskConfig.size = size;

                this.emitChanges();
            },

            changeRotation(rotation) {
                this.popMaskConfig.rotation = rotation;

                this.emitChanges();
            }
        }
    };
</script>
