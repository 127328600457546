<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Inpainting & Removal</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
            <div class="d-flex between two-column panel_row custom-wd-half">
                <label class="label-half-btn">Inpainting Tools</label>
                <div class="half">
                    <a tabIndex="0" class="button btn-action cancel" @click="initDraw('drawing')" :class="{ active: this.drawParams.isDrawing }">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3257 0.678838C14.9892 0.340261 14.556 0.114127 14.0858 0.0314617C13.6156 -0.0512035 13.1314 0.0136498 12.6995 0.217124C12.4119 0.352022 12.1552 0.544577 11.9452 0.782838L5.86746 7.58627C6.44758 7.84179 6.97059 8.21101 7.40558 8.67211C7.84057 9.13321 8.17872 9.67683 8.40003 10.2708L15.2229 4.08798C15.459 3.87677 15.6497 3.61971 15.7833 3.33246C15.9169 3.04522 15.9906 2.73378 16 2.41712C16.0123 2.0967 15.9587 1.77716 15.8428 1.4782C15.7268 1.17925 15.5509 0.907203 15.3257 0.678838ZM6.16346 14.3988L6.18403 14.3783C4.56803 15.984 1.93032 16.592 0.192032 15.3006C-0.0559679 15.1177 -0.0502536 14.7611 0.141746 14.5188C0.980604 13.4606 0.902889 12.656 0.829746 11.9086C0.758889 11.1794 0.693746 10.5074 1.48917 9.71312C1.79019 9.37782 2.15641 9.10741 2.56547 8.91843C2.97452 8.72945 3.41782 8.62587 3.86826 8.61402C4.31871 8.60216 4.76684 8.68228 5.18527 8.84948C5.6037 9.01669 5.98364 9.26745 6.30187 9.58646C6.6201 9.90547 6.86995 10.286 7.03612 10.7049C7.2023 11.1237 7.28133 11.572 7.26837 12.0224C7.25542 12.4729 7.15076 12.9159 6.96078 13.3245C6.7708 13.7331 6.4995 14.0986 6.16346 14.3988Z" fill="#D7DCDD"/>
                        </svg>
                    </a>
                </div>

                <div class="half">
                    <a tabIndex="0" class="button btn-action cancel" @click="initDraw('eraser')" :class="{ active: this.drawParams.isEraser }">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9042 4.61303L11.9575 1.66631C11.2904 0.99925 10.2088 0.99925 9.54171 1.66631L1.09587 10.1122C0.428817 10.7793 0.428817 11.8608 1.09587 12.5279L3.23545 14.6675C3.34268 14.7746 3.48818 14.8345 3.63973 14.834H14.2636C14.7019 14.834 14.9759 14.3595 14.7567 13.9799C14.655 13.8037 14.467 13.6952 14.2636 13.6952H8.23491L14.9042 7.02877C15.5712 6.36167 15.5712 5.28013 14.9042 4.61303ZM14.0977 6.22163L10.2777 10.0431L6.52738 6.2928L10.3489 2.47346C10.5713 2.25079 10.9322 2.25079 11.1546 2.47346L14.0999 5.41875C14.3221 5.64109 14.3221 6.00142 14.0999 6.22376L14.0977 6.22163Z" fill="#D7DCDD"/>
                        </svg>
                    </a>
                </div>
            </div>

            <div class="panel_row">
                <Range
                    rangeSliderId="BrushSize"
                    :rangeSliderValue="drawParams.width"
                    rangeSliderLabel="Brush Size"
                    rangeSliderMeasure="%"
                    rangeSliderClass="range-blue"
                    :minValue="1"
                    :maxValue="100"
                    :changeValue="setBrushSize"
                    @dragEnd="setBrushSize($event, true)"
                    :defaultValue="10"
                />
            </div>
            
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="processed" :class="{ 'disabled-o': !drawingMask }">
                    Inpaint & Remove
                </a>
            </div>

            <PanelAccordionAi accordionTitle="Advanced" :accordActiveClass="false" accordAdditionalClass="with-border" accordBodyClass="pb-8">
                <template v-slot:panel_accordion_body>
                    <div class="d-flex between two-column panel_row">
                        <div class="half">
                            <div class="or_half-image">
                                <img :src="targetImage" alt="target image"/>
                                <div class="bt_label_image">Target image</div>
                            </div>
                        </div>
                        
                        <div class="half">
                            <div class="or_half-image">
                                <span class="close-x-btn" @click="removeSelectedMask" v-if="drawingMask">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.3" clip-path="url(#clip0_19_309)">
                                        <path d="M14.0625 3.9375L3.9375 14.0625" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.0625 14.0625L3.9375 3.9375" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_19_309">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </span>

                                <img :src="drawingMask" v-if="drawingMask"/>
                                <div v-else class="img-placeholder"><span>Use tools to create a mask</span></div>
                                <div class="bt_label_image">Mask for inpainting</div>
                            </div>
                        </div>
                    </div>

                    <div class="panel_row">
                        <a class="button btn-action-all btn-action duplicate" style="width: 100%;" @click="resetDrawing(true)" :class="{ 'disabled-o': !isEnabledDrawing || !hasDrawingElements }">
                            Reset
                        </a>
                    </div>
                </template>
            </PanelAccordionAi>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Remove unwanted objects or imperfections and fill in the gaps seamlessly.']"
            filename="Inpainting_Removal"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {
            mask: false
        },
        
        drawingMask: null,
        targetImage: null,
        hasDrawingElements: false,

        drawParams: {
            isDrawing: false,
            isEraser: false,
            width: 10,
            opacity: 100,
            softness: 0, // 100
            color: "#ffffff",
            brush: "Simple",
        },
        maxWidthBrush: 140,
        isEnabledDrawing: false,
        intervalCheck: false,
        pathsAmount: 0
      };
    },

    beforeDestroy() {
        this.$lib.Drawing.initDraw(false);
    },

    mounted() {
        this.targetImage = this.$aitool.extractOptimizedThumb(216, 216);
        this.maxWidthBrush = Math.min(
            this.$lib.app.stage.width / this.$lib.app.zoomProcentage,
            this.$lib.app.stage.height / this.$lib.app.zoomProcentage
        ) * 0.5;

        this.intervalCheck = setInterval(() => {
            if(!this.isEnabledDrawing) return false;
            const count = this?.$lib?.Drawing?.getDrawingChanges()?.length;

            this.hasDrawingElements = !!count;
            if(this.pathsAmount !== count) this.applyMask();

            this.pathsAmount = count;
        }, 500);
    },

    methods: {
        setBrushSize(value, isDragEnd) {
            this.$lib.Drawing.setSize((value / 100) * this.maxWidthBrush, !isDragEnd);
            this.drawParams.width = value;
        },


        removeSelectedMask() {
            if(this.drawingMask) URL.revokeObjectURL(this.drawingMask);

            this.drawingMask = false;
            this.payload.mask = false;
        },

        async applyMask(isFull = false) {
            const mask = await this.$aitool.getDrawingBlobMask(isFull);
            //this.resetDrawing();

            this.payload.mask = mask;
            if(this.drawingMask) URL.revokeObjectURL(this.drawingMask);
            this.drawingMask = URL.createObjectURL(mask);
        },

        async processed() {
            await this.resetDrawing();
            await this.applyIt();
        },

        resetDrawing(justRemoveElements = false) {
            if(justRemoveElements) {
                this.$lib.Drawing.getDrawingChanges().forEach(obj => this.$lib.Drawing.canvas.remove(obj));

                return false;
            }

            this.drawParams = {
                isDrawing: false,
                isEraser: false,
                width: 10,
                opacity: 100,
                softness: 0, // 100
                color: "#ffffff",
                brush: "Simple",
            };

            this.isEnabledDrawing = false;
            this.$lib.Drawing.initDraw(false);
        },

        async changeDrawingType(type) {
            if (type == "eraser") {
                await this.$lib.Drawing.setEraser();
                this.drawParams.isDrawing = false;
                this.drawParams.isEraser = true;
            } else {
                this.$lib.Drawing.setBrush(this.$lib.Drawing.brush);
                this.drawParams.isDrawing = true;
                this.drawParams.isEraser = false;
            }
        },

        initDraw(type = 'eraser') {
            if(this.isEnabledDrawing) return this.changeDrawingType(type);

            this.resetDrawing();
            this.$lib.Drawing.payloadCursor = {};
            this.$lib.Drawing.initDraw(true, {
                color: '#ffffff',
                width: this.$lib.lib.conv(this.drawParams.width, 1, this.maxWidthBrush),
                brush: this.drawParams.brush.toLowerCase(),
                opacity: this.drawParams.opacity / 100,
                maxWidthBrush: this.maxWidthBrush,
                softness: this.drawParams.softness,
            });

            this.isEnabledDrawing = true;
            this.changeDrawingType(type);
        },

        async generatePayload(image) {
            await this.applyMask(true);
            const mask = await this.$aitool.getCurrentStateImage(this.payload.mask);

            return { image, mask };
        }
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.ObjectRemovalAPI;
        }
    }
  };
</script>

<style scoped>
    .bt_label_image {
        font-family: Proxima Nova;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.62px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #A4A2A2;
    }

    .or_half-image {
        position: relative;
    }
    
    .or_half-image > .close-x-btn {
        position: absolute;
        right: 3px;
        top: 3px;
        opacity: .8;
        transition: .3s;
    }

    .or_half-image > .close-x-btn:hover {
        opacity: 1;
        transition: .3s;
    }

    .or_half-image > img {
        width: 104px;
        height: 104px;
        border-radius: 4px 0px 0px 0px;
        background: #000000;
        object-fit: cover;
    }

    .or_half-image > .img-placeholder {
        font-family: Proxima Nova;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.62px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #A4A2A2;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 104px;
        height: 104px;
        padding: 12px 0px 0px 0px;
        border-radius: 4px 0px 0px 0px;
        background: #303030;
        margin-bottom: 5px;
    }

    .or_half-image > .img-placeholder > span {
        width: 84px;
        user-select: none;
    }

    .label-half-btn {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.05px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #A4A2A2;
    }

    .custom-wd-half > .half {
        width: calc(50% - 2px);
    }

    .custom-wd-half .button.active {
        background: #303030;
    }
</style>
