<template>
    <div class="tool-item-panel" :class="sidebarPanelClass">
        <div class="panel-top mb-10 without_hover">
            <div class="back">
                <h3>AI Tools</h3>
            </div>

            <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
                <img src="@/assets/img/help-icon.svg" alt />
            </div>
        </div>

        <div v-bar>
            <div class="panel-content wo_pad">
                <ul class="menu-edit">
                    <li v-for="(item, i) in tools" v-bind:key="i">
                        <a href="#" v-on:click="toolLinkClick(item.title, item.premium)" :class="{ 'disabled-o': item.disabled }">
                            <span class="inch-icon" v-html="item.svg"></span>
                            {{item.title}}
                            
                            <span class="ai-content"><sup>AI</sup></span>
                            <span v-if="item.premium && (!isPremium || !_allowAiTools)" class="inch-icon inch-plus-membership-icon ai"></span>
                        </a>
                    </li>
                </ul>

                <AdLeftPanel v-if="isLeftAd" margin="0 14px"/>
            </div>
        </div>

        <transition name="panel-slide" v-on:after-enter="afterEnter">
            <FaceRestoration
                v-if="editPanelOpened === 'Face Restoration'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <BackgroundRemoval
                v-if="editPanelOpened === 'Background Removal'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <ImageUpscale
                v-if="editPanelOpened === 'Image Upscale'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <FaceToSticker
                v-if="editPanelOpened === 'Face to Sticker'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <PhotoToAnime
                v-if="editPanelOpened === 'Photo to Anime'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <ImageDeoldify
                v-if="editPanelOpened === 'Image De-oldify'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <ImageToPrompt
                v-if="editPanelOpened === 'Prompt Generation'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
            
            <ObjectRemoval
                v-if="editPanelOpened === 'Inpainting & Removal'"
                :editPanelPropChange="editPanelPropChange"
                :editPanelClass="editPanelClass"
                :applyChanges="applyChanges"
                :closeCurrentEditor="closeCurrentEditor"
            />
        </transition>
    </div>
</template>

<script>
    import EditCropPanel from "@/components/sidebarPanel/editPanel/EditCropPanel.vue";
    
    import FaceRestoration from "./FaceRestoration.vue";
    import BackgroundRemoval from "./BackgroundRemoval.vue";
    import ImageUpscale from "./ImageUpscale.vue";
    import FaceToSticker from "./FaceToSticker.vue";
    import PhotoToAnime from "./PhotoToAnime.vue";
    import ImageDeoldify from "./ImageDeoldify.vue";
    import ImageToPrompt from "./ImageToPrompt.vue";
    import ObjectRemoval from "./ObjectRemoval.vue";
    import { trackMxEvent } from '@/libs/mixpanel.js';

    import AdLeftPanel from "@/components/AdLeftPanel.vue";

    const analitics = require('../../../libs/analytics');

    export default {
        name: "EditPanel",
        props: {
            sidebarPanelClass: String
        },
        components: {
            EditCropPanel,
            AdLeftPanel,

            FaceRestoration,
            BackgroundRemoval,
            ImageUpscale,
            FaceToSticker,
            PhotoToAnime,
            ImageDeoldify,
            ImageToPrompt,
            ObjectRemoval
        },
        //<img :src='require("")'/>
        data() {
            return {
                editPanelOpened: "",
                editPanelClass: "",
                tools: [{
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12_17)">
                    <path d="M13.1801 13.5C12.7602 12.1908 11.9355 11.0488 10.8249 10.2386C9.71416 9.4284 8.37487 8.99182 7.00007 8.99182C5.62526 8.99182 4.28597 9.4284 3.17528 10.2386C2.0646 11.0488 1.23989 12.1908 0.820068 13.5H13.1801Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 9C9.3373 9 11.2321 7.10524 11.2321 4.76794C11.2321 2.43064 9.3373 0.535889 7 0.535889C4.6627 0.535889 2.76794 2.43064 2.76794 4.76794C2.76794 7.10524 4.6627 9 7 9Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.38196 6.4054C8.38196 6.4054 8.03081 7.09634 7.00006 7.09634C5.96931 7.09634 5.61816 6.4054 5.61816 6.4054" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.1554 3.96219C11.1459 3.96225 11.1365 3.96228 11.1271 3.96228C9.89693 3.96228 8.7925 3.42409 8.03637 2.57033C7.28025 3.42411 6.17581 3.96231 4.94566 3.96231C4.23132 3.96231 3.55936 3.78082 2.97339 3.46144C3.52393 1.76353 5.11856 0.535889 6.99993 0.535889C9.0617 0.535889 10.7791 2.01026 11.1554 3.96219Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>

                    <clipPath id="clip0_12_17">
                        <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Face Restoration'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12_85)">
                    <path d="M5.49359 6.65533L9.41229 1.75697C10.1621 0.819703 11.5601 0.742273 12.4089 1.591C13.2576 2.43972 13.1802 3.83775 12.2429 4.58755L7.18482 8.634" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.12572 7.27807C3.53993 5.86386 5.3077 6.21741 6.36836 7.27807C7.42902 8.33873 7.78257 10.1065 6.36836 11.5207C4.95415 12.9349 2.8764 13.0947 1.06506 12.5814C2.45728 11.1891 0.585552 8.81824 2.12572 7.27807Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_12_85">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Inpainting & Removal'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12_104)">
                    <path d="M10.8565 8.65279L13.0602 6.44908" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.9583 10.3056L13.0602 9.2037" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.4074 5.34722L13.0602 3.69444" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.0602 0.939819L10.581 3.41899" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.3056 0.939819L9.20374 2.04167" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.86806 8.37732L0.939819 10.3056" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.31713 6.17361L0.939819 7.55093" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.55094 0.939819L6.72455 1.76621" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.7963 0.939819L0.939819 4.7963" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.04167 0.939819L0.939819 2.04167" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.31012 8.8807C9.29911 8.38184 9.97741 7.35695 9.97741 6.17362C9.97741 4.50015 8.62078 3.14352 6.94732 3.14352C5.27385 3.14352 3.91723 4.50015 3.91723 6.17362C3.91723 7.35695 4.59553 8.38184 5.58455 8.8807C4.20269 9.35885 3.15552 10.5438 2.88428 11.9984C2.79665 12.4683 3.0703 12.8526 3.54441 12.914C4.1217 12.9887 5.14502 13.0602 6.94732 13.0602C8.74965 13.0602 9.77296 12.9887 10.3502 12.914C10.8243 12.8526 11.098 12.4683 11.0104 11.9984C10.7391 10.5438 9.69198 9.35885 8.31012 8.8807Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_12_104">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Background Removal'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_13_127)">
                    <path d="M7.75 9.25C6.25 9.25 4 10 4 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 7.75C1 7.35217 1.15804 6.97064 1.43934 6.68934C1.72064 6.40803 2.10217 6.25 2.5 6.25H6.24999C6.64782 6.25 7.02935 6.40803 7.31065 6.68934C7.59196 6.97064 7.74999 7.35217 7.74999 7.75V11.5C7.74999 11.8978 7.59196 12.2793 7.31065 12.5607C7.02935 12.842 6.64782 13 6.24999 13H2.5C2.10217 13 1.72064 12.842 1.43934 12.5607C1.15804 12.2793 1 11.8978 1 11.5V7.75Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 3.25V1.75C1 1.55109 1.07902 1.36032 1.21967 1.21967C1.36032 1.07902 1.55109 1 1.75 1H3.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.25 1H7.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.75 1H12.25C12.4489 1 12.6397 1.07902 12.7803 1.21967C12.921 1.36032 13 1.55109 13 1.75V3.25" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 6.25V7.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 10.75V12.25C13 12.4489 12.921 12.6397 12.7803 12.7803C12.6397 12.921 12.4489 13 12.25 13H10.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_13_127">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Image Upscale'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.80699 13.0161H10.1927C10.7654 13.0161 11.221 12.5449 11.1594 12.002C10.7617 8.504 8.38821 8.56185 8.38821 6.99997C8.38821 5.4381 10.792 5.52489 11.1591 1.99795C11.2169 1.45476 10.7654 0.983887 10.1927 0.983887H3.80699C3.23433 0.983887 2.78397 1.45476 2.84066 1.99795C3.20769 5.52489 5.61155 5.4092 5.61155 6.99997C5.61155 8.5908 3.23807 8.504 2.84066 12.002C2.77877 12.5449 3.23433 13.0161 3.80699 13.0161Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.52496 12.0905H4.48732C4.03612 12.0905 3.90885 11.5698 4.2253 11.247C4.9912 10.4707 6.53715 9.91483 6.53715 9.02456V6.07436C6.53715 5.50021 5.43806 5.06203 4.75805 4.13069C4.64585 3.97712 4.65712 3.76047 4.94231 3.76047H9.07059C9.31382 3.76047 9.36646 3.97536 9.2557 4.12926C8.58552 5.06203 7.46271 5.49734 7.46271 6.07436V9.02456C7.46271 9.90762 9.07403 10.3839 9.78817 11.2479C10.0759 11.5961 9.97532 12.0905 9.52496 12.0905Z" fill="currentColor"/>
                    </svg>`,
                    title: 'Image De-oldify'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_13_148)">
                    <path d="M2.60994 12.6318C1.93809 12.5762 1.4238 12.0616 1.36815 11.3901C1.2946 10.5047 1.21527 9.06983 1.21527 7C1.21527 4.93017 1.2946 3.49529 1.36815 2.60995C1.4238 1.93809 1.93836 1.4238 2.60994 1.36816C3.49528 1.29461 4.93016 1.21528 6.99999 1.21528C9.06982 1.21528 10.5047 1.29461 11.39 1.36816C12.0619 1.4238 12.5762 1.93837 12.6318 2.60995C12.7054 3.49529 12.7847 4.93017 12.7847 7C12.7847 7.80628 12.804 8.58501 12.7847 9.2037L9.2037 12.7847C8.58501 12.804 7.80627 12.7847 6.99999 12.7847C4.93016 12.7847 3.49528 12.7054 2.60994 12.6318Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.20367 8.37732C7.98668 9.84636 6.01326 9.84636 4.79626 8.37732" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.79626 5.07176V5.62269" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.20367 5.07176V5.62269" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.7847 9.2037C11.8346 9.2329 11.1041 9.28414 10.5738 9.33427C9.90555 9.39736 9.3976 9.90558 9.33424 10.5739C9.28411 11.1038 9.23287 11.8347 9.20367 12.7847" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_13_148">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Face to Sticker'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_13_173)">
                    <path d="M2.33333 1C1.97971 1 1.64057 1.14048 1.39052 1.39053C1.14048 1.64058 1 1.97972 1 2.33334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6667 1C12.0203 1 12.3594 1.14048 12.6095 1.39053C12.8595 1.64058 13 1.97972 13 2.33334" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.33333 13C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 1H5.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 13H5.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33337 1H9.00004" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33337 13H9.00004" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 5V5.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 5V5.66667" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 8.33333V9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 8.33333V9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.66663 4.33333H8.99996" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.66663 7H10.3333" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.66663 9.66667H7.66663" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_13_173">
                    <rect width="14" height="14" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`,
                    title: 'Prompt Generation'
                }, {
                    svg: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 1C3.6862 1 1 3.6862 1 7C1 10.3138 3.6862 13 7 13C7.1014 13 7.1998 12.9907 7.3 12.9859C4.4578 12.8287 2.2 10.4806 2.2 7.6C2.2 4.6174 4.6174 2.2 7.6 2.2C10.4812 2.2 12.8293 4.4578 12.9859 7.3C12.9907 7.1998 13 7.1014 13 7C13 3.6862 10.3138 1 7 1ZM7.32461 3.40938C7.09832 3.42411 6.8682 3.45736 6.63555 3.51016C4.98195 3.88516 3.69645 5.28198 3.44805 6.95898C3.03345 9.75918 5.38648 12.1351 8.18008 11.7613C9.57388 11.5747 10.7786 10.6391 11.3852 9.3707C11.7296 8.6501 11.8369 7.95912 11.7895 7.31172C11.7703 7.04652 11.4465 6.90306 11.2563 7.08906C10.8099 7.52526 10.1367 7.73051 9.4293 7.51211C8.8905 7.34591 8.4462 6.91877 8.2668 6.38477C7.9416 5.41817 8.44296 4.5387 9.22656 4.1793C9.41856 4.0911 9.42096 3.81384 9.22656 3.73164C8.64741 3.48594 8.00349 3.36516 7.32461 3.40938Z" fill="currentColor" stroke="none"/>
                    </svg>`,
                    title: 'Photo to Anime'
                }].map(q => ({ ...q, premium: true }))
            };
        },
        computed: {
            panelOpened() {
                return this.editPanelOpened;
            },

            isPremium() {
                return this.$store.state.isPremiumAccount;
            },

            _allowAiTools() {
                return this.$store.state._allowAiTools;
            },

            hash() {
                const hash = this.$route.hash.split('#')[2]
                if(hash) return `${hash[0].toUpperCase()}${hash.substring(1)}`.replace('-',' ');
                return null;
            },

            isLeftAd() {
                return (!this.isPremium && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
            },
        },

        watch: {
            panelOpened(panel) {
                this.$store.state.selectedEditPanel = panel;
            },
        },
        mounted: function() {
            this.openPanelHash();
        },

        async beforeDestroy() {
            this.$store.state.selectedEditPanel = '';
            try {
                if (this.$lib.app.stage.children.length > 1) {
                    this.$lib.app.stage.removeChild(this.$lib.app.stage.children[1]);
                }
            } catch (err) {
            }

            await this.closeCurrentEditor();
            if(this.hash){
                this.$router.push({ hash: "" });
            }
        },

        methods: {
            openPanelHash(){
                if(!this.hash) return
                const selectedEditPanel = [...this.editBasicsItem,...this.editEnhanceItem,...this.editOtherItem].find(item => item.linkText === this.hash)
                if(!selectedEditPanel) return
                this.toolLinkClick(selectedEditPanel.linkText,selectedEditPanel.premium)
            },
            toolLinkClick(panelName, premiumStatus) {

                if (premiumStatus && !this.isPremium) {
                    this.$store.commit("changePremiumStatus", true);
                }
                if (this.editPanelOpened === panelName) {
                    this.editPanelOpened = "";
                    this.editPanelClass = "";
                } else {
                    this.editPanelOpened = panelName;
                    this.editPanelClass = "active";
                    if (this.isPremium) {
                        try {
                            trackMxEvent(`AI Tool ${panelName}`)
                        } catch (error) {
                            // 
                        }
                    }
                    
                    analitics.sendEvent(panelName, analitics.categ.edit);
                }
            },

            applyChanges(/* lib */) {
                this.$store.dispatch("isUpdateCache");
                this.$store.dispatch("fabricSlide", false);
                this.editPanelOpened = "";
                this.editPanelClass = "";
            },

            async closeCurrentEditor(lib) {
                this.$store.dispatch("fabricSlide", false);
                this.editPanelOpened = "";
                this.editPanelClass = "";

                // lib.effects._setFilters(lib.app.stage.children[0].children[0]);
                // lib._cache.renderFromCacheAt(lib._cache.getCacheList().length - 1);

                await this.$lib._cache.renderSaveByTimestamp();
                this.$store.dispatch("isUpdateCache");

                // lib._cache.prev();
            },

            editPanelPropChange() {
                this.editPanelOpened = "";
                this.editPanelClass = "";
                this.$store.commit("changePremiumStatus", false);
            },

            afterEnter() {
                let temp = this.editPanelClass;
                this.editPanelClass = "";
                this.$nextTick(function () {
                    this.editPanelClass = temp;
                    console.log("UPD");
                });
            }
        }
    };
</script>
<style scoped>
.custom-padding{
  padding: 12px 24px !important;
}
</style>
<style>
.svg_icon_remove_background{
  width: 14px;
  height: 13px;
  display: inline-block;
}
.svg_icon_replace_color{
  width: 15px;
  height: 13px;
  display: inline-block;
}
    .inch-tint-icon {

    }

    .menu-edit a:hover svg path {
        stroke: white !important;

    }

    .ai-content {
      top: 583px;
      left: 251px;
      width: 8px;
      height: 9px;
      text-align: center;
      font-style: normal;
      font-variant: normal;
      font-weight: bold;
      font-size: 8px;
      font-family: Proxima Nova;
      letter-spacing: 0.32px;
      color: #4ADABA;
      opacity: 1;
    }
    
    .ai-pl {
        font-family: Proxima Nova;
        font-size: 7px;
        font-weight: 600;
        line-height: 8.53px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #4ADABA;

    }
</style>
