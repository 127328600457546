<template>
  <div>
    <div class="form_loading" v-if="isLoading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!-- Register variant -->
    <div v-if="!isLoggedIn || isSubmitting">
      <div class="form_loading" v-if="isSubmitting"></div>
      <div
        class="popup save_popup upgrade-popup subs_popup reg-var"
        v-if="confirmSubscriptionPopup && isOnline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          viewBox="0 0 36 30"
        >
          <g id="emblem" transform="translate(-586 -239)">
            <path
              id="bg"
              d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
              transform="translate(586 239)"
              fill="#ff9360"
            />
            <path
              id="plus"
              d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
              transform="translate(552.992 245)"
              fill="#fff"
            />
          </g>
        </svg>

        <div :class="{ is_pwa_popup: is_pwa }">
          <div class="content" style="margin-top: 19px; width: 348px">
            <div class="">
              <div class="">
                <h2
                  class="title old_update_popup_1"
                  style="
                    text-align: unset;
                    font-size: 26px;
                    text-align: left;
                    margin-bottom: 24px;
                    letter-spacing: -1.04px;
                  "
                >
                  Upgrade to <span style="color: #fa6400">Pro</span> to unlock all effects
                  & premium features
                </h2>
                <p
                  class="all-plus-future"
                  style="margin-bottom: 32px; margin-left: 0px"
                >
                  Starting at <b>just $6.99/mo</b>, get access to all Pro
                  features for one low price. Pay by month or the year. Cancel
                  anytime.
                </p>
              </div>
            </div>

            <div
              v-if="!is_pwa"
              :style="{ 'text-align': 'center', 'margin-top': '36px' }"
            >
              <div class="">
                <form v-on:submit.prevent="onSubmit" class="reg-form">
                  <div>
                    <input
                      type="email"
                      ref="xemail"
                      :value="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Email address"
                      required
                    />
                  </div>
                  <div style="margin-top: 16px">
                    <input
                      type="password"
                      ref="xpassword"
                      :value="password"
                      class="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      required
                      minlength="5"
                    />
                  </div>
                  <div class="form_errors" v-if="formErrors">
                    <ul id="example-1">
                      <li v-for="item in formErrors">{{ item[0] }}</li>
                    </ul>
                  </div>

                  <div style="margin-top: 48px">
                    <button
                      type="submit"
                      class="btn-orange"
                      style="
                        color: #fff;
                        margin: 0;
                        max-width: 100%;
                        height: 48px;
                        font-size: 12px;
                        box-shadow: 0px 2px 24px #fa640066;
                      "
                    >
                      SIGN UP
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div style="text-align: center; margin-top: 14px">
              <span class="already-member">
                Already a member?
                <a
                  href="#"
                  @click.prevent="showLoginPopUp"
                  style="
                    text-align: left;
                    text-decoration: underline;
                    font: normal normal 300 14px/20px Soleil;
                    letter-spacing: -0.28px;
                    color: #fa6400;
                  "
                >
                  Sign in
                </a>
              </span>
            </div>

            <div class="agree-links">
              <span
                >By signing up, you agree to the Colorcinch
                <a
                  href="https://www.cartoonize.net/privacy-policy/"
                  target="_blank"
                  >Privacy Policy</a
                >
                and
                <a
                  href="https://www.cartoonize.net/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Terms of Service</a
                >.</span
              >
            </div>
          </div>
          <div class="teaser-modal__image"></div>
          <div class="plus-benefits">
            <p
              style="
                font-size: 16px;
                color: #1d1d1d;
                letter-spacing: -0.64px;
                text-align: left;
                font-weight: normal;
                font-family: Soleil;
                margin-bottom: 12px;
              "
            >
              <strong>Pro users also get:</strong>
            </p>
            <ul class="list-plus">
              <li>AI one-click background remover</li>
              <li>Artistic filters, overlays & masks</li>
              <li>Smart editing tools & more</li>
            </ul>
          </div>
          <div>
            <a
              class="close_popup_btn"
              v-on:click="changePopup('backgroundRemoverPopup', false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="x" transform="translate(-198 -160)">
                  <circle
                    id="Ellipse_106"
                    data-name="Ellipse 106"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(198 160)"
                    fill="#000"
                    opacity="0.5"
                  />
                  <g
                    id="x_icon"
                    data-name="x icon"
                    transform="translate(203 165)"
                  >
                    <rect
                      id="Rectangle_435"
                      data-name="Rectangle 435"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(7.308 8) rotate(-135)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_436"
                      data-name="Rectangle 436"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(0 7.308) rotate(-45)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <div id="img-area">
              <div id="img-area2" style="opacity: 0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Normal Variant -->
    <div v-else>
      <div class="form_loading" v-if="isRedirecting"></div>
      <div
        class="popup save_popup upgrade-popup subs_popup"
        v-if="confirmSubscriptionPopup && isOnline && allowAiTools"
      >
        <!--            <div style="margin-bottom: 0; padding-top: 0">-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          viewBox="0 0 36 30"
        >
          <g id="emblem" transform="translate(-586 -239)">
            <path
              id="bg"
              d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
              transform="translate(586 239)"
              fill="#ff9360"
            />
            <path
              id="plus"
              d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
              transform="translate(552.992 245)"
              fill="#fff"
            />
          </g>
        </svg>
        <!--            </div>-->
        <div :class="{ is_pwa_popup: is_pwa }">
          <div class="content" style="margin-top: 19px; width: 340px">
            <div class="">
              <div class="">
                <h2
                  class="title old_update_popup_1"
                  style="
                    text-align: unset;
                    font-size: 26px;
                    text-align: left;
                    margin-bottom: 24px;
                  "
                >
                  Upgrade to Pro to unlock the background remover
                </h2>
                <p
                  class="all-plus-future"
                  style="margin-bottom: 32px; margin-left: 0px"
                >
                  With a single click, instantly remove image backgrounds with
                  precision and the power of AI included in Pro.
                </p>
                <p
                  style="
                    font-size: 16px;
                    color: #1d1d1d;
                    letter-spacing: -0.64px;
                    text-align: left;
                    font-weight: normal;
                    font-family: Soleil;
                    margin-bottom: 20px;
                  "
                >
                  <strong>Pro users also have:</strong>
                </p>
                <ul class="list-plus">
                  <li>Hundreds of AI artsy effects</li>
                  <li>Smart editing tools</li>
                  <li>Premium filters, overlays & masks</li>
                  <li>Extended graphics library</li>
                  <li>Fullscreen mode</li>
                </ul>
              </div>
            </div>

            <div
              v-if="!is_pwa"
              :style="{ 'text-align': 'center', 'margin-top': '36px' }"
            >
              <a
                href="javascript:void(0)"
                @click="redirectToUpgrade"
                class="btn-orange"
                style="color: #fff; margin: 0; max-width: 100%; height: 48px"
                >Upgrade Now</a
              >
            </div>
            <div style="text-align: center; margin-top: 14px">
              <span class="already-member">
                Already a member?
                <a
                  href="#"
                  @click.prevent="showLoginPopUp"
                  style="
                    text-align: left;
                    text-decoration: underline;
                    font: normal normal 300 14px/20px Soleil;
                    letter-spacing: -0.28px;
                    color: #fa6400;
                  "
                >
                  Sign in
                </a>
              </span>
            </div>
          </div>
          <div class="teaser-modal__image"></div>
          <div>
            <a
              class="close_popup_btn"
              v-on:click="changePopup('backgroundRemoverPopup', false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="x" transform="translate(-198 -160)">
                  <circle
                    id="Ellipse_106"
                    data-name="Ellipse 106"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(198 160)"
                    fill="#fff"
                    opacity="0.5"
                  />
                  <g
                    id="x_icon"
                    data-name="x icon"
                    transform="translate(203 165)"
                  >
                    <rect
                      id="Rectangle_435"
                      data-name="Rectangle 435"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(7.308 8) rotate(-135)"
                      fill="#8c8c8c"
                    />
                    <rect
                      id="Rectangle_436"
                      data-name="Rectangle 436"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(0 7.308) rotate(-45)"
                      fill="#8c8c8c"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <div id="img-area">
              <div id="img-area2" style="opacity: 0"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="popup save_popup upgrade-popup subs_popup"
        v-if="confirmSubscriptionPopup && isOnline && !allowAiTools"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          viewBox="0 0 36 30"
        >
          <g id="emblem" transform="translate(-586 -239)">
            <path
              id="bg"
              d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
              transform="translate(586 239)"
              fill="#ff9360"
            />
            <path
              id="plus"
              d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
              transform="translate(552.992 245)"
              fill="#fff"
            />
          </g>
        </svg>

        <div :class="{ is_pwa_popup: is_pwa }">
          <div
            class="content"
            style="
              margin-top: 19px;
              width: 552px;
              display: flex;
              justify-content: space-between;
              gap: 32px;
            "
          >
            <div class="content-section">
              <div class="">
                <div class="">
                  <h2
                    class="title old_update_popup_1"
                    style="
                      text-align: unset;
                      font-size: 26px;
                      text-align: left;
                      margin-bottom: 24px;
                    "
                  >
                    <span style="color: #1c7ea8">Total AI</span>
                  </h2>

                  <p
                    style="font-size: 26px; color: #1d1d1d; margin-bottom: 16px"
                  >
                    <strong>$12.99/mo</strong>
                  </p>

                  <p
                    class="all-plus-future"
                    style="margin-bottom: 24px; margin-left: 0px"
                  >
                    Get access to all Total AI features for one low price. Pay by
                    month or the year. Cancel anytime.
                  </p>

                  <p
                    style="
                      font-size: 16px;
                      color: #1d1d1d;
                      letter-spacing: -0.64px;
                      text-align: left;
                      font-weight: normal;
                      font-family: Soleil;
                      margin-bottom: 20px;
                    "
                  >
                    <strong>Everything in Pro and:</strong>
                  </p>
                  <ul class="list-plus list-plus--ai">
                    <li v-for="(lai, i) of listAiItems" :key="i">{{ lai }}</li>
                    <!-- <li>Face Restoration</li>
                    <li>Inpainting & Removal</li>
                    <li>Image Upscale</li>
                    <li>Image De-oldify</li>
                    <li>Face to Sticker</li>
                    <li>Prompt Generation</li>
                    <li>Photo to Anime</li> -->
                  </ul>
                </div>
              </div>

              <div
                v-if="!is_pwa"
                :style="{ 'text-align': 'center', 'margin-top': '36px' }"
              >
                <a
                  href="javascript:void(0)"
                  @click="redirectToUpgrade(true)"
                  class="btn-orange"
                  style="
                    color: #fff;
                    margin: 0;
                    max-width: 100%;
                    height: 48px;
                    background-color: #1c7ea8;
                  "
                  >Upgrade Now</a
                >
              </div>
            </div>
          </div>

          <div class="teaser-modal__image cc" id="img-area-register2"></div>
          <div>
            <a
              class="close_popup_btn"
              v-on:click="changePopup('stripeCheckoutPopup', false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <g id="x" transform="translate(-198 -160)">
                  <circle
                    id="Ellipse_106"
                    data-name="Ellipse 106"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(198 160)"
                    fill="#000"
                    opacity="0.5"
                  />
                  <g
                    id="x_icon"
                    data-name="x icon"
                    transform="translate(203 165)"
                  >
                    <rect
                      id="Rectangle_435"
                      data-name="Rectangle 435"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(7.308 8) rotate(-135)"
                      fill="#fff"
                    />
                    <rect
                      id="Rectangle_436"
                      data-name="Rectangle 436"
                      width="10.335"
                      height="0.979"
                      rx="0.489"
                      transform="translate(0 7.308) rotate(-45)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <div v-if="!isMobileView" id="img-area">
              <div id="img-area2" style="opacity: 0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var gStorage = {};

function toogle(anImage, anAltSrcArr) {
  if (
    typeof anImage === 'undefined' ||
    typeof anAltSrcArr === 'undefined' ||
    anAltSrcArr.length === 0
  ) {
    return;
  }

  var id = anImage.id;
  var oldSrc = anImage.src;

  if (typeof gStorage[id] === 'undefined') {
    gStorage[id] = {
      id: id,
      origSrc: oldSrc,
      i: 0,
    };
  }

  gStorage[id].i += 1;
  if (gStorage[id].i > anAltSrcArr.length) {
    gStorage[id].i = 0;
  }

  if (gStorage[id].i === 0) {
    anImage.src = gStorage[id].origSrc;
  } else {
    anImage.src = anAltSrcArr[gStorage[id].i - 1];
  }
}
//import {
//	CardNumber,
//	CardExpiry,
//	CardCvc,
//	Card,
//	createToken,
//	createPaymentMethod,
//	instance,
//	customers,
//} from "vue-stripe-elements-plus";

import CGPStripe from '@/libs/cgp-stripe';
import Gpay from '@/libs/gpay';
import Acc from '@/libs/acc.js';
const analitics = require('@/libs/analytics');

import PromoCodeParser from '@/libs/promoCode';

export default {
  name: 'BackgroundRemoverPopupNew',
  props: {
    yesUpgrade: Function,
  },

  destroyed() {
    clearInterval(this.myInterval);
  },
  created() {
    // this.newRegisterPopup = true;
    // this.confirmSubscriptionPopup = false;

    this.myInterval = window.setInterval(function () {
      const img2 = document.querySelector('#img-area2');
      if (img2) {
        if (getComputedStyle(img2).opacity == '1') {
          img2.style.opacity = '0';
        } else {
          img2.style.opacity = '1';
        }
      }
    }, 2000);

    this.plans = this.$store.state.plans || [];
    if (this.plans.length) {
      this.selectedPlanId = this.plans[0].id;
    }

    //TODO uncomment
    // this.is_pwa = window.matchMedia('(display-mode: standalone)').matches && typeof PaymentRequest!='undefined';
    //
    // this.is_debug_mode = document.cookie.indexOf('debug_payments_1=')===-1?false:true;

    // this.$nextTick(()=>{
    //   if(this.is_pwa){
    //     this.gpay = new Gpay(this.$store, this);
    //     console.log("this.$refs", this.$refs);
    //     this.gpay.init(this.$refs.gpayButton, this.selectedPlanId);
    //   }else{
    //     this.cgpstripe = new CGPStripe(this.$refs);
    //   }
    // })
  },

  data: function () {
    return {
      gpay_token_generated: false,
      myInterval: null,
      acc: new Acc(),
      isSubmitting: false,
      isRedirecting: false,
      email: '',
      password: '',
      isMobileView: false,
      is_pwa: false,
      is_debug_mode: false,
      cgpstripe: false,
      gpay_promo: '',
      promo_code: {
        code: '',
        isFree: false,
        isOpenInput: false,
        loading: false,
        data: false,
        updater: 1,
        plac: {
          text: 'Enter Promo Code',
          default: 'Enter Promo Code',
          error: "Oops! The code you entered doesn't exist.",
        },
      },
      howUse: {
        selectDropdown: '',
        options: [
          "Personal use – I'm just having fun with it",
          "I'm a photographer – to edit personal and client photos",
          "I'm a freelancer/business owner – to manage my business",
          'Company use – at my job at another company',
          'Other...',
        ],
        select: '',
      },

      agree_terms: false,
      register: {
        name: '',
        email: '',
        email_confirm: '',
        password: '',
        howUse: '',
      },
      paymentMethodDetails: {},
      confirmSubscriptionPopup: true,
      createAccountPopup: false,
      congratPopup: false,
      newRegisterPopup: false,
      stripe: stripe_pub, //"pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc",
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      cardName: '',
      isLoading: false,
      formErrors: [],
      plans: [],
      selectedPlanId: null,

      options: {
        elements: {
          fonts: [{}],
        },
        style: {
          base: {
            fontFamily: 'Proxima Nova',
          },
        },
      },
      maskPhotos: [
        {
          src: require('@/assets/images/mask.jpg'),
          thumb: require('@/assets/images/mask.jpg'),
        },
        {
          src: require('@/assets/images/mask2.png'),
          thumb: require('@/assets/images/mask2.png'),
        },
      ],
    };
  },

  methods: {
    //		onGpayToken(){
    //			if(this.$store.state.paymentToken && this.$store.state.paymentToken.pwa){
    //				if(this.$store.state.USER){
    //					this.paymentMethodDetails = this.$store.state.paymentToken;
    //					this.createUserAndSubscribe(true);
    //				}else{
    //					this.paymentMethodDetails = this.$store.state.paymentToken;
    //					this.popupOpen("newRegisterPopup");
    //				}
    //			}
    //			return this.$store.state.paymentToken;
    //		},

    async redirectToUpgrade(isAiPremium = false) {
      window.sendinblue.track('checkout', { checkoutType: 'direct' });
      this.isRedirecting = true;
      window.location.replace(window.location.origin + '/upgrade-now' + (isAiPremium ? '?ai=1' : ''));
    },

    async onSubmit(ev) {
      this.isSubmitting = true;
      this.formErrors = null;
      var resp = await this.acc.registerBeforeCheckout({
        email: this.$refs.xemail.value,
        password: this.$refs.xpassword.value,
      });
      console.log('after register');

      this.formErrors = resp.errors;

      if (resp.success) {
        console.log('resp.success', resp.success);
        analitics.sendCustomEvent('sign_up');
        this.$store.state.USER = resp.data.user;
        if (resp.data.user) {
          window.sendinblue.track('signup', { singupType: 'upgrade' });
          window.sendinblue.identify(resp.data.user.email, {
            STATUS: 'abandoned',
          });
          document
            .querySelector('meta[name="uniq_id"]')
            .setAttribute('content', resp.data.user.id);
          window.sendinblue.track('checkout', { checkoutType: 'after_signup' });
          window.location.replace(window.location.origin + '/upgrade-now');
        }
      } else {
        this.isSubmitting = false;
      }
    },
    renderPlanDescription(plan) {
      const parse = new PromoCodeParser(this.promo_code, this.plans);
      return parse.replacePlanDescription(plan);
    },

    getPromoSavingPrice() {
      const parse = new PromoCodeParser(this.promo_code, this.plans);
      return parse.getPromoSavingPrice();
    },

    getPromoCode() {
      return this.promo_code.code;
    },

    parsePlanPrice(plan = {}, isFull = false) {
      const parse = new PromoCodeParser(this.promo_code, this.plans);
      return parse.parsePlanPrice(plan, isFull);
    },

    removePromoCode(isOpenInput = false, focusInput = false) {
      this.promo_code.data = false;
      this.promo_code.isOpenInput = isOpenInput;
      this.promo_code.error = false;
      this.promo_code.code = '';

      if (focusInput) {
        this.$refs.promo_code_elem.focus();
      }
    },

    async checkPromoCode() {
      if (!this.promo_code.code) return;
      if (this.promo_code.loading) return;
      this.promo_code.loading = true;

      try {
        const response = await fetch(
          `${window.prefixURL}/check_promocode?code=${this.promo_code.code}`
        ).then((res) => res.json());
        if (response.success) {
          const plan = this.plans.find(
            (pl) => pl.stripe_id == response.plan_id
          );
          if (!plan) throw Error('Bad code');

          this.selectedPlanId = plan.id;
          this.promo_code.data = response;
          this.promo_code.updater += 1;
        } else {
          throw Error('Bad code');
        }
      } catch (err) {
        this.promo_code.plac.text = this.promo_code.plac.error;

        console.error(err);
        this.promo_code.error = true;
        this.promo_code.code = '';
        this.$refs.promo_code_elem.focus();
      }

      this.promo_code.loading = false;
    },

    inputPromoCode() {
      this.promo_code.error = false;
      this.promo_code.plac.text = this.promo_code.plac.default;
    },

    openPromoInput() {
      if (this.promo_code.data) {
        this.removePromoCode(true, true);
      } else {
        this.promo_code.isOpenInput = !this.promo_code.isOpenInput;
      }
    },

    openDropdown(option) {
      this[option].selectDropdown =
        this[option].selectDropdown == 'active' ? '' : 'active';
    },

    selectDrop(value) {
      this.register.howUse = value;
      this.howUse.selectDropdown = '';
    },

    showLoginPopUp() {
      this.closeAllPopups();
      this.$store.dispatch('popupsToggle', {
        property: 'signIn',
        value: true,
      });
    },

    selectPlan(e) {
      this.promo_code.code = '';
      this.promo_code.isOpenInput = false;
      this.promo_code.loading = false;
      this.promo_code.data = false;
      this.promo_code.datupdatera += 1;

      this.selectedPlanId = e.target.value;
    },

    changeCardName(e) {
      this.cardName = e.target.value;
    },
    //TODO remove?
    async createCustomer(paymentMethod, card) {
      return fetch(`${this.$store.state.prefixURL}/create_customer`, {
        method: 'post',
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_method: paymentMethod,
          card,
          promo_code: this.promo_code.code,
          plan_id: this.selectedPlanId,
        }),
      }).then((res) => res.json());
    },

    async createUserAndSubscribe(only_payment = false) {
      console.log('in createUserAndSubscribe');

      //paymentToken

      this.formErrors = null;
      if (only_payment === true) {
        only_payment = true;
      } else {
        only_payment = false;
      }

      if (only_payment === false) {
        if (
          !this.register.email ||
          !this.register.email_confirm ||
          !this.register.password
        ) {
          this.formErrors = [['Please fill out all fields']];
          return false;
        }

        if (
          !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
            this.register.email
          )
        ) {
          this.formErrors = [['Please enter a valid email address']];
          return false;
        }

        if (this.register.email != this.register.email_confirm) {
          this.formErrors = [['Email and confirm email does not match']];
          return false;
        }

        if (this.register.password.length < 8) {
          this.formErrors = [['Your password must be at least 8 characters']];
          return false;
        }

        if (!this.register.howUse) {
          this.formErrors = [['Please make a selection']];
          return false;
        }
      }

      this.isLoading = true;
      var is_gpay = false;

      if (this.paymentMethodDetails.pwa) {
        var payment_data = {
          is_gpay: true,
          promo_code: this.gpay_promo,
          plan_id: this.selectedPlanId,
          ...this.paymentMethodDetails,
        };
        is_gpay = true;
      } else {
        var payment_data = {
          seti: this.paymentMethodDetails,
          promo_code: this.promo_code.code,
          plan_id: this.selectedPlanId,
          is_gpay: false,
        };
      }

      const result = await fetch(`${this.$store.state.prefixURL}/register3`, {
        method: 'POST',
        body: JSON.stringify(
          Object.assign(this.register, {
            _token: $('[name="csrf-token"]').attr('content'),
            only_payment: only_payment,
            promo_code: this.promo_code.code,
            payment_data: payment_data,
            is_gpay: is_gpay,
          })
        ),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': $('[name="csrf-token"]').attr('content'),
        },
      }).then((res) => res.json());

      this.formErrors = result.errors || null;
      this.isLoading = false;

      if (result.success == false) {
        this.formErrors = [paymentRes.message];
        //window.location.reload();
        this.isLoading = false;
        this.confirmSubscriptionPopup = true;
        this.newRegisterPopup = false;
        return;
      }

      result.data.user.isPremium = true;
      this.$store.state.USER = result.data.user;

      this.popupOpen('congratPopup');
      this.isLoading = false;
      await fetch('/createAffilate', {
        headers: {
          'X-CSRF-TOKEN': $('[name="csrf-token"]').attr('content'),
        },
      }).then((r) => r.text());
      rewardful('convert', { email: this.register.email });

      this.success_payment = true;

      return false;
    },
    update() {
      this.complete = this.number && this.expiry && this.cvc;
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },

    changePopup(property, value) {
      clearInterval(this.myInterval);
      this.$store.dispatch('popupsToggle', { property, value });
    },

    closeAllPopups() {
      this.$store.dispatch('popupsToggle', {
        property: 'backgroundRemoverPopup',
        value: false,
      });
    },
    popupOpen(popupName) {
      this.newRegisterPopup = false;

      (this.confirmSubscriptionPopup = false),
        (this.createAccountPopup = false),
        (this.congratPopup = false),
        (this[popupName] = true);
    },
  },

  computed: {
    listAiItems() { return this.$store.state.listAiItems; },
    allowAiTools() {
      return this.$store.state.allowAiTools;
    },

    isLoggedIn() {
      return this.$store.state.USER;
    },
    //		async paymentToken(){
    //
    //			console.log("this.$store.state.paymentToken", this.$store.state.paymentToken);
    //			alert(thist.gpay_token_generated);
    //
    //
    //			if(this.$store.state.paymentToken && this.$store.state.paymentToken.pwa){
    //				if(this.$store.state.USER){
    //					this.paymentMethodDetails = this.$store.state.paymentToken;
    //					this.createUserAndSubscribe(true);
    //				}else{
    //					this.paymentMethodDetails = this.$store.state.paymentToken;
    //					this.popupOpen("newRegisterPopup");
    //				}
    //			}
    //			return this.$store.state.paymentToken;
    //		},

    openHidePromoInput() {
      return this.promo_code.isOpenInput;
    },

    promoCodeUpdate() {
      return this.promo_code.data;
    },

    getPromoCodeBlockStyle() {
      try {
        const isOpen = this.promo_code.isOpenInput;
        const data = this.promo_code.data;

        let height = isOpen && !data ? '58px' : '0';
        if (data) {
          height = '58px';
        }

        return { height, opacity: isOpen && !data ? 1 : 0 };
      } catch (err) {
        console.error(err);
        return {};
      }
    },
  },
  watch: {
    paymentToken() {},

    openHidePromoInput() {
      this.inputPromoCode();
    },

    promoCodeUpdate(data) {
      if (data) {
        const plan = this.plans.find(
          (pl) => pl.stripe_id == this.promo_code.data.plan_id
        );

        this.promo_code.isFree = this.parsePlanPrice(plan) == 'FREE';
      } else {
        this.promo_code.isFree = false;
      }
    },

    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
};
</script>

<style scoped>
.reg-form .form_loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #8080809c;
  z-index: 10000;
}
.form_errors {
  text-align: left;
  font: normal normal normal 14px/34px Soleil;
  color: #ff5353;
}
#img-area {
  flex: 0 0 50%;
  max-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/mask.jpg');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}
#img-area2 {
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/mask2.png');
  background-size: cover, auto;
  background-position: center;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}

.already-member {
  text-align: left;
  font: normal normal 300 14px/20px Soleil;
  letter-spacing: -0.28px;
  color: #727272;
}

.list-plus li {
  margin-left: 30px;
  margin-top: 12px;
  text-align: left;
  font: normal normal 300 16px/20px Soleil;
  letter-spacing: -0.32px;
  color: #727272;
  opacity: 1;
}

.list-plus li:before {
  content: url('../../../assets/svg/plus.svg'); /* FontAwesome Unicode */
  font-family: icomoon;
  color: #fa9d1c;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
  width: 1.3em; /* same as padding-left set on li */
}

.list-plus.list-plus--ai li:before {
  content: url('../../../assets/svg/plus-ai.svg');
}

.content {
  flex: 0 0 50%;
  max-width: 50%;
  position: absolute;
  /*left:0;*/
  /*top:0;*/
  width: 100%;
  /* height: 100%; */
}

.popup.save_popup.upgrade-popup {
  text-align: unset !important;
  padding: 0px 35px 0px 28px !important;
  max-width: 100%;
  width: 840px;
  height: 625px;
  overflow: visible;
}
.popup.save_popup.upgrade-popup.reg-var {
  padding: 0px 35px 0px 36px !important;
}
.plus-benefits {
  position: absolute;
  bottom: 1.4rem;
  right: 0;
  background: #fff;
  width: 47%;
  padding: 20px;
}
.reg-var .list-plus li {
  margin-top: 8px;
  height: 18px;
}
.reg-var .list-plus li:before {
  vertical-align: middle;
  margin-top: -2px;
}
.agree-links {
  text-align: left;
  font: normal normal normal 12px/16px Soleil;
  letter-spacing: 0px;
  color: #828c9c;
  margin-top: 25px;
}
.agree-links a {
  text-decoration: underline;
}
.reg-form input {
  box-shadow: 0px 1px 2px #00000014;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
}
.form_loading {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.all-plus-future {
  text-align: left !important;
  font: normal normal 300 16px/20px Soleil !important;
  letter-spacing: -0.32px !important;
  color: #727272 !important;
  opacity: 1;
}

.title-success-signin {
  font-weight: inherit !important;
  font-family: inherit;
  font-size: 32px !important;
  font-weight: 400px;
}

.box-success-signin {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}
.subs_popup .form-group label {
  font-weight: 400;
}

.form-group .label {
  font-weight: 400 !important;
}

.popup.save_popup.upgrade-popup .checkbox {
  font-weight: 400 !important;
}

.popup.save_popup.upgrade-popup {
  padding: 70px 100px 60px 100px;
}

select:active {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.promoButton {
  font: normal normal 600 12px/15px Proxima Nova;
  letter-spacing: -0.29px;
  color: #9a9c9e;
  float: right;
  position: absolute;
  bottom: -20px;
  right: 7px;
  text-decoration: underline;
}

.plan-type-wrap {
  position: relative;
}

.promo_code_block {
  margin-top: 38px;
  z-index: 99;
  transition: 0.3s;
  height: 0;
  overflow: hidden;
  position: relative;
}

.promo_code_block > input {
  border: 1px solid rgba(0, 0, 0, 0);
  background: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  padding: 14px;
  display: block;
  border: 1px solid #ececec;
  border-radius: 5px;
  color: #757575;
  font-size: 14px;
  font-family: inherit;
  padding-right: 52px;
}

.promo_code_block > input:focus {
  border: 1px solid #ececec;
}

/* .checkActivePromocode.active {
    background: #fa6401;
    color: #fff;
    pointer-events: all;
} */

.promo_error {
  border: 1px solid #ff5352 !important;
}

.promo_error::placeholder {
  color: #ff5352;
}

.promo_code_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #a4ecdc 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 18.5px;
  margin-bottom: 12px;
  position: absolute;
  width: 100%;
  top: 0px;
  transition: 0.3s;
  opacity: 0;
}

.promo_code_info > .left {
  flex: 1;
  text-align: left;
}

.promo_code_info > .left > ._code {
  letter-spacing: 0.2px;
  color: #2a2a2a;
  font: normal normal 600 14px/17px Proxima Nova;
  margin-right: 4px;
}

.promo_code_info > .right {
  font: normal normal bold 12px/15px Proxima Nova;
  opacity: 0.8;
  color: #2a2a2a;
}

.remove_promo_btn:hover {
  color: #d40000;
}

.promo_applied {
  position: absolute;
  right: 0;
  top: -6px;
  background: #4adaba;
  padding: 6px;
  border-radius: 0px 5px 3px 3px;
  text-align: left;
  font: normal normal 600 9px/11px Proxima Nova !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  text-transform: uppercase;
  opacity: 1;
}

.decoration {
  text-decoration: line-through;
}

.promo_button {
  top: 0;
  width: 40px;
  display: block;
  cursor: default;
  height: 38px;
  right: 0;
  position: relative;
  pointer-events: none;
}

.promo_button.act ._bg {
  fill: #fa6400;
}

.promo_button.act ._arrow {
  stroke: #fff;
}

.promo_button.act {
  pointer-events: all;
  cursor: pointer;
}

.popup.save_popup.upgrade-popup .checkbox {
  transition: 0.3s;
}

.popup.save_popup.upgrade-popup .checkbox.hide_card {
  margin-top: 87px;
}

.upgrade-popup {
  transition: 0.2s;
}

.card-data.hide_card {
  bottom: 275px;
  pointer-events: none;
  opacity: 0;
}

.wrap_promo_plan {
  background: #fff;
  z-index: 999999999;
  position: relative;
  display: block;
}

.content-section {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
