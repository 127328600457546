<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="editPanelPropChange">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Resize</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <label class="text_label has_mb">Width</label>
            <div class="btns_wrpr">
              <InputWidthHeight
                :inputValue="resize.width"
                inputName="width"
                @change="setResize"
                @postBack="postBack"
              />
            </div>
          </div>
          <div class="half">
            <label class="text_label has_mb">Height</label>
            <div class="btns_wrpr">
              <InputWidthHeight
                :inputValue="resize.height"
                inputName="height"
                @change="setResize"
                @postBack="postBack"
              />
            </div>
          </div>
        </div>
        <div class="panel_row margin_bottom_15">
          <ToggleCheck
            toggleCheckLabel="Lock Aspect Ratio"
            :thisToggleCheckChecked="lockAspectRatio"
            @toggleCheckHandle="checkToggle"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div class="half">
            <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            Input your width and height manually or adjust
            <br />the size by clicking and dragging the up/down <br />arrows.
          </p>
          <br />
          <p class="simple_light_p">
            Toggle
            <i>Lock Aspect Ratio</i> to keep the original <br />image
            proportions. This is especially useful <br />when you want to avoid
            any stretching of the <br />image.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import InputWidthHeight from "@/components/InputWidthHeight.vue";
import ToggleCheck from "@/components/ToggleCheck.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditResizePanel",
  components: {
    InputWidthHeight,
    ToggleCheck,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    return {
      tipsText:
        "The Resize tool allows you to reduce both file size and resolution of your image. ",
      helpIcon: require("@/assets/img/help-icon.svg"),
      lockAspectRatio: true,
      resize: {},
      libResize: false,
      changes: false,
    };
  },
  methods: {
    async applyIt() {
      // const isHavePremium = await this.$store.dispatch("isPremiumCurrentUser");

      // if (!isHavePremium) {
      //   this.$store.dispatch("popupsToggle", {
      //     property: "upgradePopup",
      //     value: true
      //   });

      //   return;
      // }

      await this.libResize.resizeSprite(
        this.resize.width,
        this.resize.height,
        this.lockAspectRatio,
        true
      );

      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
    },

    postBack(data) {
      if (data.name == "height") {
        this.resize.height = parseInt(data.value);
        this.resizeCanvas(undefined, this.resize.height);
      } else {
        this.resize.width = parseInt(data.value);
        this.resizeCanvas(this.resize.width);
      }
    },

    async closeIt() {
      if (this.changes) {
        await this.$lib._cache.renderSaveByTimestamp();
      }
      await this.$lib.app.addTransparentPNGMask()
      this.closeCurrentEditor(this.$lib);
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },

    async resizeCanvas(width, height) {
      await this.libResize.resizeSprite(width, height, this.lockAspectRatio);

      this.$lib.app.setFabricZoom();
      this.changes = true;
    },

    setResize(valObj) {
      if (valObj.name == "width") {
        this.resize.width = parseFloat(valObj.value);
        this.resizeCanvas(this.resize.width);
      } else {
        this.resize.height = parseFloat(valObj.value);
        this.resizeCanvas(undefined, this.resize.height);
      }
    },

    checkToggle() {
      this.lockAspectRatio = !this.lockAspectRatio;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
    getResize() {
      return this.libResize._resize;
    },
  },

  async mounted() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);

    this.libResize = new this.$lib.edit.Resize(this.$lib.app.stage.children[0]);
    await this.libResize.resizeSprite();
    this.resize = this.libResize._resize;
  },

  beforeDestroy() {
    //this.$store.commit("changePremiumStatus", false);

    if (this.libResize) {
      this.closeIt();
    }
  },
  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
    getResize(val) {
      this.resize = val;
    },
  },
};
</script>
