
export function trackMxEvent(eventName, properties = {}) {
    if (!window.mixpanel) {
        console.error("Mixpanel is not available on window.");
        return;
    }

    window.mixpanel.track(eventName, properties);
}

export function initializeMixpanel(user) {
    if (!window.mixpanel) {
        console.error("Mixpanel is not available.");
        return;
    }

    if (!user || !user.email) {
        console.error("User not found.");
        return;
    }
    // test: 29f10e757f8589a2fbad86e004dd080f
    // live: 58afe2d07c1f5d376416111177f96776
    window.mixpanel.init("58afe2d07c1f5d376416111177f96776", {
        debug: false,
        track_pageview: true,
        persistence: "localStorage",
    });

    window.mixpanel.identify(user.email);

    window.mixpanel.people.set_once({
        '$name': user.name,
        '$email': user.email,
        'status': user.status,
        'show_trial': user.show_trial_popup,
        'plan': user.plan_id,
    });
}
