<template>
  <div class="popup" v-custom-drag>
    <div class="popup_header">
      <h3>background</h3>
      <a class="close_popup_btn" v-on:click="closePopups">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <div class="multi_line_checkboxes_wrpr">
            <label class="btn-action-left multi_line_checkbox">
              <input
                type="radio"
                name="backgroundType"
                value="full"
                :checked="getCheckStatus('full')"
                v-on:input="changeCheckBox($event, 'full')"
              />
              <span class="btn-action-left multi_line_checkbox_text">Full</span>
            </label>
            <label class="multi_line_checkbox">
              <input
                type="radio"
                name="backgroundType"
                value="line"
                :checked="getCheckStatus('line')"
                v-on:input="changeCheckBox"
              />
              <span class="multi_line_checkbox_text">Line</span>
            </label>
            <label class="btn-action-right multi_line_checkbox">
              <input
                type="radio"
                name="backgroundType"
                value="word"
                :checked="getCheckStatus('word')"
                v-on:input="changeCheckBox"
              />
              <span class="btn-action-right multi_line_checkbox_text">Word</span>
            </label>
          </div>
        </div>
        <div class="popup_body_row_item">
          <ColorIput
            textLabel="Color"
            :defColor="backgroundTextParams.color"
            :changeColorValue="setSelectedColor"
          />
        </div>
        <div class="popup_body_row_item" v-if="getCheckStatus('full')">
          <Range
            rangeSliderId="editOpacity"
            :changeValue="changePaddingValue"
            :rangeSliderValue="backgroundTextParams.padding"
            rangeSliderLabel="Padding"
            rangeSliderMeasure="%"
            @dragEnd="setChangePadding"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :defaultValue="0"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ColorIput from "@/components/ColorInput";
import Range from "@/components/Range.vue";

export default {
  name: "PopupTextBackground",
  components: {
    Range,
    ColorIput,
  },
  props: {
    popUpVisible: Boolean,
    popUpPropChange: Function,
    popUpLabel: String,
    closePopups: Function,
    updateParams: Function,
    backgroundTextParams: Object,
  },

  methods: {
    getCheckStatus(type) {
      return type == this.backgroundTextParams.type;
    },

    setSelectedColor(color) {
      this.backgroundTextParams.color = color.hex;
      this.updateParams(this.backgroundTextParams);
    },

    changeCheckBox(e, type) {
      if (type != "full") {
        this.backgroundTextParams.padding = 0;
      }

      this.backgroundTextParams.type = e.target.value;
      this.$emit("onUpdate", this.backgroundTextParams);
    },

    changePaddingValue(value) {
      this.backgroundTextParams.padding = Number(value);
      this.updateParams(this.backgroundTextParams);
    },

    setChangePadding(value) {
      this.backgroundTextParams.padding = Number(value);
      this.$emit("onUpdate", this.backgroundTextParams);
    },
  },
};
</script>
