<template>
  <div class="popup graphic-properties" v-if="showPropertiesGraphics" v-custom-drag>
    <div class="popup_header">
      <h3>graphic properties</h3>
      <a class="close_popup_btn" v-on:click="popUpPropChange(false)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <ColorInput
            :defColor="layerPropConfig.color"
            :key="dispatchUpdateTrigger"
            @inputRGBA="setColor"
            textLabel="Fill Color"
            :checkColor="!!layerPropConfig.color"
          />
        </div>
        <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :key="dispatchUpdateTrigger"
            :rangeSliderValue="layerPropConfig.intensity"
            rangeSliderLabel="Intensity"
            rangeSliderMeasure=""
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :changeValue="setIntensity"
          />
        </div>
      </div>
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <div class="actions-group double-btn popup_double_btns_wrpr">
            <a
              href="#"
              class="button btn-action duplicate"
              @click="popupLayerPropShow(true)"
            >Layer Properties</a>
            <a href="#" @click="deleteSelectedGraphic" v-tooltip.top="'Delete'" class="btn-action trash">
              <span class="inch-trash-icon"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";

export default {
  name: "PopupGraphicProp",
  props: {
    popUpPropChange: Function,
    popupLayerPropShow: Function,
    popUpGraphChanges: Function,
    layerPropConfig: Object,
    dispatchUpdateTrigger: Boolean
  },
  data: () => {
    return {
      checkedFillColor: false
    };
  },
  components: {
    Range,
    ColorInput
  },
  computed: {
    showPropertiesGraphics() {
      return this.$store.state.global.showPropertiesGraphics;
    }
  },
  methods: {
    updateCheckedColor(value) {
      this.checkedFillColor = value;
    },

    deleteSelectedGraphic() {
      this.$lib.fabric.deleteSelectedObject();
      this.$store.state.global.showPropertiesGraphics = false;
      this.$store.dispatch("isUpdateCache");
    },

    sendUpdate() {
      this.popUpGraphChanges(this.layerPropConfig);
    },

    setColor(rgba) {
      const parsedRGBA = this.$lib.lib.rgbaToHexAlpha(rgba);

      this.layerPropConfig.color = parsedRGBA.alpha == 0 ? undefined : rgba;

      this.sendUpdate();
    },

    setIntensity(intensity) {
      this.layerPropConfig.intensity = intensity;
      this.sendUpdate();
    }
  }
};
</script>