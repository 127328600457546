<template>
    <div>
        <div v-if="videoURL" class="video_preview">
            <video autoplay loop muted playsinline :src="videoURL" @click="toggleFullVideoPreview">
                Your browser does not support the <code>video</code> element or the WebP format.
            </video>
        </div>

        <!-- <div v-if="isFullVideoPreview" class="fill-video-preview" @click="toggleFullVideoPreview">
            <video autoplay loop muted playsinline :src="videoURL">
                Your browser does not support the <code>video</code> element or the WebP format.
            </video>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        filename: {
            type: String,
            default: () => ''
        }
    },

    data() {
        return {
            isFullVideoPreview: false
        };
    },

    methods: {
        toggleFullVideoPreview() {
            this.isFullVideoPreview = !this.isFullVideoPreview;
        }
    },

    computed: {
        videoURL() {
            if(!this.filename) return false;
            return require(`@/assets/video_ai_preview/${this.filename}.webm`);
        }
    }
}
</script>

<style scoped>
    .fill-video-preview {
        background: rgba(5, 5, 5, .6);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .fill-video-preview video {
        max-width: 80vw;
    }

    .video_preview {
        width: 324px;
        height: 198.45px;
        display: block;
        overflow: hidden;
        pointer-events: none;
        cursor: default;
    }

    .video_preview video {
        width: 324px;
        height: 198.45px;
        background: #000;
    }
</style>