<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="editPanelPropChange">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Crop</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar style="height: 568px;">
      <div class="panel-content wo_pad">
        <div class="panel_row">
          <DropdownSelectCrop
            textLabel="Aspect Ratio"
            :current="selectDropdownValue"
            :selectClass="selectDropdown"
            :selectOptions="selectOptions"
            @toggleDropdown="openDropdown"
            @selectDropdown="selectDrop"
            @resetSelectClass="selectDropdown = ''"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <label class="text_label has_mb">Width</label>
            <div class="btns_wrpr">
              <InputWidthHeight
                :inputValue="parseIntResize(resize.width)"
                :maxValue="resize.width + resize.x"
                @change="setResize"
                @postBack="setResize"
                inputName="width"
              />
            </div>
          </div>
          <div class="half">
            <label class="text_label has_mb">Height</label>
            <div class="btns_wrpr">
              <InputWidthHeight
                :inputValue="parseIntResize(resize.height)"
                :maxValue="resize.height + resize.y"
                @change="setResize"
                @postBack="setResize"
                inputName="height"
              />
            </div>
          </div>
        </div>
        <div class="panel_row margin_bottom_15">
          <ToggleCheck
            toggleCheckLabel="Lock Aspect Ratio"
            :thisToggleCheckChecked="lockAspectRatio"
            @toggleCheckHandle="checkToggle"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div class="half">
            <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            Input your width and height manually or select <br />
            one of our crop presets from the dropdown.
          </p>
          <br />
          <p class="simple_light_p">
            Toggle <i>Lock Aspect Ratio</i> to keep the original<br />
            image proportions.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import DropdownSelectCrop from "@/components/DropdownSelectCrop.vue";
import InputWidthHeight from "@/components/InputWidthHeight.vue";
import ToggleCheck from "@/components/ToggleCheck.vue";
import Tips from "@/components/Tips.vue";
import utils from '../../../libs/utils';
import AdLeftPanel from "@/components/AdLeftPanel.vue";


export default {
  name: "EditCropPanel",
  components: {
    DropdownSelectCrop,
    InputWidthHeight,
    ToggleCheck,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    const socialPresets = [{
        title: 'Facebook',
        type: 'tab',
        presets: [{
            title: 'Profile Photo',
            size: { w: 180, h: 180 }
          }, {
            title: 'Business Profile Picture',
            size: { w: 180, h: 180 }
          }, {
            title: 'Cover Photo',
            size: { w: 820, h: 312 }
          }, {
            title: 'Shared Image',
            size: { w: 1200, h: 630 }
          }, {
            title: 'Event Image',
            size: { w: 1920, h: 1080 }
          }, {
            title: 'Fundraiser Image',
            size: { w: 800, h: 300 }
          }]
        }, {
        title: 'Twitter',
        type: 'tab',
        presets: [{
            title: 'Profile Photo',
            size: { w: 400, h: 400 }
          }, {
            title: 'Header Photo',
            size: { w: 1500, h: 1500 }
          }, {
            title: 'In-Stream Photo',
            size: { w: 1200, h: 675 }
          }]
        }, {
        title: 'Instagram',
        type: 'tab',
        presets: [{
          title: 'Profile Picture',
          size: { w: 110, h: 110 }
        }, {
          title: 'Photo Thumbnail',
          size: { w: 161, h: 161 }
        }, {
          title: 'Photo Size',
          size: { w: 1080, h: 1080 }
        }, {
          title: 'Instagram Stories',
          size: { w: 1080, h: 1920 }
        }]
      }, {
        title: 'YouTube',
        type: 'tab',
        presets: [{
          title: 'Channel Profile Image',
          size: { w: 800, h: 800 }
        }, {
          title: 'Channel Cover Photo',
          size: { w: 2048, h: 1152 }
        }, {
          title: 'Video Thumbnail',
          size: { w: 1280, h: 720 }
        }]
      }, {
        title: 'Pinterest',
        type: 'tab',
        presets: [{
          title: 'Profile Picture',
          size: { w: 165, h: 165 }
        }, {
          title: 'Pin Sizes',
          size: false,
          aspectRatio: 2 / 3
        }, {
          title: 'Board Display',
          size: { w: 222, h: 150 }
        }]
      }, {
        title: 'LinkedIn',
        type: 'tab',
        presets: [{
          title: 'Profile Image',
          size: { w: 300, h: 300 }
        }, {
          title: 'Background Image',
          size: { w: 1584, h: 396 }
        }, {
          title: 'Company Logo Image',
          size: { w: 300, h: 300 }
        }, {
          title: 'Company Cover Image',
          size: { w: 1128, h: 191 }
        }, {
          title: 'Shared Image',
          size: { w: 1200, h: 627 }
        }]
      }, "_divider"].map(preset => {
        if(typeof preset == 'object') {
          return {
            ...preset, id: utils.uuidv4(),
            presets: preset.presets.map(pr => ({ ...pr, id: utils.uuidv4() }))
          }
        } else {
          return preset;
        }
    });

    return {
      tipsText:
        "The Crop tool allows you to trim away unnecessary parts of your image.",
      helpIcon: require("@/assets/img/help-icon.svg"),
      selectDropdown: "",
      selectDropdownValue: "Freeform",
      selectOptions: [
        "Freeform",
        "_divider",
        "Original Ratio",
        "Square",
        "_divider",
        ...socialPresets,
        "Photo 4x6",
        "Photo 8x10",
        "Photo 16x9",
        "_divider",
        "Image 4:3",
        "Image 3:2",
        "_divider",
        "Film 1.85:1",
        "Film 2.39:1",
      ],
      lockAspectRatio: false,
      currenAspectRatio: 0,
      crop: false,
      resize: {},
    };
  },

  methods: {
    parseIntResize(num) {
      return parseInt(num);
    },

    async applyIt() {
      await this.crop.selectedCrop();
      // await this.crop.addToCache();

      this.crop = false;
      await this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
      this.$lib.app.setFabricZoom();
    },
    closeIt() {
      this.closeCurrentEditor(this.$lib);
      if (this.crop) {
        this.crop.destroyCrop();
        this.crop = false;
      }
    },

    openDropdown() {
      this.selectDropdown = "active";
    },

    calculateSelectedObjectPreset(data) {
      if(this.selectDropdownValue && this.selectDropdownValue.item) {
        const item = this.selectDropdownValue.item;
        const { w, h } = this.$lib.app._canvasSize;
        if(!item.size) {
          const width = (w * .90);
          const height = (h * .90);

          this.currenAspectRatio = item.aspectRatio;
          this.resize = { width, height, x: (w - width) / 2, y: (h - height) / 2 };

          this.crop.setResize(this.resize);
          this.crop.setOptions({ aspectRatio: this.currenAspectRatio });
          return;
        }

        const { w: pw, h: ph } = item.size;

        const aspectRatio = pw / ph;
        this.currenAspectRatio = aspectRatio;
        let payload = {};

        if(pw > w || ph > h) {
          const scale = Math.min(w / pw, h / ph);
          
          const width = scale * pw;
          const height = scale * ph;

          payload = { width, height, x: (w - width) / 2, y: (h - height) / 2 };
        }

        this.resize = {
          ...this.resize, width: pw, height: ph,
          x: (w - pw) / 2, y: (h - ph) / 2,
          ...payload
        };

        this.lockAspectRatio = true;
        this.crop.setResize(this.resize);
        this.crop.setOptions({ aspectRatio: this.currenAspectRatio });
      }
    },

    selectDrop(value) {
      if(typeof value == 'object') {
        this.selectDropdownValue = value;

        this.calculateSelectedObjectPreset();
        return false;
      }

      this.selectDropdownValue = value;
      this.selectDropdown = "";

      if (this.selectDropdownValue === "Freeform") {
        this.lockAspectRatio = false;
        this.setAspectRatio();
      } else {
        this.lockAspectRatio = true;
        this.setAspectRatio();
      }

      this.lockAspectRatio ? this.setAspectRatio() : false;
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    changesAppliedShow() {
      /* this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000); */

      if (this.crop) {
        this.crop.selectedCrop();
        this.crop.initCrop();
      } else {
        this.crop = new this.$lib.edit.Crop(this.$lib.app.stage.children[0]);
        this.crop.initCrop();
      }
    },
    cancelCrop() {
      this.crop.destroyCrop();
      this.crop = false;
    },

    setResize(valObj) {
      if (this.lockAspectRatio && this.currenAspectRatio) {
        if (valObj.name == "width") {
          this.resize.width = valObj.value;
          this.resize.height = valObj.value / this.currenAspectRatio;
        } else {
          this.resize.height = valObj.value;
          this.resize.width = valObj.value * this.currenAspectRatio;
        }
      } else {
        this.resize[valObj.name] = valObj.value;
      }

      this.crop.setResize(this.resize);
    },

    checkToggle(e) {
      this.lockAspectRatio = e.target.checked;

      if (!this.lockAspectRatio) {
        this.selectDropdownValue = "Freeform";
      }

      this.setAspectRatio();
    },

    setAspectRatio() {
      if(this.selectDropdownValue != 'Freeform') {
        this.$lib.app.fabricCanvas.set({ uniScaleTransform: false });
      }

      switch (this.selectDropdownValue) {
        case "Freeform":
          this.currenAspectRatio = this.lockAspectRatio
            ? this.resize.width / this.resize.height
            : 0;

            this.$lib.app.fabricCanvas.set({ uniScaleTransform: true });
          break;
        case "Original Ratio": {
          this.currenAspectRatio =
            this.$lib.app.stage.width /
            this.$lib.app.zoomProcentage /
            (this.$lib.app.stage.height / this.$lib.app.zoomProcentage);

          break;
        }

        case "Square":
          this.currenAspectRatio = 1;
          break;
        case "Photo 4x6":
          this.currenAspectRatio = 4 / 6;
          break;
        case "Photo 8x10":
          this.currenAspectRatio = 8 / 10;
          break;
        case "Photo 16x9":
          this.currenAspectRatio = 16 / 9;
          break;
        case "Image 4:3":
          this.currenAspectRatio = 4 / 3;
          break;
        case "Image 3:2":
          this.currenAspectRatio = 3 / 2;
          break;
        case "Film 1.85:1":
          this.currenAspectRatio = 1.85;
          break;
        case "Film 2.39:1":
          this.currenAspectRatio = 2.39;
          break;
        default:
          this.currenAspectRatio = 0;
      }

      if (this.lockAspectRatio) {
        this.crop.setOptions({ aspectRatio: this.currenAspectRatio });
      } else {
        this.crop.setOptions({ aspectRatio: 0 });
      }
    },
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },

    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },

    getResize() {
      return this.crop._resize;
    },
  },
  mounted() {
    this.$lib.app.fabricCanvas.set({ uniScaleTransform: true });

    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.crop = new this.$lib.edit.Crop(this.$lib.app.stage.children[0]);
    this.crop.initCrop();

    window.crop = this.crop;
  },
  beforeDestroy() {
    this.$lib.app.fabricCanvas.set({ uniScaleTransform: false });
    this.$store.dispatch("fabricSlide", false);
    if (this.crop) {
      this.closeIt();
    }
  },
  
  watch: {
    selectDropdown(val) {
      if(!val) this.$store.state.dropdownMenuOption = false;
    },

    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
    /* selectDropdown() {
      console.log('CODE', this.selectDropdown);
    }, */

    getResize(val) {
      this.resize = val;
      // {
      //   ...val,
      //   w: val.w * (val.zoom || this.$lib.app.scaleProcentage),
      //   h: val.h * (val.zoom || this.$lib.app.scaleProcentage)
      // };
      //    console.log({val});
    },
  },
};
</script>

