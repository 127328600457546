<template>
	<div class="popup save_popup upgrade-popup sign-in">

		<a class="close_popup_btn" v-on:click="changePopup('signIn', false)">
			<span class="inch-icon inch-close-icon"></span>
		</a>
		<h2 class="title sm">{{ isForgotPopup ? 'Forgot password' : 'Sign in' }}</h2>

		<div class="is_offline" v-if="isOffline">
			<Offline></Offline>
		</div>     
		<form id="login_form" v-on:submit.prevent="onSubmit" v-if="isOnline">
			<div class="form_loading" v-if="isLoading"></div>

			<div class="form-group">
				<div class="label">{{ isForgotPopup ? 'EMAIL': 'USERNAME / EMAIL' }}</div>
				<input type="email" ref="xemail" name="email" class="form-control xemail" :value="email" />
			</div>
			<div class="form-group" v-if="!isForgotPopup">
				<div class="label">PASSWORD</div>
				<input type="password" ref="xpassword" name="password" class="form-control" :value="password" />
			</div>
			<div class="form_errors" v-if="formErrors">
				<ul id="example-1">
					<li v-for="item in formErrors">{{ item[0] }}</li>
				</ul>
			</div>
			
			<div class="btn-wrap" style="display: flex;align-items: center;justify-content: space-between;">
				<input type="submit" class="btn-orange" :value="isForgotPopup ? 'Send request': 'Sign in'" style="padding: 15px 40px;"/>
				<label class="checkbox" v-if="!isForgotPopup" style="font-size: 13px;margin: 0;">
					<input type="checkbox" checked="checked" name="remember" id="remember" ref="remember" />
					<span class="check"></span>
					Remember Me
				</label>
			</div>
			
			<div class="bottom-info" style="margin-top: 32px;">
				<a @click="forgotPassword(true)" v-if="!isForgotPopup" style="margin: 0;">Forgot password?</a>
				<a @click="forgotPassword(false)" v-if="isForgotPopup" style="margin: 0;">Sign in</a>
			</div>
		</form>
	</div>
</template>


<script>
import Acc from "@/libs/acc.js";
import Offline from "@/components/Offline.vue";
import { initializeMixpanel, trackMxEvent } from '@/libs/mixpanel.js';

//client@mail.com
//123123123

export default {
	name: "SignIn",
	data() {
		return {
			isLoading: false,
			acc: new Acc(),
		formErrors: null,
		isForgotPopup: false,
		email: "",
		password: ""
		};
	},
	components:{
		Offline
	},
	methods: {
		forgotPassword(status) {
		this.isForgotPopup = status;
	},
		async onSubmit(ev) {
		this.isLoading = true;
		if(this.isForgotPopup) {
			const result = await this.acc.resetPassword(this.$refs.xemail.value);
			this.changePopup("signIn", false);
			this.isLoading = false;
			return;
		}
			this.formErrors = null;
			var resp = await this.acc.login({
				email: this.$refs.xemail.value,
				password: this.$refs.xpassword.value,
				remember: this.$refs.remember.checked
			});

			this.formErrors = resp.errors;
			this.isLoading = false;

			if (resp.success) {
				window.sendinblue.track('login');
				const firstname = resp.data.user.name!="Anonymouse"? resp.data.user.name : '';
				const status = (resp.data.user.status == 'free' && resp.data.user.dropout) ? 'abandoned' : resp.data.user.status;
				window.sendinblue.identify(resp.data.user.email, {'FIRSTNAME': firstname, 'STATUS': status});

				if (resp.data.user.isPremium) {
					try {
						initializeMixpanel(this.USER);
						trackMxEvent('Sign In')
					} catch (error) {
						// 
					}
				}
				
				
				if(this.$route.query.redirect) {
					this.$router.push(this.$route.query.redirect);
				}

				this.$store.state.USER = resp.data.user;
				if(resp.data.user) {
					$('meta[name=uniq_id]').attr('content', resp.data.user.id);
				}
				
				this.changePopup("signIn", false);
			}
		},
		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		}
	}
};
</script>

<style>
.form_loading {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #8080809c;
	z-index: 10000;
}

.btn-orange {
	border: none;
}
.form_errors {
	/*color: red;*/
	/*font-weight: bold;*/
	text-align: left;
	font: normal normal normal 14px/20px Soleil;
	color: #FF5353;
  letter-spacing: -0.28px;
}
.popup.save_popup.upgrade-popup.sign-in .title{
	font-size: 26px;
}

.form-group .label {
	font-size: 13px !important;
}


.popup.save_popup.upgrade-popup .btn-wrap .checkbox {
    text-transform: uppercase;
    font-size: 14px;
    padding-left: 30px;
}

.popup.save_popup.upgrade-popup .btn-wrap .check {
    border-color: #E5E5E5;
}

.popup.save_popup.upgrade-popup .btn-wrap input:checked~.check {
    background-color: #FA6400;
    border-color: #FA6400;
}

.popup.save_popup.upgrade-popup .btn-wrap input:checked~.check::after {
    border-color: #fff;
}
.popup.save_popup.upgrade-popup .btn-wrap a.change-sub-card {
	margin-bottom: 12px;
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	font: normal normal normal 14px/18px Soleil;
	letter-spacing: -0.28px;
	color: #FFFFFF;
}

.popup.save_popup.upgrade-popup .btn-wrap a.update-card {
	margin-bottom: 12px;
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	font: normal normal normal 14px/18px Soleil;
	letter-spacing: -0.28px;
	color: #FFFFFF;
}

.popup.save_popup.upgrade-popup .btn-wrap a {
    margin-bottom: 12px;
    display: inline-block;
    text-transform: uppercase;
    /*color: #1d1d1d;*/
	font: normal normal normal 14px/18px Soleil;
	letter-spacing: -0.28px;
	color: #FFFFFF;
}

</style>
