<template>
    <StripeCheckoutPopupOld v-if="showOldPopup"/>
    <StripeCheckoutPopupNew v-else/>
</template>

<script>
    import StripeCheckoutPopupOld from './Popups/Old/StripeCheckoutPopup.vue';
    import StripeCheckoutPopupNew from './Popups/AI/StripeCheckoutPopup.vue';

    export default {
        name: "StripeCheckoutPopup",
        components: {
            StripeCheckoutPopupOld,
            StripeCheckoutPopupNew
        },

        computed: {
            showOldPopup() {
                return ['IN', 'BR', 'ID'].includes(window.subLocation);
            }
        }
    };
</script>