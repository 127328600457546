<template>
  <div>
    <label v-if="textLabel" class="text_label has_mb">{{ textLabel }}</label>
    <div class="dropdown_select" :class="selectClass">
      <div class="dropdown_select_current" v-on:click="$emit('toggleDropdown')">
        <span class="select_current_text" :class="textAlign">
          <span v-if="placeholder && !current">{{ placeholder }}</span>
          {{ current }}
        </span>
        <ArrowDownIcon />
      </div>
      
      <div class="dropdown_select_wrap">
        <div v-bar :style="{ ...(skipHeight ? {} : {height: fullHeight ? '100%' : '368px' }) }">
          <ul class="dropdown_select_list">
            <li
              v-for="(option, index) in options"
              :key="index"
              :class="[isCurrent(option), isDivider(option)]"
              v-on:click="emitSelectDropdown(option)"
            >{{ printOption(option) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDownIcon from "@/assets/img/arrow_down.svg?inline";
export default {
  name: "DropdownSelect",
  components: {
    ArrowDownIcon
  },
  props: {
    textLabel: String,
    selectClass: String,
    current: String,
    selectOptions: Array,
    placeholder: String,
    fullHeight: {
      type: Boolean,
      default: false
    },
    
    skipHeight: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: ''
    },
  },
  data: function() {
    return {
      options: this.selectOptions,
      toggle: false
    };
  },
  computed: {
    triggerDropdown() {
      return this.$store.state.triggerDropdown;
    }
  },

  watch: {
    triggerDropdown() {
      this.$emit("resetSelectClass");
    }
  },

  methods: {
    isCurrent(value) {
      return value === this.current ? "active" : "";
    },
    isDivider(value) {
      return value === "_divider" ? "divider" : "";
    },

    emitSelectDropdown(option) {
      if (option == "_divider") return;
      this.$emit("selectDropdown", option);
    },

    printOption(option) {
      return option == "_divider" ? "" : option;
    }
    /* addClick() {
      this.$nextTick(() => {
        if (this.selectClass === "active" && this.toggle) {
          this.$emit("selectDropdown", this.current);
          document.removeEventListener("click", this.addClick);
          this.toggle = false;
        } else {
          this.toggle = true;
        }
      });
    },
 */
    /* setPresetStyle(option) {
                if(!option) {
                    return {
                        padding: 0,
                        'border-top': '1px solid #3e4049'
                    };
                } else {
                    return {};
                }

                :style="setPresetStyle(option)"
            } */
  }
  /* updated() {
    if (this.selectClass === "active") {
      document.addEventListener("click", this.addClick);
    }
  },
  destroyed() {
    if (this.selectClass === "active") {
      document.removeEventListener("click", this.addClick);
    }
  }, */
};
</script>

<style>
.dropdown-overlay {
  position: absolute;
}
.select_current_text_align {
  text-align: initial;
}
</style>
