<template>
	<div class="tool-item-panel graphics_panel" :class="sidebarPanelClass">
		<div class="panel-top without_hover">
			<div class="back">
				<h3>graphics</h3>
			</div>
			<div
				class="help-box"
				@click.stop="popTipsToggle('popupsToggleStatus', true)"
			>
				<img :src="helpIcon" alt />
			</div>
		</div>
		<div class="panel-content wo_pad wo_pad_wo_overfl with_popup">
			<div
				class="graphics_panel_scrolled"
				:class="[
					{ accord_second_is_open: accordSecond },
					{ accord_first_is_closed: !accordFirst },
				]"
			>
				<div class="panel_row pb_18">
					<label class="blue_btn file_input_btn blue_btn full_w_btn">
						upload your own
						<input
							v-custom-upload
							type="file"
							data-type="0"
							ref="file"
							accept="image/*"
							@change="handleUploadImage"
						/>
					</label>
				</div>
				<div
					class="panel_accordion panel_accordion_first"
					v-bind:class="{ active: accordFirst }"
				>
					<div class="panel_accordion_header" v-on:click="accordHandlerFirts()">
						<h3 class="accordion_title">ALL GRAPHICS</h3>
						<span class="accordion_arrow">
							<span class="inch-down-arrow-icon"></span>
						</span>
					</div>
					<div class="panel_accordion_body">
						<div class="panel_row pt_8" v-if="isOnline">
							<SearchInput
								SearchInputPlaceholder="Search graphics"
								:searchSendRequest="searchSendRequest"
								@searchUpdate="searchSendRequest"
							/>
						</div>

						<div v-if="isOffline" class="is_offline">
							<Offline></Offline>
						</div>						
						
						<div class="panel_row pt_36" v-if="isOnline">
							<div class="text_label_with_icon">
								<label class="text_label">{{
									!querySearch ? "Popular" : "Results"
								}}</label>
								<span
									v-if="isOnline"
									class="inch-icon inch-expand-icon open_popup_btn"
									v-tooltip.top="'Expand'"
									@click="changePopup('popupGraphics', true)"
								></span>
							</div>

							<div
								class="loader-wrapper"
								v-if="panelLoading"
								style="width: 100%"
							>
								<div class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
							<div v-bar>
								<div
									class="accord-popular-items"
									:class="getGraphicStyles()"
									@scroll="contentUpload($event)"
								>

									<div class="graphics_items_wrpr" v-if="isOnline">
										<div
											v-for="item in graphicsItem.filter((g) => !g.isHide)"
											:key="item.id"
											@click="selectGraphic(item)"
											:style="item.categoryId == 5 ? { background: '#353535' } : {}"
											class="thumb-wrap graphic with-border radius-4 small-thumb-graph graphic_item"
											:class="{ active: item.active }"
										>
											<div
												class="thumb-icons bottom-right"
												v-if="item.isPremium && !isHavePremium"
											>
												<span
													class="inch-icon inch-plus-membership-icon"
												></span>
											</div>
											<img 
											loading="lazy"
											:src="item.thumb || item.imgPath" alt />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="panel_accordion accord-graphics"
					:style="{ 'border-top': '1px solid #303030' }"
					v-bind:class="{ active: accordSecond }"
				>
					<div
						class="panel_accordion_header"
						v-on:click="accordHandlerSecond()"
					>
						<h3 class="accordion_title">your graphics</h3>
						<span class="accordion_arrow">
							<span class="inch-down-arrow-icon"></span>
						</span>
					</div>
					<div class="panel_accordion_body">
						<div class="panel_row">
							<div v-bar>
								<div
									class="accord-graphics-items your-graphics"
									:class="{ fullPage: !accordFirst }"
								>
									<div class="graphics_items_wrpr">
										<div
											v-for="(item, index) in myGraphics"
											:key="index"
											class="thumb-wrap graphic with-border radius-4 small-thumb-graph graphic_item hover-overlay"
											:style="item.categoryId == 5 ? { background: '#353535' } : {}"
											@click="popUpPropChange(true, item.id)"
											draggable="true"
											@dragstart="
												dragGraphicStart($event, item.imgPath, item.id)
											"
										>
											<img :src="item.imgPath" class="thumb-z" />
											<div
												class="thumb-icons bottom-right thumb-z"
												v-if="item.isPremium && !isHavePremium"
											>
												<span
													class="inch-icon inch-plus-membership-icon"
												></span>
											</div>
											<div
												class="thumb-icons hidden top-right thumb-z"
												:style="{ 'z-index': 9999999 }"
												@click.stop="closeGraphic(item.id)"
											>
												<span class="inch-icon active inch-close-icon"></span>

												<div class="thumb-icons hidden thumb-z drag-or-click">
													<span class="tooltip-block">Drag or Click</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Tips
			v-if="popupTipsShow"
			:tipsHide="closePopups"
			text="The Graphics library offers a wide selection of over 50,000 high quality icons and graphics to choose from."
		>
			<template v-slot:body>
				<div class="tooltip_row_item">
					<p class="simple_light_p with_icon">
						<span class="inch-icon inch-expand-icon"></span> Expand Graphics
						panel for a more comfortable browsing experience
					</p>
				</div>
				<div class="tooltip_row_item">
					<p class="simple_light_p with_icon">
						<span class="inch-icon circle_icon"></span> Search graphics using
						the search feature
					</p>
				</div>
				<div class="tooltip_row_item">
					<p class="simple_light_p with_icon">
						<span class="inch-icon circle_icon"></span> Click on a graphic to
						add it to <i>Your Graphics</i> tab
					</p>
				</div>
				<div class="tooltip_row_item">
					<p class="simple_light_p with_icon">
						<span class="inch-icon circle_icon"></span> Click on a graphic from
						<i>Your Graphics</i> tab to add it to your project
					</p>
				</div>
			</template>
			<template v-slot:footer v-if="!isPremiumAccount">
				<div class="tooltip_row_item">
					<p class="simple_light_p with_icon">
						<span class="inch-icon inch-plus-membership-icon"></span> Indicates
						the graphic is a Pro feature. Get
						<a
							@click="upgradePlan()"
							class="colorcinchplus"
							>Pro</a
						>
					</p>
				</div>
			</template>
		</Tips>
	</div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import Tips from "@/components/Tips.vue";
import PopupGraphicProp from "@/components/popupPanel/PopupGraphicProp.vue";
import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";
import storage from "@/libs/storage.js";

import Offline from "@/components/Offline.vue";

export default {
	name: "GraphicsPanel",
	props: {
		sidebarPanelClass: String,
	},
	components: {
		SearchInput,
		Tips,
		PopupGraphicProp,
		PopupLayerProp,
		Offline
	},
	data: function () {
		return {
			popupLayerPropVisible: false,
			popupTipsShow: false,
			querySearch: "",
			tipsText:
				"Lorem ipsum dolor sit, amet consectetur alias, itaque odio ipsum quas quidem quos nostrum.",
			helpIcon: require("@/assets/img/help-icon.svg"),
			rabbitImg: require("@/assets/img/rabbit_image.png"),
			myGraphics: [],
			graphicsItem: [],
			cacheSearch: [],
			layerPropConfig: {},
			dispatchUpdateTrigger: false,
			accordFirst: true,
			accordSecond: false,
			checkedFillColor: false,
			panelLoading: false,
			isHavePremium: false,
			pageControl: {
				currentPage: 1,
				totalPages: 1,
				isLoading: false,
			},
		};
	},

	async mounted() {
		this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
		this.isHavePremium = await this.$store.dispatch("isPremiumCurrentUser");
		if(navigator.onLine){
			await this.loadGraphics("/api/graphics/popular", true);
		}else{
		}
		this.updateMyGraphics(this.$store.state.popupGraphicsData);

		this.accordSecond = !!this.myGraphics.length;

		this.loadingPopUp();
	},

	methods: {
		upgradePlan() {
			// if (window.abVariant && window.abVariant == 2) {
				this.$store.dispatch("popupsToggle", {
					property: "stripeCheckoutPopup",
					value: true,
				});
			// }
			// else{
			// 	this.$store.dispatch("popupsToggle", {
			// 		property: "upgradePopup",
			// 		value: true,
			// 	});
			// }
		},
		loadingPopUp() {
			const graphicsPopUp = this.$store.state.graphicsPopUp;
			if(graphicsPopUp) return;
			fetch("/api/graphics/all?page=1").then(async (result) => {
				const json = await result.json();

//				json.data.forEach((data) => fetch(data.thumb));

				if (!graphicsPopUp) {
					let categories = await fetch(
						`${this.$store.state.prefixURL}/api/graphics`
					).then((res) => res.json());

					categories.unshift({
						active: true,
						id: "all",
						title: "All",
					});

					if (!this.$store.state.graphicsPopUp) {
						this.$store.state.graphicsPopUp = {
							categories,
							graphicItems: json.data,
							categoryId: 'all',
							pageControl: {
								currentPage: 1,
								totalPages: json.total_pages,
								isLoading: false,
							}
						};
					}
				}
			});
		},

		async contentUpload(event) {
			const element = $(".accord-popular-items")[0];
			const scrolled =
				element.scrollTop >= element.scrollHeight - element.clientHeight - 15;

			if (this.querySearch) return;
			if (
				scrolled &&
				!this.pageControl.isLoading &&
				this.pageControl.currentPage < this.pageControl.totalPages
			) {
				this.pageControl.isLoading = true;
				this.pageControl.currentPage += 1;

				const url = `/api/graphics/popular?page=${this.pageControl.currentPage}`;

				const result = await fetch(
					`${this.$store.state.prefixURL}${url}`
				).then((res) => res.json());

				this.pageControl.totalPages = result.total_pages;

				const res = result.data.filter(
					(data) => !this.graphicsItem.find((gi) => gi.id == data.id)
				);
				this.graphicsItem.push(...res);

				this.pageControl.isLoading = false;
			}
		},

		getGraphicStyles() {
			if (this.accordSecond) {
				return {};
			}

			return {
				"calc-height-acco330": !this.myGraphics.length,
				"calc-height-acco368": this.myGraphics.length,
			};
		},

		async loadGraphics(url, isPopular) {
			this.panelLoading = true;

			const result = await fetch(
				`${this.$store.state.prefixURL}${url}`
			).then((res) => res.json());

			if (isPopular) {
				this.pageControl.totalPages = result.total_pages;
			} else {
				this.pageControl.currentPage = 1;
			}

			this.graphicsItem = result.data;
			if (this.pageControl.currentPage == 1) {
				$(".accord-popular-items")[0].scrollTo(0, 0);
			}

			this.panelLoading = false;
		},

		async parseGraphics(graphic) {
			let img = new Image();
			img.src = graphic.url || graphic.imgPath;
			graphic.image = await new Promise(
				(resolve) => (img.onload = () => resolve(img))
			);

			return graphic;
		},

		accordHandlerFirts() {
			this.accordFirst = !this.accordFirst;
		},

		accordHandlerSecond() {
			this.accordSecond = !this.accordSecond;
		},

		async updateMyGraphics(graphicsData = []) {
			this.myGraphics = graphicsData;
			this.graphicsItem = this.graphicsItem.map((item) => {
				item.active = !!this.myGraphics.find((m) => m.id == item.id);

				return item;
			});

			let parsedGraphics = [];
			let isUpdate = false;
			for (let gr of graphicsData) {
				if (!gr.image) {
					isUpdate = true;

					gr = await this.parseGraphics(gr);
				}

				parsedGraphics.push(gr);
			}

			if (isUpdate) {
				this.$store.dispatch("popupGraphicsData", parsedGraphics);
			}
		},

		dragGraphicStart(e, imgPath, id) {
			const image = this.myGraphics.find((mg) => mg.id == id).image;
			const img = this.$lib.lib.resizeDragImage(image, 83);

			const data = {
				src: imgPath,
				layerType: "graphics",
				width: img.width,
				height: img.height,
				offsetX: img.width / 2,
				offsetY: img.height / 2,
				positionAuto: e.ctrlKey,
				id,
			};

			e.dataTransfer.setData("dataDrop", JSON.stringify(data));
			e.dataTransfer.setDragImage(img, data.offsetX, data.offsetY);
		},

		async selectGraphic(item) {
			//console.log(item);
			if (item.isPremium) {
				if (!this.isHavePremium) {
					// if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
					return;
				}
			}

			const graphicItem = this.myGraphics.find(
				(myGraphic) => myGraphic.id == item.id
			);

			if (!graphicItem) {
				item = await this.parseGraphics(item);
				this.myGraphics.push(item);
			} else {
				this.myGraphics = this.myGraphics.filter(
					(myGraphic) => myGraphic.id != item.id
				);
			}

			if (this.myGraphics.length) {
				this.accordSecond = true;
			} else {
				this.accordSecond = false;
			}

			this.$store.dispatch("popupGraphicsData", this.myGraphics);
		},

		dispatchConfigLayerProperties() {
			this.$store.dispatch("globalLayerPopUpParams", {
				...this.$store.state.globalLayerPopUp.params,
				...this.layerPropConfig,
			});

			this.$store.dispatch("isUpdateCache");
		},

		calculateIntensity(intensity) {
			return intensity < 0 ? 50 - Math.abs(intensity) / 2 : 50 + intensity / 2;
		},

		popUpGraphChanges({ color, intensity }) {
			this.layerPropConfig.color = color;
			this.layerPropConfig.intensity = intensity;

			this.setBlendMode();
		},

		setBlendMode() {
			//if (!this.layerPropConfig.color) return;
			/* const parsedRGBA = this.$lib.lib.rgbaToHexAlpha(
									this.layerPropConfig.color
								); */

			this.$lib.fabric.setBlendMode(
				!this.layerPropConfig.color ? "clear_filters" : "tint",
				this.layerPropConfig.intensity,
				//this.calculateIntensity(),
				this.layerPropConfig.color,
				!!this.layerPropConfig.color
			);

			this.dispatchConfigLayerProperties();
		},

		async handleUploadImage(e) {
			if (e.target.files && e.target.files[0]) {
				this.$store.dispatch("preloader", {
					message: "Uploading...",
					isShow: true,
				});

				const file = e.target.files[0];
				const data = {
					id: Date.now(),
					imgPath: await this.convertBlobToBase64(file),
					active: true,
					isUploaded: true,
					title: "Upload",
				};
				

				this.myGraphics.unshift(await this.parseGraphics(data));
				await this.$store.dispatch("popupGraphicsData", this.myGraphics);

				this.$store.dispatch("preloader", {
					message: "",
					isShow: false,
				});

				this.accordSecond = true;
			}
		},

		async searchSendRequest(event) {
			this.querySearch = event.target.value;

			if (this.querySearch.length) {
				await this.loadGraphics(
					"/api/graphics/search?search=" + this.querySearch
				);
			} else {
				this.pageControl.currentPage = 1;
				await this.loadGraphics("/api/graphics/popular", true);
			}

			/* this.graphicsItem = this.graphicsItem.map(item => {
									if (
										this.querySearch &&
										!item.title.toLowerCase().includes(this.querySearch.toLowerCase())
									) {
										item.isHide = true;
									} else {
										item.isHide = false;
									}

									return item;
								}); */
			/* const inCache = this.cacheSearch.find(search => search.query === query);
								if (inCache) {
									this.graphicsItem = inCache.graphicsItem;
								} else {
									const request = await fetch(
										`https://freesvgclipart.com/wp-json/clipart/api?num=20&query=${query}`
									).then(response => {
										return response.json();
									});

									console.log(request);
								} */
		},

		async changePopup(property, value) {
			await this.$store.dispatch("popupGraphicsData", this.myGraphics);
			this.$store.dispatch("popupsToggle", { property, value });
		},

		closePopups() {
			this.$store.state.global.showPropertiesGraphics = false;
			this.popupLayerPropVisible = false;
			this.popupTipsShow = false;
		},

		async convertBlobToBase64(blob) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onerror = reject;
				reader.onload = () => {
					resolve(reader.result);
				};
				reader.readAsDataURL(blob);
			});
		},

		popupLayerPropShow() {
			return (this.popupLayerPropVisible = !this.popupLayerPropVisible);
		},

		async popUpPropChange(status, id) {
			const graphic = this.myGraphics.find((graphic) => graphic.id == id);
			if (!graphic) {
				this.$store.state.global.showPropertiesGraphics = status;
				return;
			}

			if(navigator.onLine){

				fetch(
					`${this.$store.state.prefixURL}/api/graphics/update_statistic/${id}`
				).then((res) => res.json());
				
			}
			
			this.$store.dispatch("preloader", {
				message: "Adding Graphics...",
				isShow: true,
			});

			this.$lib.app.renderAsGraphicsSprite(
				graphic.imgPath,
				() => {
					this.$store.dispatch("preloader", { message: "", isShow: false });
					this.$store.dispatch("isUpdateCache");

					this.$store.state.global.showPropertiesGraphics = status;
				},
				{
					mapId: this.$lib.CONST.MAP_CHANGES.ADD_GRAPHIC,
				}
			);
		},

		/* async selectGraphic(id) {
							this.graphicsItem.map(item => {
								if (item.id === id) {
									item.active = !item.active;
								}
							});

							const activeGraphics = this.graphicsItem.filter(item => item.active);
							this.myGraphics = this.myGraphics.filter(
								myGraphisc => myGraphisc.isUploaded
							);

							this.myGraphics.push(...activeGraphics);
						}, */

		closeGraphic(id) {
			this.$store.state.global.showPropertiesGraphics = false;
			this.myGraphics = this.myGraphics.filter((item) => {
				return item.id != id;
			});

			this.graphicsItem = this.graphicsItem.map((item) => {
				if (item.id === id) {
					item.active = false;
				}
				return item;
			});

			this.$store.state.popupGraphicsData = this.myGraphics;
			if (!this.myGraphics.length) {
				this.accordSecond = false;
			}
		},

		popTipsToggle() {
			this.popupTipsShow = !this.popupTipsShow;
		},

		setLayerProperties(layerPropConfig) {
			for (const key in layerPropConfig) {
				if (this.layerPropConfig[key] != layerPropConfig[key]) {
					this.layerPropConfig = layerPropConfig;

					this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
					break;
				}
			}
		},
	},

	created() {
		this.setLayerProperties({
			...this.$store.state.globalLayerPopUp.default,
			...this.$store.state.globalLayerPopUp.params,
		});
	},

	watch: {
		getPopupGraphicsData(myGraphics) {
			storage.setItem("myGraphics", myGraphics);
			this.updateMyGraphics(myGraphics);
		},

		getGlobalLayerParams(layerPropConfig) {
			this.setLayerProperties(layerPropConfig);
		},

		updateMyGraphicsStore() {
			if (this.myGraphics.length) {
				this.accordSecond = true;
			} else {
				this.accordSecond = false;
			}
		},
	},

	computed: {
		updateMyGraphicsStore() {
			return this.$store.state.updateMyGraphics;
		},

		isPremiumAccount() {
			return this.$store.state.isPremiumAccount;
		},

		showPropertiesGraphics() {
			return this.$store.state.global.showPropertiesGraphics;
		},

		popUpVisible() {
			return this.$store.state.global.showPropertiesGraphics;
		},

		getPopupGraphicsData() {
			return this.$store.state.popupGraphicsData;
		},

		getGlobalLayerParams() {
			return {
				...this.$store.state.globalLayerPopUp.default,
				...this.$store.state.globalLayerPopUp.params,
			};
		},
	},
};
</script>
<style>
.thumb-z {
	z-index: 1;
}

.calc-height-acco368 {
	height: calc(100vh - 368px) !important;
}

.calc-height-acco330 {
	height: calc(100vh - 330px) !important;
}

.tooltip-block {
	letter-spacing: 0px;
	color: #d7dcdd;
	font-size: 12px;
	background: #2a2a2a 0% 0% no-repeat padding-box;
	box-shadow: 1px 1px 2px #1d1d1db3;
	border-radius: 2px;
	padding: 6px 8px;
}

.drag-or-click {
	z-index: 9999;
	bottom: -78px;
	/* left: 9px; */
	left: -1000px;
	transition: 0 !important;
}

.thumb-wrap:hover .drag-or-click {
	left: -59px;
	transition: 0s 1s !important;
}
.your-graphics {
	height: calc(100vh - 725px) !important;
}

.fullPage {
	height: calc(100vh - 273px) !important;
}
</style>
