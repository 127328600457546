<template>
  <div class="popup graphics_popup">
    <div class="popup_header">
      <h3>graphics</h3>
      <a
        class="close_popup_btn"
        v-on:click="changePopup('popupGraphics', false)"
      >
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="graphics_popup_left">
        <div class="panel_row">
          <SearchInput
            :searchSendRequest="searchByKeywords"
            SearchInputPlaceholder="Search graphics"
            :searchValue="searchKeywords"
            @searchUpdate="searchByKeywords"
          />
        </div>
        <div v-bar>
          <div class="categories_list_mh">
            <ul class="categories_list">
              <li>
                <span>categories</span>
              </li>
              <div class="loader-wrapper" v-if="panelLoading">
                <!-- <div class="loader_dot_wrpr">
                  <span class="loader_dot"></span>
                  <span class="loader_dot"></span>
                  <span class="loader_dot"></span>
                </div>-->
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <template v-else>
                <li v-for="category in categories" :key="category.id">
                  <a
                    href="#"
                    :class="{ active: category.active }"
                    @click="loadItemsById(category.id)"
                    >{{ category.title }}</a
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="graphics_popup_right">
        <!-- <h3 class="graphics_popup_right_title" v-if="searchKeywords">
          Search results for "
          <b>{{ searchKeywords }}</b>"

          .filter(graphicItem => !graphicItem.hidden)
        </h3>-->
        <div v-bar :style="{ height: '100%', 'padding-bottom': '38px' }">
          <div
            class="graphics_popup_right_items_wrap"
            @scroll="contentUpload($event)"
          >
            <div
              class="graphics_items_wrpr"
              :style="{
                'justify-content': 'center',
                'max-height': 'inherit !important',
              }"
            >
              <div
                v-for="item in graphicItems.filter(
                  (graphicItem) => !graphicItem.hidden
                )"
                :key="item.id"
                class="thumb-wrap graphic with-border radius-4 small-thumb-graph graphic_item"
                :style="categoryId == 5 ? { background: '#353535' } : {}"
                :class="{ active: item.isActive }"
                @click="selectItem(item)"
              >
                <div class="thumb-icons bottom-right">
                  <span
                    v-if="!isPremiumAccount && item.isPremium"
                    class="inch-icon inch-plus-membership-icon"
                  ></span>
                </div>
                <img 
                loading="lazy"
                :src="item.thumb || item.imgPath" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "PopupGraphics",
  components: {
    SearchInput,
  },
  props: {},
  data: function () {
    return {
      panelLoading: true,
      graphicItemsLoader: true,
      graphicItems: [],
      categories: [],
      myGraphics: [],
      searchKeywords: "",
      categoryId: undefined,
      pageControl: {
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      },

      scrollTop: 0,
      cache: [],
      counterError: 0,
    };
  },
  async mounted() {
    this.myGraphics = this.$store.state.popupGraphicsData || [];

    const config = this.$store.state.graphicsPopUp;
    if (
      config &&
      config.categories &&
      config.graphicItems
    ) {
      const blockElement = $(".graphics_popup_right_items_wrap");

      this.categories = config.categories;
      this.graphicItems = config.graphicItems;
      if(config.pageControl) {
        this.pageControl = config.pageControl;
      }
      
      this.categoryId = config.categoryId;
      this.searchKeywords = config.searchKeywords;

      this.$nextTick(() => {
        let scrollTop = config.scrollTop;
        if (
          blockElement.width() != config.w ||
          blockElement.height() != config.h
        ) {
          const el = $(".graphics_popup_right_items_wrap")
            .find(".thumb-wrap")
            .find(`[src="${config.firstScrollSrc}"]`)[0];

          if (el) {
            scrollTop = el.parentElement.offsetTop;
          }
        }

        blockElement[0].scrollTo(0, scrollTop);

        this.contentUpload();
      });

      this.loadInCache(
        `${this.$store.state.prefixURL}/api/graphics/${this.categoryId}?page=${
          this.pageControl.currentPage + 1
        }`
      );

      this.panelLoading = false;
    } else {
      await this.loadCategories();
    }
  },

  beforeDestroy() {
    const blockElement = $(".graphics_popup_right_items_wrap");
    const elements = Array.from($(".thumb-wrap"));

    const firstScrollSrc = $(
      elements.find((el) => el.offsetTop >= blockElement[0].scrollTop - 110)
    )
      .find("img")
      .attr("src");

    this.$store.state.graphicsPopUp = {
      ...(this.$store.state.graphicsPopUp
        ? this.$store.state.graphicsPopUp
        : {}),
      categories: this.categories,
      graphicItems: this.graphicItems,
      pageControl: this.pageControl,
      scrollTop: blockElement[0].scrollTop,
      categoryId: this.categoryId,
      w: blockElement.width(),
      h: blockElement.height(),
      firstScrollSrc,
      searchKeywords: this.searchKeywords,
    };
  },

  methods: {
    async contentUpload(event) {
      const element = $(".graphics_popup_right_items_wrap")[0];
      const scrolled =
        element.scrollTop >= element.scrollHeight - element.clientHeight - 15;

      if (
        scrolled &&
        !this.pageControl.isLoading &&
        this.pageControl.currentPage < this.pageControl.totalPages
      ) {
        const category = this.categories.find((c) => c.id == this.categoryId);
        if (!category) return;

        if (this.pageControl.isLoading) return;
        this.pageControl.isLoading = true;
        this.pageControl.currentPage += 1;
        await this.loadItemsById(this.categoryId, true);
        //this.pageControl.isLoading = false;
      }
    },

    scrollToTop() {
      const element = $(".graphics_popup_right_items_wrap")[0];
      element.scrollTo(0, 0);
    },

    async loadCategories() {
      this.panelLoading = true;
      const categories = await fetch(
        `${this.$store.state.prefixURL}/api/graphics`
      ).then((response) => {
        return response.json();
      });

      categories.unshift({
        active: true,
        id: "all",
        title: "All",
      });

      this.categories = categories;

      if (this.categories[0]) {
        await this.loadItemsById(this.categories[0].id);
        this.panelLoading = false;
      }
    },

    async loadInCache(url) {
    	return ;
      try {
        if (!this.cache.find((c) => c.url == url)) {
          const graphs = await fetch(url).then((res) => res.json());

          if (!this.cache.find((c) => c.url == url)) {
            this.cache.push({
              data: graphs,
              url: url,
            });

            graphs.data.forEach((graph) => {
              fetch(graph.thumb);
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    loader(isShow = false) {
      this.$store.dispatch("preloader", {
        message: "Loading...",
        isShow,
      });
    },

    async loadItemsById(id, isAdd = false) {
      this.searchKeywords = "";
      this.categoryId = id;

      this.loader(true);
      if (!isAdd) {
        this.pageControl.currentPage = 1;
      }

      this.graphicItemsLoader = true;
      const graphicUrl = `${this.$store.state.prefixURL}/api/graphics/${id}`;
      const loadUrl = `${graphicUrl}?page=${this.pageControl.currentPage}`;

      const inCache = this.cache.find((c) => c.url == loadUrl);

      let graphicItems = [];
      if (inCache) {
        graphicItems = inCache.data;
        if (graphicItems.total_pages > this.pageControl.currentPage) {
          this.loadInCache(
            `${graphicUrl}?page=${this.pageControl.currentPage + 1}`
          );
        }
      } else {
        try {
          graphicItems = await fetch(loadUrl).then((response) =>
            response.json()
          );

          if (graphicItems.total_pages > this.pageControl.currentPage) {
            this.loadInCache(
              `${graphicUrl}?page=${this.pageControl.currentPage + 1}`
            );
          }
        } catch (err) {
          if (this.counterError >= 5) {
            this.counterError = 0;
            this.graphicItemsLoader = false;
            return;
          }

          if (!isAdd) {
            pageControl.currentPage -= 1;
          }

          await new Promise((resolve) => {
            setTimeout(async () => {
              resolve(await loadItemsById(id, isAdd));
            }, 100);
          });
          return;
        }
        // graphicItems = await fetch(loadUrl).then((response) => response.json());

        if (!this.cache.find((c) => c.url == loadUrl)) {
          this.cache.push({
            data: graphicItems,
            url: loadUrl,
          });
        }
      }

      if (!isAdd) {
        this.categories = this.categories.map((category) => {
          category.active = category.id == id;
          return category;
        });
      }

      this.parseActiveItems(graphicItems, isAdd);
      this.counterError = 0;
      this.graphicItemsLoader = false;

      this.loader();
    },

    parseActiveItems(graphicItems, isAdd) {
      let graphics = [];

      if (graphicItems.data) {
        this.pageControl.totalPages = graphicItems.total_pages;

        graphics = graphicItems.data;
      } else {
        graphics = graphicItems;
      }

      const items = graphics
        .map((graphic) => {
          if (
            this.myGraphics.find(
              (myGraphic) => myGraphic.imgPath == graphic.imgPath
            )
          ) {
            graphic.isActive = true;
          }

          //graphic.hidden = this.isHiddenItem(graphic);
          return graphic;
        })
        .filter((gr) => !this.graphicItems.find((gi) => gi.id == gr.id));

      this.graphicItems = [...(isAdd ? this.graphicItems : []), ...items];

      if (!isAdd) {
        this.scrollToTop();
      }

      this.$nextTick(() => {
        this.pageControl.isLoading = false;
        this.contentUpload();
      });
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    isHiddenItem(graphic) {
      return (
        !graphic.imgPath ||
        !graphic.title ||
        (this.searchKeywords &&
          graphic.title &&
          !graphic.title
            .toLowerCase()
            .includes(this.searchKeywords.toLowerCase()))
      );
    },

    async selectItem(item) {
      if (!item) return;
      if (item.isPremium) {
        if (!this.isPremiumAccount) {
          // if (window.abVariant && window.abVariant == 2) {
              this.$store.dispatch("popupsToggle", {
                  property: "stripeCheckoutPopup",
                  value: true,
              });
          // }
          // else{
          //     this.$store.dispatch("popupsToggle", {
          //         property: "upgradePopup",
          //         value: true,
          //     });
          // }
          return;
        }
      }

      if (item.isActive) {
        this.myGraphics = this.myGraphics.filter(
          (myGraphic) => myGraphic.imgPath !== item.imgPath
        );
      } else if (!this.myGraphics.find((g) => g.imgPath == item.imgPath)) {
        item = await this.parseGraphics(item);
        this.myGraphics.push({
          ...item, categoryId: this.categoryId,
        });
      }

      this.graphicItems = this.graphicItems.map((graphicItem) => {
        if (graphicItem.id == item.id) {
          graphicItem.isActive = !graphicItem.isActive;
        }

        return graphicItem;
      });

      this.$store.dispatch("popupGraphicsData", this.myGraphics);
      this.$store.state.updateMyGraphics += 1;
    },

    async parseGraphics(graphic) {
      let img = new Image();
      img.src = graphic.url || graphic.imgPath;
      graphic.image = await new Promise(
        (resolve) => (img.onload = () => resolve(img))
      );

      return graphic;
    },

    async loadGraphics(url) {
      //this.panelLoading = true;
      this.loader(true);
      this.parseActiveItems(
        await fetch(`${this.$store.state.prefixURL}${url}`).then((res) =>
          res.json()
        )
      );
      this.loader(false);
      //this.panelLoading = false;
    },

    async searchByKeywords(event) {
      let keywords = event.target.value;
      if (this.searchKeywords == keywords) return;
      this.searchKeywords = keywords;

      if (this.searchKeywords.length && this.searchKeywords.length >= 3) {
        let url = "/api/graphics/search?search=" + this.searchKeywords;
        if (
          (this.categoryId || this.categoryId == 0) &&
          this.categoryId !== "all"
        ) {
          url += `&category_id=${this.categoryId}`;
        }

        await this.loadGraphics(url);
      } else {
        if (!this.categories || !this.categories.length) {
          return await this.loadCategories();
        }

        let selectedCategoryId = this.categories[0].id;

        if (this.categoryId || this.categoryId == 0) {
          selectedCategoryId = this.categoryId;
        }

        await this.loadItemsById(selectedCategoryId);
      }

      /* this.graphicItems = this.graphicItems.map(item => {
        return {
          ...item,
          hidden: this.isHiddenItem(item)
        };
      }); */
    },
  },

  watch: {
    getPopupGraphicsData(myGraphics) {
      this.myGraphics = myGraphics || [];
    },
  },

  computed: {
    getPopupGraphicsData() {
      return this.$store.state.popupGraphicsData;
    },

    isPremiumAccount() {
      return this.$store.state.isPremiumAccount;
    },
  },
};
</script>

<style scoped>
.graphics_popup {
  height: calc(100vh - 80px) !important;
  width: calc(100vw - 100px) !important;
}

.popup_body {
  height: 100% !important;
}
</style>
