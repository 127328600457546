const { Model } = require("vue-property-decorator");

const categories = {
    openTab: 'Open Tab',
    edit: 'Edit',
    effect: 'Effect',
    filter: 'Filter',
    frame: 'Frame',
    mask: 'Mask',
    overlay: 'Overlay'
};

module.exports = new class {
    constructor() {
        this.categ = categories;
    }

    sendEvent(name, category) {
        console.log('ANALITICS', name, category);
        this.gtag('event', name, {
            'event_category': category,
        });
    }

    sendCustomEvent(name, data = {}){
        console.log('sendCustomEvent->', name, data);
        if(Object.keys(data).length === 0) {
            this.gtag('event', name);
        } else {
            this.gtag('event', name, data);
        }
    }

    gtag(){
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(arguments)
    }
};