<template>
  <div class="input_with_icon">
    <input
      type="text"
      name
      :value="searchValue || ''"
      class="text_input search_input"
      :placeholder="SearchInputPlaceholder"
      :disabled="SearchInputDisabled || false"
      @input="searchInput"
      @update="searchUpdate"
      v-on:keyup.enter="searchSendRequest"
    />
    <span class="inch-search-icon input_icon" @click="searchButton()"></span>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    SearchInputPlaceholder: String,
    SearchInputDisabled: Boolean,
    searchSendRequest: Function,
    searchValue: String
  },
  data: function() {
    return {
      inputValue: this.searchValue || ''
    };
  },
  methods: {
    searchInput(value) {
      this.inputValue = value.target.value;
      this.$emit('searchInput', value);
    },
    
    searchUpdate(value) {
      this.$emit('searchUpdate', value);
    },

    searchButton() {
      console.log(this.inputValue);
      this.$emit('searchUpdate', { target: { value: this.inputValue } });
    }
  }
};
</script>

<style scoped>
.inch-search-icon {
    pointer-events: all !important;
    cursor: pointer;
}

.inch-search-icon::before {
  padding: 10px 12px;
}
</style>