<template>
    <div class="beginner_tooltip" :class="addClass">
        <div class="tooltip_row" v-if="text">
            <p class="simple_light_p">{{text}}</p>
            <p v-if="subText" class="simple_light_p top-margin" v-html="subText"></p>
        </div>
        <div class="tooltip_row">
            <slot name="body"></slot>
        </div>
        <div class="tooltip_row" v-if="hasFooterSlot">
            <slot name="footer"></slot>
        </div>
        <div class="tooltip_row tooltip_row_footer">
            <Checkmark msg="Disable Tips" :checkboxCheck="isDisabled" @update="updateDisabledTips"/>
            <a class="btn_textual" @click="closePopup('popupsToggleStatus', false), tipsHide ? tipsHide() : ''">got it, thanks</a>
        </div>
    </div>
</template>

<script>
    import Checkmark from "@/components/Checkmark.vue"

    export default {
        name: 'Tips',
        props: {
            text: String,
            subText: String,
            addClass: String,
            tipsHide: Function
        },

        methods: {
            test() {
                alert();
            },
            updateDisabledTips(e) {
                this.$store.state.tipsComponents._isHideAll = e.target.checked;

                localStorage.setItem('tipsComponents', JSON.stringify(this.$store.state.tipsComponents));
            },

            closePopup(property, value) {
                this.$store.dispatch("popupsToggleStatus", {property, value});
            }
        },

        computed: {
            isDisabled() {
                return !!(this.$store.state.tipsComponents._isHideAll);
            },

            hasFooterSlot () {
                return !!this.$slots['footer'];
            }
        },

        components: {
            Checkmark
        }
    }
</script>
<style scoped>
    .top-margin{
        margin-top: 18px;
    }
</style>
