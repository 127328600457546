<template>
  <div class="popup settings-popup" :class="panelClass">
    <div class="popup_header">
      <h3>settings</h3>
      <a href="#" class="close_popup_btn" v-on:click="closePopup">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
    <div class="popup_body_row shortcut" @click="showSo">
      <span class="short">Ctrl</span>
      Shortcuts
    </div>


      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <div class="toggle_check_wrpr">
            <label class="text_label">Show Guides</label>
            <label class="toggle_check_btn">
              <input 
                type="checkbox" 
                name="showGuides" 
                :checked="!!showGuides" 
                @change="showHideGuides()"/>
              <div class="toggle_check_view">
                <span class="toggle_check_circle"></span>
              </div>
            </label>
          </div>
        </div>
        <div class="popup_body_row_item">
          <div class="toggle_check_wrpr">
            <label class="text_label">Snap to Guides</label>
            <label class="toggle_check_btn">
              <input 
                type="checkbox" 
                name="snapToGuides" 
                :checked="settingsState.snapToGuides"
                @change="changeSnapGuides"/>
              <div class="toggle_check_view">
                <span class="toggle_check_circle"></span>
              </div>
            </label>
          </div>
        </div>


        <div class="popup_body_row_item">
          <div class="toggle_check_wrpr">
            <label class="text_label">Enable Auto-Save</label>
            <label class="toggle_check_btn">
              <input 
                type="checkbox" 
                name="enableAutosave" 
                :checked="settingsState.enableAutosave"
                @change="changeAutoSave"/>
              <div class="toggle_check_view">
                <span class="toggle_check_circle"></span>
              </div>
            </label>
          </div>
        </div>
        
        <!-- <div class="popup_body_row_item">
          <div class="toggle_check_wrpr">
            <label class="text_label">Autoload Last Project?</label>
            <label class="toggle_check_btn">
              <input 
                type="checkbox" 
                name="autoloadLastProject" 
                v-model="settingsState.autoloadLastProject"
                @change="changeSettings('autoloadLastProject', settingsState.showGuides)"/>
              <div class="toggle_check_view">
                <span class="toggle_check_circle"></span>
              </div>
            </label>
          </div>
        </div> -->
      </div>
      <div class="popup_body_row" v-if=!is_integration>
        <div class="popup_body_row_item">
          <div class="need_h_block">
            <p>
              Need Help?
              <a 
              :target="is_pwa?'_blank':'_self'"
              :href="getLink('support/')" class="btn_textual">See Our Tutorials</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="settings-overlay" :style="{ background: `rgba(0,0,0,${showShortcuts ? 0 : 0})` }" @click="closePopup"></div> -->
  </div>
</template>

<script>
import indexdb from '../../../libs/indexdb';
export default {
  name: "Settings",
  props: {
    sidebarPanelClass: String
  },

  data: function() {
    return {
      showShortcuts: false,
      is_pwa: false,
      panelClass: this.sidebarPanelClass,
      showGuides: false,
      is_integration: window.is_integration
    }
  },

  watch: {
    showShortcuts(val) {
      this.$store.state.showShortcuts = val;
      if(val) { this.closePopup(); }
    }
  },

  computed: {
    settingsState() {
      return this.$store.getters.SETTINGS
    }
  },

  mounted() {
    this.showGuides = !!this.$lib.fabric.isShowGuidesGrid;
	  this.is_pwa = window.matchMedia('(display-mode: standalone)').matches && typeof PaymentRequest!='undefined';	
  },

  methods: {
    showSo() {
      this.showShortcuts = true;  
    },
    
    getLink(suf) {
      return `${locationOrigin}/${suf}`;
    },

    showHideGuides() {
      this.showGuides = !this.showGuides;
      this.$lib.fabric.showHideGrid(this.showGuides);
      //changeSettings('showGuides', settingsState.showGuides)
    },
    changeSnapGuides(e) {
      const checked = e.target.checked;
      window.isActiveSnap = checked;
      this.$store.getters.SETTINGS.snapToGuides = checked;
    },
    
    changeAutoSave(e){
      const checked = e.target.checked;
      window.isAutosaveActive = checked;
      localStorage.autoSaveEnabled = checked?1:0;
      this.$store.getters.SETTINGS.enableAutosave = checked;

      if(checked) {
        const historyChanges = this.$lib._cache.getHistoryChanges();
        const lastSave = historyChanges[historyChanges.length - 1];

        indexdb.deleteAll('autosave');
      }
    },
    
    
    changeSettings(property, value) {
      this.$store.dispatch('settingsToggle', { property, value });
    },

    closePopup() {
      console.log('closePopup');
      this.$store.dispatch('sidebarPanelToggle', { panelSettings: 'hide' });
      this.showShortcuts = false;
    }
  }
};
</script>

<style scoped>
  .shortcut {
    padding: 10px 15px;
    color: #bfc3c4;
    cursor: pointer;
  }

  .shortcut:hover {
    color: #fff;
  }

  .short {
    padding: 3px 7px;
    background: #8c8c8c;
    color: #2a2a2a;
    border-radius: 6px;
    font-weight: bold;
    font-size: 9px;
    vertical-align: middle;
    margin-right: 6px;
  }

  .shortcut:hover .short {
    background-color: #fff;
  }
</style>
