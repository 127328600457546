<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Image De-oldify</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    De-oldify
                </a>
            </div>

            <PanelAccordionAi accordionTitle="Advanced" :accordActiveClass="false" accordAdditionalClass="with-border" accordBodyClass="pb-8">
                <template v-slot:panel_accordion_body>
                    <div class="panel_row">
                    <DropdownSelect
                        textLabel="Model Name" key="model_name"
                        :current="payload.model_name"
                        :selectOptions="model_names"
                        :selectClass="dropdowns['model_name']"
                        @toggleDropdown="changeDropdown('model_name')"
                        @selectDropdown="changeRange('model_name')($event)"
                        @resetSelectClass="changeDropdown('model_name', false)"
                        :skipHeight="true"
                    />

                    <span class="custom-label-text">Artistic offers vibrant colors but can leave parts of the image gray, while Stable is better for nature and avoids gray human areas.</span>
                </div>

                <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Render Factor</label>
                        <div class="btns_wrpr">
                            <div class="input_width_height_wrpr">
                                <InputWidthHeight
                                    :isChangeInputValueIncrement="true"
                                    :inputValue="payload.render_factor"
                                    :maxValue="100" :minValue="1"
                                    @change="changeRange('render_factor')($event.value)"
                                    inputName="render_factor"
                                />
                            </div>
                        </div>
                        <!-- <input type="number" min="1" class="text_input input_width_height" v-model="payload.render_factor"/> -->
                    </div>
                    <span class="custom-label-text">The default value of 35 is suitable for most scenarios, affecting the image's color resolution. Lower resolutions render faster and enhance color vibrancy, especially for older images. Higher render factors improve quality but may wash out colors.</span>
                </div>
                </template>
            </PanelAccordionAi>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Colorize and bring life to old, black-and-white photos.']"
            filename="Image_De-Oldify"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {
            "model_name": "Artistic",
            "render_factor": 35
        },

        payloadTemp: {
            seed: 0,
        },

        model_names: ['Artistic', 'Stable'],
        dropdowns: {
            model_name: ''
        }
      };
    },

    methods: {
        async generatePayload(image) {
            return { ...this.payload, input_image: image };
        },
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.ImageDeoldifyAPI;
        }
    }
  };
</script>
