export default {
    API_URL: 'https://api.unsplash.com',
    API_KEY:  'tV-Y4T6X09oRGIl23Wwcqyp1TG3Z_T0xPzJWUS22Iws',

    async statisticFetch(url = '') {
        const result = await fetch(`${url}?client_id=${this.getKey()}`).then(res => res.json());

        return result;
    },

    getKey() {
        return window.API_UNSPLASH_KEY || this.API_KEY;
    },
    
    async getPhotos(id, options = {}) {
        return this.request(`topics/${id}/photos`, options);
    },

    async findPhotos(options) {
        return this.request(`search/photos`, options);
    },

    async getCollections(options = {}) {
        return this.request('topics', { ...options, per_page: 50 });
    },

    async request(link, options) {
        try {
            return await fetch(`${this.API_URL}/${link}?client_id=${this.getKey()}&${$.param(options)}`)
                .then(res => res.json());
        } catch (err) {
            console.error(err);
            return [];
        }
    }
};