import LockZoom from './LockZoom';

export default {
    mixins: [LockZoom],

    props: {
        eraseConfig: Object,
    },
    
    data() {
        return {
            tab: 'adjust',
            tool: 'brush',
        };
    },

    methods: {
        changeToolName(tool) {
            this.tool = tool;

            if(window._isCurrentMixin) {
                this.changeConfig({ key: 'tool', value: tool });
            } else {
                this.$emit('changeConfig', { key: 'tool', value: tool });
            }
        },
    
        changeConfig(data) {
            if(window._isCurrentMixin) return this.changeConfig(data);

            this.$emit('changeConfig', data);
            //this.eraseConfig[key] = value;
        },
    
        resetPopupConfig() {
            this.tab = 'adjust';
            this.tool = 'brush';
        },
    
        undorChange() {
            this.$lib.Drawing.clearDrawing();
        },
    },

    watch: {    
        tab(value) {
            if(value == 'erase') {
                $(`.pop_up_closer`).css({ width: 0, height: 0 });
                this.$store.state.drawEventVisible = true;

                if(window._isCurrentMixin) {
                    this.selectPointerEvents(false);
                } else {
                    this.$emit('selectPointerEvents', false);
                }
            } else {
                this.$store.state.drawEventVisible = false;

                if(window._isCurrentMixin) {
                    this.selectPointerEvents(true);
                } else {
                    this.$emit('selectPointerEvents', true);
                }
            }
        }
    },
};