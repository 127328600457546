<template>
	<div :class="[{'tool-item-panel': !isMobileView}, sidebarPanelClass]">
		<div v-if="!isMobileView" class="panel-top without_hover">
			<div class="back">
				<h3>effects</h3>
			</div>
		</div>

		<div :class="{'panel-content': !isMobileView, 'wo_pad': !isMobileView, 'wo_pad_wo_overfl': !isMobileView}">
			<div v-if="!isMobileView" v-bar>
				<div class="panel_row effects_items_wrpr">
					<Favorites
						v-if="favoritesList.length"
						:favoritesCount="favoritesList.length"
						:favoritesOpen="favoritesOpen"
					/>
					<template v-for="cardItem in panels">
						<CategoryCard
							v-bind:key="cardItem.id"
							:toolLinkClick="toolLinkClick"
							:msg="cardItem.title"
							:categoryId="cardItem.id"
							:imgPath="cardItem.url || require('@/assets/img/img-3.png')"
						/>
					</template>
				</div>
			</div>
      <transition name="panel-slide">
        <div v-if="isMobileView && showPanel" class="panel_row_mobile">
          <template v-for="cardItem in panels">
            <CategoryCard
                :isMobileView="isMobileView"
                v-bind:key="cardItem.id"
                :toolLinkClick="toolLinkClick"
                :msg="cardItem.title"
                :categoryId="cardItem.id"
                :imgPath="cardItem.url_m  || cardItem.url || require('@/assets/img/img-3.png')"
            />
          </template>
        </div>
      </transition>
			<AdLeftPanel v-if="isLeftAd && !isMobileView" margin="20px 14px" />
		</div>

		<transition name="panel-slide">
			<template v-for="panel in panels">
				<ThumbsPanel
					:appliedPresetId="appliedPresetId"
					v-bind:key="panel.id"
					v-if="panelOpened === panel.title"
					:pannelInfo="pannelInfo"
					:panelName="panel.title"
					:panelItems="panel.panelItems"
					:panelPropChange="panelPropChange"
					@panelView="panelView"
					:popUpPropChange="popUpPropChange"
					:panelClass="panelClass"
					:panelLoading="panelLoading"
					:sliderDefaultValue="100"
					:favoritesPropChange="favoritesPropChange"
					:panelId="panel.id"
					:popUpVisible="popUpVisible"
					:filterPreview="filterPreview"
					:applyFilter="applyEffect"
					:sliderVal="effectConfig.valueOpacity"
					:sliderMax="100"
					:isValuesPanel="true"
					:isEffect="true"
					:sliderMin="0"
					:sliderUpdate="changeEffectOpacity"
					@loadSecondPageIfBigScreen="loadSecondPageIfBigScreen"
					@contentUpload="contentUpload"
					:isLoading="pageControl.isLoading"
				>
						<template v-slot:popup>
							<PopupEffects
								:popUpVisible="popUpVisible"
								:popUpLabel="popUpLabel"
								:popUpPropChange="popUpPropChange"
								@updateUniforms="updateUniforms"
								:params="popupSetting"
								@applyEffect="applyEffect"
								@resetSettings="resetPopupSettings"

								:eraseConfig="eraseData"
								@resetDrawing="resetDrawing(true, true)"
								@selectPointerEvents="selectPointerEvents"
								@changeConfig="changeConfig"
							/>
						</template>
				</ThumbsPanel>
			</template>
		</transition>

		<transition name="panel-slide">
			<ThumbsPanel
				:appliedPresetId="appliedPresetId"
				v-if="panelOpened === 'Favorites'"
				panelName="Favorites"
				:panelItems="favoritesList"
				:panelPropChange="panelPropChange"
				:popUpPropChange="popUpPropChange"
				:pannelInfo="pannelInfo"
				:panelClass="panelClass"
				:panelLoading="false"
				:sliderDefaultValue="100"
				:favoritesPropChange="favoritesPropChange"
				:filterPreview="filterPreview"
				:isEffect="true"
				:applyFilter="applyEffect"
				:isValuesPanel="true"
				:sliderVal="effectConfig.valueOpacity"
				:sliderMax="100"
				:sliderMin="0"
				:sliderUpdate="changeEffectOpacity"
			>
				<template v-slot:popup>
					<PopupEffects
						:popUpVisible="popUpVisible"
						:popUpLabel="popUpLabel"
						:popUpPropChange="popUpPropChange"
						@updateUniforms="updateUniforms"
						:params="popupSetting"
						@applyEffect="applyEffect"
						@resetSettings="resetPopupSettings"

						:eraseConfig="eraseData"
						@resetDrawing="resetDrawing(true, true)"
						@selectPointerEvents="selectPointerEvents"
						@changeConfig="changeConfig"
					/>
				</template>
			</ThumbsPanel>
		</transition>
	</div>
</template>

<script>
import storage from "@/libs/storage.js";
import CategoryCard from "@/components/CategoryCard.vue";
import ThumbsPanel from "@/components/ThumbsPanel.vue";
import PopupEffects from "@/components/popupPanel/PopupEffects.vue";
import Favorites from "@/components/Favorites.vue";
import Offline from "@/components/Offline.vue";
import EarseToolMixin from '../../../mixin/EarseToolMixin';
import AdLeftPanel from "@/components/AdLeftPanel.vue";
const analitics = require("../../../libs/analytics");
import LoaderMixin from '@/mixin/LoaderMixin';
import { EventBus } from '@/main.js';

export default {
	name: "EffectsPanel",
	props: {
		sidebarPanelClass: String,
	},

	mixins: [EarseToolMixin, LoaderMixin],
	components: {
		CategoryCard,
		ThumbsPanel,
		PopupEffects,
		Favorites,
		Offline,
		AdLeftPanel,
	},

	data: function() {
		const defaultOpacity = 100;
		return {
			isMobileView: false,
			showPanel: true,
			appliedPresetId: false,
			panelId: 0,
			selectedPanelId: false,
			categoryId: false,
			pageControl: {
				currentPage: 1,
				totalPages: 1,
				isLoading: false,
			},
			pannelInfo: {
				pannelTitle: "",
				pannelList: [
					{ icon: "slider_icon", text: "Adjust the effect intensity" },
					{ icon: "icon_heart", text: "Add selected effect to Favorites" },
					{
						icon: "icon_tune",
						text: "Access advanced settings to further fine-tune the effect",
					},
				],
				pannelFooter: `<span class="inch-icon inch-plus-membership-icon"></span>Indicates the effect is a Pro feature. <a href='#get_plus'>Get Pro</a>`,
			},

			effectConfig: {
				valueOpacity: defaultOpacity,
				defaultOpacity,
			},
			favoritesCount: 0,
			favoritesList: [],
			favoritesPanelVisible: false,
			popUpVisible: false,
			popUpLabel: "",
			panelLoading: true,
			panelOpened: "",
			panelClass: "",
			panels: [],
			categoryCardData: [],
			previewEffectData: {},
			popupSetting: {},
		};
	},

	async mounted() {
    this.isMobileView = this.$store.state.isMobileView;
		this.updateGlobalEarseConfig();
		this.selectPointerEvents(true);

		this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });

		this.favoritesList = this.$store.state.favorites.effects;

		this.panels = await fetch(
			`${this.$store.state.prefixURL}/api/xeffects`
		).then((response) => {
			return response.json();
		});

        if(!this.$store.state.effectServerURL){
            this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();
        }
	},

	beforeDestroy() {
		this.resetDrawing(false, true);

		this.discardChanges();
		this.$lib.Drawing.initDraw(false);
		this.$lib.watermark.remove();
		this.selectPointerEvents(false);
        this.$store.state.effectServerURL = null
	},

	methods: {
		async loadSecondPageIfBigScreen(){
			if(!this.pageControl.isLoading && this.pageControl.currentPage < this.pageControl.totalPages){
				this.pageControl.isLoading = true;
				this.pageControl.currentPage++;
				const items = await this.getEffectsByParams(this.pageControl.currentPage);
				this.panels[this.panelId].panelItems.push(...items);
				this.pageControl.isLoading = false;
			}
		},

		async contentUpload(event) {
      let scrolled = false;
      if(event){
        if (this.isMobileView) {
          scrolled = (event.target.scrollLeft*window.devicePixelRatio) >=
              ((event.target.scrollWidth - event.target.clientWidth));
        } else {
          scrolled = (event.target.scrollTop*window.devicePixelRatio) >=
              event.target.scrollHeight - event.target.clientHeight;
        }
      }

			if (
			scrolled &&
			!this.pageControl.isLoading &&
			this.pageControl.currentPage < this.pageControl.totalPages
			) {
				const panel = this.panels.find((p) => p.id == this.selectedPanelId);
				if (!panel) return;

				this.pageControl.isLoading = true;
				this.pageControl.currentPage++;
				const items = await this.getEffectsByParams(this.pageControl.currentPage);

				this.panels[this.panelId].panelItems.push(...items);

//				await this.checkAfterLoad(items);
				this.pageControl.isLoading = false;
			}
		},

		async getEffectsByParams(page=1){
			let response =  await fetch(
				`${this.$store.state.prefixURL}/api/xeffects/${this.selectedPanelId}?page=`+this.pageControl.currentPage
			).then((response) => {
				return response.json();
			});
			this.pageControl.totalPages = response.total_pages;

			let items = response.data.map(
				(item) => {
					item.imgPath = item.url || require("@/assets/img/img-3.png");
					item.preset = `${item.preset}`;
					return item;
				}
			);
			return items;

		},

		resetPopupSettings() {
			this.popupSetting = {
				hue: 0,
				saturation: 0,
				sharpen: 0,
			};

			this.$lib.effects.settingEffect({
				...this.popupSetting,
				alpha: this.effectConfig.valueOpacity,
			});
		},

		async discardChanges() {
			if (this.$store.state.premiumStatus) {
				this.$store.commit("changePremiumStatus", false);
			}

			this.$lib.watermark.remove(true);

			await this.$lib.effects.serverEffect.discardChanges();
			this.$store.dispatch("fabricSlide", false);
			this.$store.dispatch("isUpdateCache");
			this.isPreview = false;
		},

		preloaderProgressEffect(data) {
			this.$store.dispatch("preloader", {
				isShow: true,
				message: `Preparing ${parseInt(data.loading) > 100 ? 100 : parseInt(data.loading)}%`,
			});
		},

		getSelectedPreset(id) {
			let preset = [
				...this.panels.reduce((arr, panel) => {
					if (panel.panelItems) {
						arr = [...arr, ...panel.panelItems];
					}

					return arr;
				}, []),
			].find((item) => item.id == id);

			if (!preset) {
				preset = this.favoritesList.find(
					(itemFav) => itemFav.id == id
				);
			}

			return preset;
		},

		async filterPreview(data, isTick = true) {
			if (this.appliedPresetId == data.id) {
				this.appliedPresetId = null;
				this.discardChanges();

				return;
			}

			this.resetDrawing();
			const preset = this.getSelectedPreset(data.id);
			if (!this.panelOpened || !preset) return;
			this.resetPopupSettings();
			this.previewEffectData = data;
			this.effectConfig.valueOpacity = this.effectConfig.defaultOpacity;

			this.$store.dispatch("checkPremiumStatus", {
				panels: this.panels,
				favorites: this.favoritesList,
				presetId: data.id,
			});

			// this.$store.dispatch("preloader", { message: "Loading...", isShow: true });
			if (this.isPreview) {
				await this.discardChanges();
			}

			this.preloaderProgressEffect({ loading: 0, timePassed: 0 });

			this.$lib.socket.socketOn("effectAppliedStatus", (data) =>
				this.preloaderProgressEffect(data)
			);
			this.$store.dispatch("fabricSlide", true);
			const ticker = () => {
				const sprite = this.exportDraw();
				this.setMaskTexture(sprite, true, true);
			};
			this.$lib.watermark.renderPremium(preset.isPremium && !this.$store.state.isPremiumAccount, undefined, [false, true]);
			await this.$lib.effects.serverEffect._setServerEffect(
				{
					name: this.$lib.CONST.SERVER_EFFECTS[data.effect].name,
					preset: data.params.preset,
					uniforms: { alpha: this.effectConfig.valueOpacity, },
				}, {
					mapId: 999999,
					...(data.params ? data.params.params || {} : {}),
				}, ticker, this.updateMask);

			this.isPreview = true;
			this.$store.dispatch("isUpdateCache");
			this.$store.dispatch("preloader", { message: "", isShow: false });

			this.appliedPresetId = data.id;
			this.$store.state.drawEventVisible = true;
			if(isTick) { ticker(); }
		},

		setupEffect() {
			try {
				const container = this.$lib.app.stage.children[1];
				const filter = this.getFilterByContainer(container);
				if(!filter) return console.error('ERRR FILTER');

				container.children[1].children[1].visible = this.eraseData.showMask ? true : false;
				container.children[1].children[0].visible = this.eraseData.showMask ? false : true;
				filter.uniforms.invert = !!this.eraseData.invertSelection;

				if (this.maskTexture && this.maskTexture.visible) {
					this.updateMask(container, this.maskTexture.data, this.maskTexture.width, this.maskTexture.height);
				} else {
					this.updateMask(container, new Uint8Array(4).fill(0), 1, 1);
				}
				//memory leaking issue resolved
				if(this.$store.state.drawEventVisible) return;
				this.$lib.effects.settingEffect({
					...this.popupSetting,
					alpha: this.effectConfig.valueOpacity,
				}, this.eraseData.showMask);
				this.$lib.app.reRenderCanvas();
			} catch (err) {
				//console.error(err);
			}
		},

		favoritesPropChange(itemId, panelId, imgPath, label) {
			const favoriteIndex = this.favoritesList.findIndex(
				(f) => f.id == itemId && label == label
			);

			const panel = this.panels.find((panel) => panel.id == panelId);
			if (!panel) return;
			const preset = panel.panelItems.find((item) => item.id == itemId);
			if (!preset) return;

			if (favoriteIndex <= -1) {
				this.favoritesList.push({
					...preset,
					favoritesIconIsChecked: true,
					panelId,
				});
			} else {
				this.favoritesList.splice(favoriteIndex, 1);
			}

			// let favoritesCount = 0;
			this.$store.dispatch("favorites", {
				key: "effects",
				value: this.favoritesList,
			});

			storage.updateFavorites("effects", this.favoritesList);

			this.panels.map((panel) => {
				if (panel.id === panelId) {
					panel.panelItems.map((panelItem) => {
						if (panelItem.id === itemId) {
							panelItem.favoritesIconIsChecked = !panelItem.favoritesIconIsChecked;
						}
					});
				}
			});

			/* for (let index = 0; index < this.panels.length; index++) {
				favoritesCount += this.panels[index].panelItems.filter(
					el => el.favoritesIconIsChecked
				).length;
			}

			this.favoritesCount = favoritesCount; */
		},

		popUpPropChange(label, event) {
			if (
				this.popUpVisible !== false &&
				this.popUpLabel !== label &&
				label !== ""
			) {
				return (this.popUpLabel = label);
			} else {
				return (
					(this.popUpVisible = !this.popUpVisible), (this.popUpLabel = label)
				);
			}
		},

		updateUniforms({ uniform, value }) {
			this.popupSetting[uniform] = value;

			this.$lib.effects.settingEffect({
				...this.popupSetting,
				alpha: this.effectConfig.valueOpacity,
			}, this.eraseData.showMask);
		},

		async toolLinkClick(panelName, categoryId) {
			this.pageControl.currentPage = 1;
			this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);

			const panelIndex = this.panels.findIndex(
			  (panel) => panel.title == panelName
			);
			this.selectedPanelId = this.panels[panelIndex].id;

			this.categoryId = categoryId;
      // if(!this.$store.state.effectServerURL){
      //   this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();
      // }

			if (this.panelOpened === panelName) {
				this.panelOpened = "";
				this.panelClass = "";
				this.isLoading = true;
			} else {
				analitics.sendEvent(panelName, analitics.categ.frame);

				const panelId = this.panels.findIndex((p) => p.id == categoryId);
				if (panelId != -1) {
					this.panelOpened = panelName;
					this.panelClass = "active";
					let items = await this.getEffectsByParams(this.pageControl.currentPage);
					this.panelId = panelId;
					this.panels[panelId].panelItems = items;
					this.panelLoading = false;
				}
			}

			this.showPanel = false;
		},

		async changeEffectOpacity(opacity) {
			this.effectConfig.valueOpacity = opacity;
			this.$lib.effects.settingEffect({
				...this.popupSetting,
				alpha: this.effectConfig.valueOpacity,
			});

			// await this.$lib.effects.serverEffect.setOpacityToLastServerEffect(
			//	 opacity
			// );
		},

		isApplyByPremium() {
			let effect = [
				...this.panels.reduce((arr, panel) => {
					if (panel.panelItems) {
						arr = [...arr, ...panel.panelItems];
					}

					return arr;
				}, []),
			].find((pi) => pi.id == this.previewEffectData.id);

			if (!effect || this.isPremiumAccount) return true;
			if (effect.isPremium) {
				// if (window.abVariant && window.abVariant == 2) {
                    this.$store.dispatch("popupsToggle", {
                        property: "stripeCheckoutPopup",
                        value: true,
                    });
                // }
                // else{
                //     this.$store.dispatch("popupsToggle", {
                //         property: "upgradePopup",
                //         value: true,
                //     });
                // }
				return false;
			}

			return true;
		},

		async applyEffect(params, slider=false) {
			let updateId = -1;
			const isApply = this.isApplyByPremium();

			if (!isApply) return;

			await this._preloader(true);
			try {
				updateId = this.previewEffectData.params.mapId;
			} catch (err) {
				console.error(err);
			}

			await this.applyMixin();

			this.eraseData.showMask = false;
			this.setupEffect();
			await this.$lib.effects.serverEffect.addToCache(updateId);
			await this._preloader(false);

			if(slider) {
				let height =this.$lib.app.fabricCanvas.height;
				let nowHeight, zoom;

				nowHeight = height - 31;
				zoom = nowHeight/this.$lib.app.stage.height;
				$(".centered-content").css({'transform': `scale(${zoom})`});
				$(".drag-wrapper").css({'height': 'calc(100vh - 280px)'})
				this.$lib.app.setFabricZoom(zoom);
			}
			this.$store.dispatch("changesApplied");
			await this.discardChanges();

			this.panelOpened = "";
			this.panelClass = "";
			this.isPreview = false;
			this.showPanel = true;
            this.$store.state.effectServerURL = null
		},
		panelView(val) {
			this.showPanel = val;
		},

		async panelPropChange() {
			this.appliedPresetId = false;
			await this.discardChanges();
			//added for image not displaying on save image panel
			this.$lib.Drawing.initDraw(false);

			this.panelOpened = "";
			this.panelClass = "";
			this.panelLoading = true;
			this.popUpVisible = false;
		},

		favoritesOpen() {
			this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
			this.panelOpened = "Favorites";
		},
	},

	watch: {
		async _applySelectedEffect() {
			try {
        if (this.appliedPresetId) {
          await this.applyEffect();
				}
        if (this.$store.state.saveOnMobile) {
          EventBus.$emit("save-on-mobile");
        }

			} catch (err) {}
			this.$store.state._callbackSave += 1;
		},
	},

	computed: {
		isLeftAd() {
			return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
		},
		isPremiumAccount() {
			return this.$store.state.isPremiumAccount;
		},

		_applySelectedEffect() {
			return this.$store.state._applySelectedEffect;
		},
	},
};
</script>
<style scoped>
	.hidden {
		display: none;
	}
</style>
