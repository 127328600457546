<template>
	<div :class="`${adPosition}-ad-bg-area `" :style="calculatePositionStyle">
		<div :class="`${adPosition}-ad-area `">
			<div v-html="content" :style="backgroundColor"></div>
<!--			<div :class="`${adPosition}-ad `" v-html="content" :style="backgroundColor"></div>-->
			<div :class="`${adPosition}-align `">
				<a href="#" @click.prevent="showUpgradePopUp"  :class="`${adPosition}-remove-ad `">
					<span class="icon-style inch-plus-membership-icon"></span>
					Remove Ads
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AdPanel",
		components: {

		},
		props: {
			adPosition:  {
				type: String,
				required: true
			},
			content:  {
				type: String,
				required: true
			},
			marginLeft:  {
				type: String,
				default: '14px'
			},
		},
		data: function () {
			return {

			};
		},
		mounted(){

		},
		computed: {

			layersBar() {
				return this.$store.state.global.layersPanel;
			},
			rightPosition(){
				return this.adPosition === 'right' ?? false;
			},
			centerPosition(){
				return this.adPosition === 'center' ?? false;
			},
			leftPosition(){
				return this.adPosition === 'left' ?? false;
			},
			bottomPosition(){
				return this.adPosition === 'bottom' ?? false;
			},
			mobileHomeAdPosition(){
				return this.adPosition === 'bottom-mobile' ?? false;
			},
			calculatePositionStyle() {
				if(this.leftPosition){
					return {
						marginLeft: this.marginLeft
					};
				}
				if(this.rightPosition){
					return {
						right: this.layersBar ? '250px': 0,
						top: '-1px',
						zIndex: '1',
					};
				}
				if(this.bottomPosition){
					return {
						width: this.layersBar ? 'calc(100% - 250px)': '100%',
						left: '0',
						bottom: '0'
					};
				}
				return '';

			},
			backgroundColor() {
                /*Start: Remove this when production*/
                if(window.DEVELOPMENT_MODE) {
					if(this.leftPosition){
						return {
							width: '200px',
							height: '200px',
							background: '#847A7A'
						};
					}
					if(this.centerPosition){
						return {
							width: '300px',
							height: '250px',
							background: '#847A7A'
						};
					}
                    if(this.rightPosition){
						return {
                            width: window.innerWidth>=1600?'300px':'160px',
                            height: '600px',
                            background: '#847A7A'
                        };
                    }
                    if(this.bottomPosition){
                        return {
                            width: '728px',
                            height: '90px',
                            background: '#847A7A'
                        };
                    }
					if(this.mobileHomeAdPosition){
						return {
							width: '320px',
							height: '50px',
							background: '#847A7A'
						};
					}
                }
                /*End: Remove this when production*/
				if(this.content){
					return {
						background: 'transparent'
					};
				}
				return '';
			},
		},

		watch: {

		},

		created() {

		},

		methods: {
			showUpgradePopUp() {
				// if (window.abVariant && window.abVariant == 2) {
					this.$store.dispatch("popupsToggle", {
						property: "stripeCheckoutPopup",
						value: true,
					});
				// }
				// else{
				// 	this.$store.dispatch("popupsToggle", {
				// 		property: "upgradePopup",
				// 		value: true,
				// 	});
				// }
			},
		},
	};
</script>

<style scoped>
	.bottom-ad-bg-area {
		position: absolute;
		width: 100%;
		/*height: 14vh;*/
		background: #1D1D1D 0% 0% no-repeat padding-box;
		opacity: 1;
		transition: all 0.5s ease-in-out;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.bottom-ad-area {
		position: relative;
		/*width: 50%;
		height: 100%;*/
		margin: auto;
		border: 8px solid transparent;
		opacity: 1;
	}
	.bottom-ad-area .bottom-ad {
		/*width: 100%;
		height: 100%;*/
		background: #847A7A 0% 0% no-repeat padding-box;
	}
	.bottom-remove-ad {
		/*position: absolute;
		bottom: 0;
		margin: 0 0 -1px 0;
		right: 0;
		width: 100%;
		text-align: end;*/
		/* UI Properties */
		font: normal normal normal 10px/20px Proxima Nova;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;
		background: #1D1D1D 0% 0% no-repeat padding-box;
	}
	.bottom-remove-ad .icon-style {
		font-size: 8px;
	}
	.bottom-ad-area  .bottom-align {
		text-align: end;
	}

	.bottom-mobile-ad-bg-area {
		position: fixed;
		bottom: 0px;
		width: 100%;
		background: #1D1D1D 0% 0% no-repeat padding-box;
		opacity: 1;
		transition: all 0.5s ease-in-out;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.bottom-mobile-ad-area {
		position: relative;
		margin: auto;
		border: 8px solid transparent;
		opacity: 1;
	}
	.bottom-mobile-remove-ad {
		font: normal normal normal 10px/20px Proxima Nova;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;
		background: #1D1D1D 0% 0% no-repeat padding-box;
	}
	.bottom-mobile-remove-ad .icon-style {
		font-size: 8px;
	}
	.bottom-mobile-ad-area  .bottom-mobile-align {
		text-align: end;
	}

	.right-ad-bg-area {
		position: absolute;
		/*width: 8vw;*/
		height: 100%;
		background: #1D1D1D 0% 0% no-repeat padding-box;
		opacity: 1;
		transition: all 0.5s ease-in-out;


		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.right-ad-area {
		position: relative;
		/*width: 100%;
		height: 50%;*/
		margin: auto;
		border: 8px solid transparent;
		/*padding: 8px;*/
		/* UI Properties */
		/*background: #847A7A 0% 0% no-repeat padding-box;*/
		opacity: 1;
	}
	.right-ad-area .right-ad {
		/*width: 100%;
		height: 100%;*/
		background: #847A7A 0% 0% no-repeat padding-box;
	}
	.right-remove-ad {
		/*position: absolute;
		bottom: 0;
		margin: 0 0 -1px 0;
		right: 0;
		width: 100%;
		text-align: center;*/
		/* UI Properties */

		font: normal normal normal 10px/20px Proxima Nova;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;
		background: #1D1D1D 0% 0% no-repeat padding-box;
	}
	.right-remove-ad .icon-style {
		font-size: 8px;
	}
	.right-ad-area  .right-align {
		text-align: center;
	}

	.center-ad-bg-area {
		width: 316px;
		height: 298px;
		background: #1D1D1D 0% 0% no-repeat padding-box;
		padding: 24px 8px 24px 8px;
		border-radius: 4px;
	}
	.center-ad-area  .center-align {
		text-align: center;
	}
	.center-remove-ad {
		/*position: absolute;
		bottom: 0;
		margin: 0 0 -1px 0;
		right: 0;
		width: 100%;
		text-align: end;*/
		/* UI Properties */
		font: normal normal normal 10px/20px Proxima Nova;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;
		background: #1D1D1D 0% 0% no-repeat padding-box;
	}

	.left-ad-bg-area {
		margin-top: 20px;
		width: 222px;
		height:236px;
		opacity: 1;
		transition: all 0.5s ease-in-out;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.left-ad-area {
		position: relative;
		/*width: 100%;
		height: 50%;*/
		margin: auto;
		border: 8px solid transparent;
		/*padding: 8px;*/
		/* UI Properties */
		/*background: #847A7A 0% 0% no-repeat padding-box;*/
		opacity: 1;
	}
	.left-ad-area .left-ad {
		/*width: 100%;
		height: 100%;*/
		background: #847A7A 0% 0% no-repeat padding-box;
	}
	.left-ad-area  .left-align {
		text-align: center;
	}
	.left-remove-ad {
		/*position: absolute;
		bottom: 0;
		margin: 0 0 -1px 0;
		right: 0;
		width: 100%;
		text-align: center;*/
		/* UI Properties */

		font: normal normal normal 10px/20px Proxima Nova;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;
	}
	.left-remove-ad .icon-style {
		font-size: 8px;
	}

</style>
