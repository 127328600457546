<template>
  <div class="popup" v-if="popUpVisible" v-custom-drag>
    <div class="popup_header">
      <h3>{{ popUpLabel }}</h3>
      <a class="close_popup_btn" v-on:click="popUpPropChange(popUpLabel)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div id="tabs">
          <ul class="button-tabs-instrumets tabs-nav">
            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'adjust' }" @click="changeTab('adjust')" draggable="false">Adjust</a>
            </li>

            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'erase' }" @click="changeTab('erase')" draggable="false">Erase</a>
            </li>
          </ul>
      </div>
      <div
        class="popup_body_row"
        :style="{ 'max-height': '75vh', 'overflow-y': 'auto' }"
      >
        <div v-show="tab == 'adjust'">
          <template v-for="(param, indexFather) in paramValues">
            <div class="popup_body_row_item"
              v-for="(value, index) in param.values"
              v-bind:key="getIndex(index, value, param, indexFather)"
            >
              <Range
                v-if="value.type == 'range'"
                :rangeSliderId="value.val"
                :rangeSliderLabel="value.title"
                :rangeSliderMeasure="value.rangeSliderMeasure || ''"
                :minValue="pr(value.userRangeMin, 0)"
                rangeSliderClass="range-blue"
                :maxValue="pr(value.userRangeMax, 100)"
                :stepSlider="pr(value.userRangeStep, 1)"
                :rangeSliderValue="getRangeValue(value, param.uniform)"
                :defaultValue="
                  getRangeValue(value.val, param.uniform, undefined, true)
                "
                @changeValue="
                  updateUniforms($event, value.val, param.uniform, value)
                "
              />

              <ColorInput
                v-if="value.type == 'colorPicker'"
                @input="updateUniforms($event, value.val, param.uniform)"
                :defColor="getRangeValue(value, param.uniform, value.type)"
                :textLabel="value.title"
              />
            </div>
          </template>
        </div>

        <EraseTool v-show="tab == 'erase'"
          :tool="tool" :eraseConfig="eraseConfig" @resetDrawing="$emit('resetDrawing')"
          @changeConfig="changeConfig" @changeToolName="changeToolName"
        />

        <div class="popup_body_row_item">
          <div class="btns-group mt0">
            <a tabIndex="0" class="grey_btn" @click="resetParams">Cancel</a>
            <a tabIndex="0" class="blue_btn" @click="$emit('applyFilter')">Apply</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";
import EraseTool from '@/components/EraseTool.vue';
import MixinPopupBrush from '../../mixin/MixinPopupBrush';

export default {
  mixins: [MixinPopupBrush],
  components: { Range, EraseTool, ColorInput },
  name: "PopupFilters",
  props: {
    selectPointerEvents: Function,
    popUpVisible: Boolean,
    popUpPropChange: Function,
    updateColorInverterOpacity: Function,
    popUpLabel: String,
    paramValues: Array,
    currentFilter: Number,
    uniforms: Object,
    freezeSelectedParams: {
      default: () => ({}),
      type: Object,
    },
  },

  mounted() { },

  beforeDestroy() {
    this.lzDestroy();
  },
  computed: {
        zoom() {
            return this.$store.state.global.zoom;
        }
    },
  watch: {
    zoom(value){
      if (this.tab == 'erase') {
        this.changeTab('adjust')
        this.changeTab('erase')
      }
    },
    currentFilter() {
      this.resetPopupConfig();
    }
  },

  methods: {
    changeTab(tab) {
      this.tab = tab;
      if(tab == 'erase') {
        this.lzLock();
        this.updateCanvasSizeByZoom();
        this.changeConfig({ key: 'brushSize', value: this.eraseConfig.brushSize });
      } else {
        this.lzDestroy();
      }
    },

    getIndex(index, value, param, fatherIndex) {
      // console.log('INDEX ', index);
      // console.log('VALUE ', value);
      // console.log('PARAM ', param, '\n\n');

      return index + value.val + fatherIndex;
    },

    resetParams() {
      this.popUpPropChange(this.popUpLabel);
      this.$emit("resetFilterParams", this.currentFilter);
    },

    getRangeValue(value, uniformKey, valueType, isDefault = false) {
      let valueKey = value.val;

      const unKeyParse = `${uniformKey}`.replace("xxx", "_xxx_"); // uniformKey;
      try {
        let uniform = Object.entries(
          isDefault ? this.freezeSelectedParams : this.uniforms
        ).find((entry) => {
          return entry[0] == unKeyParse;
        });
        uniform = Object.entries(uniform[1]).find((entry) => {
          return entry[0] == valueKey;
        });

        let result = uniform
          ? typeof uniform[1] == "string" || valueType != "colorPicker"
            ? uniform[1]
            : this.$lib.lib.rgbToHexLowProc(uniform[1])
          : null;

        if(typeof result == 'number') {
          result = parseInt(_LIB.lib.convRange(result, value.min, value.max, this.pr(value.userRangeMin, 0), this.pr(value.userRangeMax, 100), true));
        }

        return result;
      } catch (err) {
        console.warn(err);
        return null;
      }
    },

    pr(val, def) {
      const valNum = Number(val);
      return (!valNum && valNum != 0 ? def : valNum);
    },

    updateUniforms(value, valueKey, uniformKey, q) {
      if (uniformKey.startsWith('ColorInverter')) {
        this.updateColorInverterOpacity(value);
      }
      // const unKeyParse = `${uniformKey}`.replace("xxx", "_xxx_"); // uniformKey;
      // console.log('PARAM SETUP: ', unKeyParse, valueKey, value);
      // console.log('this.uniforms: ', this.uniforms);
      // console.log('q: ', q);
      try {
        if (this.uniforms[unKeyParse][valueKey] !== value) {
          if(!q) {
            this.uniforms[unKeyParse][valueKey] = value;
          } else if (q.type == "range") {
            this.uniforms[unKeyParse][valueKey] = this.$lib.lib.convRange(value, this.pr(q.userRangeMin, 0), this.pr(q.userRangeMax, 100), q.min, q.max);
          } else {
            this.uniforms[unKeyParse][valueKey] = value;/*  this.$lib.lib.conv(
              value,
              this.uniforms[unKeyParse].min,
              this.uniforms[unKeyParse].max
            ); */
          }

          this.$emit("updateUniformsFilter", this.uniforms);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style>
.panelTitle {
  padding: 0 15px 15px 15px;
  text-align: center;
  color: #8c8c8c;
  line-height: 17px;
}
</style>

<style scoped src="@/assets/css/popupStyles.css"></style>
