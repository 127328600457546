<template>
	<div class="offline_block" >
		<div class="image_outer">
			<img src="../assets/images/warning.svg" alt />
		</div>
		<div class="text_block">
			<div class="no_internet_header">You're disconnected</div>
			<div class="no_internet_text">
				Check your internet connection and try again.
			</div>
		</div>

	</div>
</template>
<script>

export default {
    name: 'Offline',
}
</script>

<style>
	.offline_block{
		display: flex;
		flex-direction: column;
		color: white;
		text-align: center;
		font-size: 16px;
		margin: 0 auto;
		padding-left: 15px;
		padding-right: 15px;
		
	}
	.offline_block img{
		width: 18px;
		padding-bottom: 10px;
	}
	
	.offline_block .no_internet_header{
		text-align: center;
		letter-spacing: 0px;
		color: #D7DCDD;
		opacity: 1;	
		font-size: 16px;
		padding-bottom: 12px;
	}
	.offline_block .no_internet_text{
		letter-spacing: 0px;
		color: #727272;
		opacity: 1;
		font-size: 14px;
	}
	
	
</style>
