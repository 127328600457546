<template>
    <div>
        <div class="plus-feature-wrap" @click="openUpgradePop()" style="pointer-events: all;">
            <div class="plus-feature">
                <span class="inch-icon inch-plus-membership-icon"></span>
                <div class="title">Pro Feature</div>
                <p>
                    Upgrade to Pro to unlock this feature
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PremiumInfo",
    methods: {
        openUpgradePop() {
            this.$store.dispatch("popupsToggle", { property: "stripeCheckoutPopup", value: true });
        }
    }
}
</script>
