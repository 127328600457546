<template>
  <div class="popup save_popup" :class="{'mobileView_popup_reset': isMobileView}">
    <div class="popup_header" v-if="!isMobileView">
      <h3>{{title}}</h3>
      <a class="close_popup_btn" v-on:click="changePopup('resetPopup', false)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div v-if="isMobileView" :class="{'mobileView_popup_body_head': isMobileView}">
        <h3>{{title}}</h3>
      </div>
      <div class="popup_body_row_item reset_popup_content">
        <p>
          {{content}}
        </p>
      </div>
      <div class="popup_body_row_item rest-btns-row-mobile">
        <div class="btns-group mt0">
          <a tabIndex="0" class="grey_btn" :class="{'not_btn_mobile': isMobileView}" v-on:click="changePopup('resetPopup', false)">{{cancel}}</a>
          <a tabIndex="0" ref="focushere" :class="{'reset_btn_mobile': isMobileView}" class="blue_btn" v-on:click="changePopup('resetPopup', false, true)">{{reset}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import indexdb from '../libs/indexdb';
export default {
  name: "ResetPopup",
  props: {
    resetPopupText: String
  },
  mounted(){
    this.isMobileView = this.$store.state.isMobileView;
  	this.$refs.focushere.focus();
  },
  computed: {
    title() {
      return this.isMobileView ? 'Reset current project?' : 'RESET PROJECT?';
    },
    content() {
      return this.isMobileView ? 'Your current project will be reset and all unsaved changes will be lost.' :
          'Are you sure you would like to continue? Your current project will be reset and all unsaved changes will be lost.';
    },
    cancel() {
      return this.isMobileView ? 'Cancel' : 'No';
    },
    reset() {
      return this.isMobileView ? 'Reset' : 'Yes';
    }
  },
  methods: {
    async changePopup(property, value, push) {
      if (push) {
        this.$store.state.autosave = {
          loading: false,
          isHaveChanges: false,
          project: false,
          lastSaveId: false,
          qq: 1
        };

        await this.$store.dispatch('resetCanvas');
        this.$store.state.global.layersPanel = false;
        this.$router.push(this.$store.state.routerPrefixURL + "/");
        await indexdb.deleteAll('autosave');
        this.$store.state.premiumStatus = false;
        this.$store.state.effectServerURL = null
      }

      setTimeout(() => {
        this.$store.dispatch("popupsToggle", { property, value });
      }, 300);
    }
  },
  data: function() {
    return {
      isMobileView: false,
    };
  }
};
</script>

<style scoped>
  .popup.mobileView_popup_reset {
    margin: 0px 36px;
    color: #8C8C8C;
    background: #1D1D1D;
    width: 304px;
    height: 192px;
    border-radius: 6px;
  }
  .mobileView_popup_body_head {
    color: #FFFFFF;
  }
  .mobileView_popup_reset .popup_body {
    background: #1D1D1D;
    padding: 24px;
  }
  .mobileView_popup_body_head h3 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.72px;
    font-weight: 500;
  }
  .not_btn_mobile, .reset_btn_mobile {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 13px;
    width: 92px;
    border-radius: 6px;
    line-height: 10px;
  }
  .not_btn_mobile {
    border: 2px solid #70707024;
    background: none;
  }
  .mobileView_popup_reset .popup_body_row_item.reset_popup_content {
    padding-left: 0px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .mobileView_popup_reset .popup_body_row_item.rest-btns-row-mobile {
    padding-right: 0;
  }
  .mobileView_popup_reset .reset_popup_content p {
    font-size: 15px;
    line-height: 20px;
    color: #8C8C8C;
    width: 100%;
  }
  .mobileView_popup_reset .popup_body_row_item {
    margin-top: 36px;
    padding: 0;
  }

</style>
