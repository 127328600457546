<template>
  <div class="popup" v-if="popUpVisible" v-custom-drag>
    <div class="popup_header">
      <h3>Effect Settings</h3>
      <a class="close_popup_btn" v-on:click="popUpPropChange(popUpLabel)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>

    <div class="popup_body">
      <div id="tabs">
          <ul class="button-tabs-instrumets tabs-nav">
            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'adjust' }" @click="changeTab('adjust')" draggable="false">Adjust</a>
            </li>

            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'erase' }" @click="changeTab('erase')" draggable="false">Erase</a>
            </li>
          </ul>
      </div>
      <div
          class="popup_body_row"
          :style="{ 'max-height': '75vh', 'overflow-y': 'auto' }"
      >
        <div v-show="tab == 'adjust'">
          <div class="popup_body_row_item">
            <Range
              :rangeSliderValue="params.sharpen"
              rangeSliderLabel="Detail"
              rangeSliderClass="range-blue"
              :minValue="0"
              :maxValue="100"
              @changeValue="updateUniforms($event, 'sharpen')"
            />
          </div>
          <div class="popup_body_row_item">
            <Range
              :rangeSliderValue="parseInt($lib.lib.reverseConv(params.saturation, -1, 1))"
              rangeSliderLabel="Color Range"
              rangeSliderClass="range-blue"
              :minValue="0"
              :maxValue="100"
              :stepSlider="1"
              @changeValue="updateUniforms($event, 'saturation')"
            />
          </div>
          <div class="popup_body_row_item">
            <Range
              rangeSliderId="editOpacity"
              :rangeSliderValue="params.hue"
              rangeSliderLabel="Hue"
              rangeSliderMeasure="°"
              rangeSliderClass="range-blue"
              :minValue="0"
              :maxValue="180"
              @changeValue="updateUniforms($event, 'hue')"
            />
          </div>
        </div>

        <EraseTool v-show="tab == 'erase'"
          :tool="tool" :eraseConfig="eraseConfig" @resetDrawing="$emit('resetDrawing')"
          @changeConfig="changeConfig" @changeToolName="changeToolName"
        />

        <div class="popup_body_row_item">
          <div class="btns-group mt0">
            <a tabIndex="0" class="grey_btn" @click="resetParams()">Cancel</a>
            <a tabIndex="0" href="#" @click="applyEffect()" class="blue_btn">Apply</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Range from "@/components/Range.vue";
import MixinPopupBrush from '../../mixin/MixinPopupBrush';
import EraseTool from '../EraseTool.vue';

export default {
  name: "PopupEffects",
  props: {
    popUpVisible: Boolean,
    popUpPropChange: Function,
    popUpLabel: String,
    params: Object,
  },
  mixins: [MixinPopupBrush],
  components: { Range, EraseTool },

  beforeDestroy() {
    this.lzDestroy();
  },

  methods: {
    changeTab(tab) {
      this.tab = tab;
      if(tab == 'erase') {
        this.lzLock();
        this.updateCanvasSizeByZoom();
        
        this.changeConfig({ key: 'brushSize', value: this.eraseConfig.brushSize });
      } else {
        this.lzDestroy();
      }
    },
    
    updateUniforms(value, uniform) {
      this.$emit("updateUniforms", { value: (uniform == 'saturation' ? this.$lib.lib.conv(value, -1, 1) : value), uniform });
    },

    applyEffect() {
      this.$emit("applyEffect", true);
      this.popUpPropChange(this.popUpLabel);
    },

    resetParams() {
        this.$emit('resetSettings', true);
        this.popUpPropChange(this.popUpLabel);
    }
  },
};
</script>

<style scoped src="@/assets/css/popupStyles.css"></style>
