<template>
  <div class="popup layer_prop_popup" v-custom-drag>
    <div class="popup_header">
      <h3>layer properties</h3>
      <a class="close_popup_btn" v-on:click="popupLayerPropShow(false)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="popup_body_row">
        <div class="box_align">
          <a class="btn-action" @click="setAlign('top')" v-tooltip.top="'Align Top'">
            <span class="inch-align-top-icon"></span>
          </a>
          <a class="btn-action" @click="setAlign('middle')" v-tooltip.top="'Align Middle'">
            <span class="inch-align-vertical-center-icon"></span>
          </a>
          <a class="btn-action" @click="setAlign('bottom')" v-tooltip.top="'Align Bottom'">
            <span class="inch-align-bottom-icon"></span>
          </a>
          <a class="btn-action" @click="setAlign('left')" v-tooltip.top="'Align Left'">
            <span class="inch-align-left-icon"></span>
          </a>
          <a class="btn-action" @click="setAlign('center')" v-tooltip.top="'Align Center'">
            <span class="inch-align-horizontal-center-icon"></span>
          </a>
          <a class="btn-action" @click="setAlign('right')" v-tooltip.top="'Align Right'">
            <span class="inch-align-right-icon"></span>
          </a>
        </div>
        <div class="double_btns_block panel_row">
          <div class="double_btns_left">
            <label class="text_label has_mb">Order</label>
            <div class="btns_wrpr">
              <a
                @click="setOrder('backward')"
                class="btn-action-left btn-action btn-action-w-74"
                v-tooltip.top="'Move Backwards'"
              >
                <span class="inch-icon inch-move-backwords-icon">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </a>
              <a
                @click="setOrder('forward')"
                class="btn-action-right btn-action btn-action-w-74"
                v-tooltip.top="'Move Forwards'"
              >
                <span class="inch-icon inch-move-forwards-icon">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </a>
            </div>
          </div>
          <div class="double_btns_right">
            <label class="text_label has_mb">Flip</label>
            <div class="btns_wrpr">
              <a
                @click="setFlip('x')"
                class="btn-action-left btn-action btn-action-w-74"
                v-tooltip.top="'Flip Horizontal'"
              >
                <span class="inch-icon inch-flip-horizontal-icon"></span>
              </a>
              <a
                @click="setFlip('y')"
                class="btn-action-right btn-action btn-action-w-74"
                v-tooltip.top="'Flip Vertical'"
              >
                <span class="inch-icon inch-flip-verical-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="panel_row"
          :key="dispatchUpdateTrigger"
          v-if="this.$store.state.sidebarPanel.panelName != 'Text'"
        >
          <DropdownSelect
            textLabel="Blend Mode"
            :key="configLayerProperties.blendMode || dispatchUpdateTrigger"
            :current="getCurrentBlendMode()"
            :selectClass="blendDropdown"
            :selectOptions="layerProperties.blendModes"
            @toggleDropdown="openDropdown"
            @selectDropdown="selectDrop"
            @resetSelectClass="(blendDropdown = '')"
          />
        </div>
        <div class="panel_row">
          <Range
            rangeSliderId="editOpacity"
            :key="dispatchUpdateTrigger"
            :rangeSliderValue="configLayerProperties.opacity"
            rangeSliderLabel="Opacity"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :defaultValue="100"
            :changeValue="setOpacity"
            @dragEnd="setOpacity($event, true)"
          />
        </div>
        <div class="panel_row">
          <div class="btns-group">
            <a class="button btn-action duplicate" @click="duplicateLayer()">Duplicate</a>
            <a
              class="button btn-action duplicate red_text_btn"
              @click="deleteLayer()"
              v-on:click="popupLayerPropShow"
            >Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import Range from "@/components/Range.vue";

export default {
  name: "PopupLayerProp",
  props: {
    popupLayerPropShow: Function,
  },
  components: {
    DropdownSelect,
    Range,
  },
  data: function () {
    return {
      dispatchUpdateTrigger: false,
      activeLayer: 1,
      blendDropdown: "",
      layerProperties: {
        align: false,
        order: false,
        flip: false,
        blendModes: [],
      },

      configLayerProperties: {},
    };
  },
  methods: {
    getCurrentBlendMode() {
      try {
        return (
          this.layerProperties.blendModes.find(
            (opt) =>
              opt.toLowerCase() ==
              this.configLayerProperties.blendMode.toLowerCase()
          ) || this.layerProperties.blendModes[0]
        );
      } catch (err) {
        return this.layerProperties.blendModes[0];
      }
    },

    toggleMe(index) {
      let toggle = this.layers[index].hidden;
      this.layers[index].hidden = !toggle;
    },

    async duplicateLayer() {
      await this.$lib.fabric.dublicateSelectedObject();
      this.$store.dispatch("isUpdateCache");
    },

    deleteLayer() {
      this.$lib.fabric.deleteSelectedObject();
      this.$store.dispatch("isUpdateCache");
    },

    setAlign(value) {
      this.$lib.fabric.setPosition(value);
      this.layerProperties.align = value;
      this.$store.dispatch("isUpdateCache");
    },

    setOpacity(opacity, toCache) {
      this.$lib.fabric.setOpacity(opacity, toCache);

      this.configLayerProperties.opacity = opacity;
      this.dispatchConfigLayerProperties();
    },

    setOrder(value) {
      this.$lib.fabric.changeOrder(value);
      this.layerProperties.order = value;
      this.$store.dispatch("isUpdateCache");
    },

    setFlip(value) {
      this.$lib.fabric.flipSelectedObject(value);
      this.layerProperties.flip = value;
      this.$store.dispatch("isUpdateCache");
    },

    getAlign(value) {
      return value === this.layerProperties.align ? "active" : "";
    },

    getOrder(value) {
      return value === this.layerProperties.order ? "active" : "";
    },

    getFlip(value) {
      return value === this.layerProperties.flip ? "active" : "";
    },

    openDropdown() {
      this.blendDropdown = "active";
    },

    selectDrop(blendMode) {
      this.$lib.fabric.setBlendMode(
        blendMode,
        this.configLayerProperties.intensity,
        this.configLayerProperties.color,
        false,
        undefined,
        true
      );

      this.configLayerProperties.blendMode = blendMode;
      this.dispatchConfigLayerProperties();

      this.blendDropdown = "";
    },

    dispatchConfigLayerProperties() {
      this.$store.dispatch("globalLayerPopUpParams", {
        ...this.$store.state.globalLayerPopUp.params,
        ...this.configLayerProperties,
      });

      this.$store.dispatch("isUpdateCache");
    },

    setLayerProperties(configLayerProperties) {
      for (const key in configLayerProperties) {
        if (this.configLayerProperties[key] != configLayerProperties[key]) {
          this.configLayerProperties = configLayerProperties;

          this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
          break;
        }
      }
    },
  },
  created() {
    this.layerProperties.blendModes = this.$store.state.globalLayerPopUp.blendModes;

    this.setLayerProperties({
      ...this.$store.state.globalLayerPopUp.default,
      ...this.$store.state.globalLayerPopUp.params,
    });
  },
  computed: {
    getGlobalLayerParams() {
      return {
        ...this.$store.state.globalLayerPopUp.default,
        ...this.$store.state.globalLayerPopUp.params,
      };
    },
  },

  watch: {
    getGlobalLayerParams(configLayerProperties) {
      this.setLayerProperties(configLayerProperties);
    },
  },
};
</script>