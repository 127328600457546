<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
            <div class="back">
                <a href="#" class="back-icon">
                    <span class="inch-icon inch-back-icon"></span>
                </a>

                <h3>Image Upscale</h3>
            </div>

            <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
                <img :src="helpIcon" alt/>
            </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
                <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <div class="text_input input_width_height input-size-t" v-html="sizeResult"></div>
                    </div>
                </div>

            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    Upscale Image
                </a>
            </div>

            <PanelAccordionAi accordionTitle="Advanced" :accordActiveClass="false" accordAdditionalClass="with-border" accordBodyClass="pb-8">
                <template v-slot:panel_accordion_body>
                    <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Prompt</label>
                        <input type="text" class="text_input input_width_height" v-model="payload.prompt"/>
                    </div>
                </div>

                <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Negative Prompt</label>
                        <input type="text"  class="text_input input_width_height" v-model="payload.negative_prompt"/>
                    </div>
                </div>
                
                <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Scale Factor</label>
                        <div class="btns_wrpr">
                            <div class="input_width_height_wrpr">
                                <InputWidthHeight
                                    :isChangeInputValueIncrement="true"
                                    :inputValue="payload.scale_factor"
                                    :maxValue="5" :minValue="1"
                                    @change="changeRange('scale_factor')($event.value)"
                                    inputName="scale_factor"
                                />
                            </div>
                        </div>
                        <!-- <input type="number" min="1" max="5" class="text_input input_width_height" v-model="payload.scale_factor"/> -->
                    </div>
                </div>

                <div class="panel_row">
                    <Range
                        rangeSliderId="dynamic"
                        :rangeSliderValue="payload.dynamic"
                        rangeSliderLabel="Dynamic"
                        rangeSliderClass="range-blue"
                        :minValue="1" :stepSlider="1" :maxValue="50"
                        @dragEnd="changeRange('dynamic')($event)"
                        :changeValue="changeRange('dynamic')"
                    />
                    <span class="custom-label-text">HDR, try from 3 - 9</span>
                </div>
                
                <div class="panel_row">
                    <Range
                        rangeSliderId="creativity"
                        :rangeSliderValue="payload.creativity"
                        rangeSliderLabel="Creativity"
                        rangeSliderClass="range-blue"
                        :minValue="0.1" :stepSlider="0.05" :maxValue="1"
                        @dragEnd="changeRange('creativity')($event)"
                        :changeValue="changeRange('creativity')"
                    />
                    <span class="custom-label-text">Creativity, try from 0.3 - 0.9</span>
                </div>
                
                <div class="panel_row">
                    <Range
                        rangeSliderId="resemblance"
                        :rangeSliderValue="payload.resemblance"
                        rangeSliderLabel="Resemblance"
                        rangeSliderClass="range-blue"
                        :minValue="0.1" :stepSlider="0.05" :maxValue="3"
                        @dragEnd="changeRange('resemblance')($event)"
                        :changeValue="changeRange('resemblance')"
                    />
                    <span class="custom-label-text">Resemblance, try from 0.3 - 1.6</span>
                </div>

                <div class="d-flex between two-column panel_row">
                    <div class="half">
                        <DropdownSelect
                            textLabel="Tiling Width" key="tiling_width"
                            :current="payload.tiling_width"
                            :selectOptions="tiling_widths"
                            :selectClass="dropdowns['tiling_width']"
                            @toggleDropdown="changeDropdown('tiling_width')"
                            @selectDropdown="changeRange('tiling_width')($event)"
                            @resetSelectClass="changeDropdown('tiling_width', false)"
                            :skipHeight="false"
                        />
                    </div>

                    <div class="half">
                        <DropdownSelect
                            textLabel="Tiling Height" key="tiling_height"
                            :current="payload.tiling_height"
                            :selectOptions="tiling_heights"
                            :selectClass="dropdowns['tiling_height']"
                            @toggleDropdown="changeDropdown('tiling_height')"
                            @selectDropdown="changeRange('tiling_height')($event)"
                            @resetSelectClass="changeDropdown('tiling_height', false)"
                            :skipHeight="false"
                        />
                    </div>
                </div>

                <div class="panel_row">
                    <DropdownSelect
                        textLabel="SD Model" key="sd_model"
                        :current="payload.sd_model"
                        :selectOptions="sdModels"
                        :selectClass="dropdowns['sd_model']"
                        @toggleDropdown="changeDropdown('sd_model')"
                        @selectDropdown="changeRange('sd_model')($event)"
                        @resetSelectClass="changeDropdown('sd_model', false)"
                        :skipHeight="true"
                    />
                </div>
                
                <div class="panel_row">
                    <DropdownSelect
                        textLabel="Scheduler" key="scheduler"
                        :current="payload.scheduler"
                        :selectOptions="schedulers"
                        :selectClass="dropdowns['scheduler']"
                        @toggleDropdown="changeDropdown('scheduler')"
                        @selectDropdown="changeRange('scheduler')($event)"
                        @resetSelectClass="changeDropdown('scheduler', false)"
                        :skipHeight="false"
                    />
                </div>

                <div class="panel_row">
                    <Range
                        rangeSliderId="num_inference_steps"
                        :rangeSliderValue="payload.num_inference_steps"
                        rangeSliderLabel="Num Inference Steps"
                        rangeSliderClass="range-blue"
                        :minValue="1" :stepSlider="1" :maxValue="100"
                        @dragEnd="changeRange('num_inference_steps')($event)"
                        :changeValue="changeRange('num_inference_steps')"
                    />
                    <span class="custom-label-text">Number of denoising steps</span>
                </div>

                <div class="panel_row margin_bottom_15">
                    <ToggleCheck
                        toggleCheckLabel="Downscaling"
                        :thisToggleCheckChecked="payload.downscaling"
                        @toggleCheckHandle="checkToggle('downscaling', $event)"
                    />

                    <span class="custom-label-text">Downscale the image before upscaling. Can improve quality and speed for images with high resolution but lower quality</span>
                </div>

                <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Downscaling Resolution</label>
                        <div class="btns_wrpr">
                            <div class="input_width_height_wrpr">
                                <InputWidthHeight
                                    :isChangeInputValueIncrement="true"
                                    :inputValue="payload.downscaling_resolution"
                                    :maxValue="2048" :minValue="1"
                                    @change="changeRange('downscaling_resolution')($event.value)"
                                    inputName="downscaling_resolution"
                                />
                            </div>
                        </div>

                        <!-- <input type="number" min="1" class="text_input input_width_height" v-model="payload.downscaling_resolution"/> -->
                    </div>
                </div>

                <!-- <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Lora Links</label>
                        <input type="text" class="text_input input_width_height" v-model="payload.lora_links"/>
                    </div>

                    <span class="custom-label-text">Links can be separated by commas.</span>
                </div> -->

                <!-- <div class="panel_row">
                    <div class="input_width_height_wrpr">
                        <label class="text_label has_mb">Custom SD Model</label>
                        <input type="text" class="text_input input_width_height" v-model="payload.custom_sd_model"/>
                    </div>
                </div> -->

                <div class="panel_row">
                    <Range
                        rangeSliderId="sharpen"
                        :rangeSliderValue="payload.sharpen"
                        rangeSliderLabel="Sharpen"
                        rangeSliderClass="range-blue"
                        :minValue="0" :stepSlider="1" :maxValue="10"
                        @dragEnd="changeRange('sharpen')($event)"
                        :changeValue="changeRange('sharpen')"
                    />
                    <span class="custom-label-text">Sharpen the image after upscaling.</span>
                </div>

                <div class="panel_row">
                    <DropdownSelect
                        textLabel="Handfix" key="handfix"
                        :current="payload.handfix"
                        :selectOptions="handfixes"
                        :selectClass="dropdowns['handfix']"
                        @toggleDropdown="changeDropdown('handfix')"
                        @selectDropdown="changeRange('handfix')($event)"
                        @resetSelectClass="changeDropdown('handfix', false)"
                        :skipHeight="true"
                    />

                    <span class="custom-label-text">Use clarity to fix hands in the image</span>
                </div>

                <div class="panel_row margin_bottom_15">
                    <ToggleCheck
                        toggleCheckLabel="Pattern"
                        :thisToggleCheckChecked="payload.pattern"
                        @toggleCheckHandle="checkToggle('pattern', $event)"
                    />

                    <span class="custom-label-text">Upscale a pattern with seamless tiling</span>
                </div>

                <div class="panel_row">
                    <DropdownSelect
                        textLabel="Output Format" key="output_format"
                        :current="payload.output_format"
                        :selectOptions="output_formats"
                        :selectClass="dropdowns['output_format']"
                        @toggleDropdown="changeDropdown('output_format')"
                        @selectDropdown="changeRange('output_format')($event)"
                        @resetSelectClass="changeDropdown('output_format', false)"
                        :skipHeight="true"
                    />

                    <span class="custom-label-text">Format of the output images</span>
                </div>

                <!-- <div class="panel_row margin_bottom_15">
                    <ToggleCheck
                        toggleCheckLabel="Background Enhance"
                        :thisToggleCheckChecked="payload.background_enhance"
                        @toggleCheckHandle="checkToggle('backgroundEnhance', $event)"
                    />
                </div>
                
                <div class="panel_row margin_bottom_15">
                    <ToggleCheck
                        toggleCheckLabel="Face Upsample"
                        :thisToggleCheckChecked="payload.face_upsample"
                        @toggleCheckHandle="checkToggle('faceUpsample', $event)"
                    />
                </div> -->
                </template>
            </PanelAccordionAi>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Increase the resolution of images while maintaining quality and clarity.']"
            filename="Image_Upscale"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {
            "seed": 1337,
            "prompt": "masterpiece, best quality, highres, <lora:more_details:0.5> <lora:SDXLrender_v2.0:1>",
            "dynamic": 6,
            "handfix": "disabled",
            "pattern": false,
            "sharpen": 0,
            "sd_model": "juggernaut_reborn.safetensors [338b85bc4f]",
            "scheduler": "DPM++ 3M SDE Karras",
            "creativity": 0.1,
            "lora_links": "",
            "downscaling": false,
            "resemblance": 0.6,
            "scale_factor": 2,
            "tiling_width": "112",
            "output_format": "png",
            "tiling_height": "144",
            "custom_sd_model": "",
            "negative_prompt": "(worst quality, low quality, normal quality:2) JuggernautNegative-neg",
            "num_inference_steps": 18,
            "downscaling_resolution": 768
        },

        sdModels: ['epicrealism_naturalSinRC1VAE.safetensors [84d76a0328]', 'juggernaut_reborn.safetensors [338b85bc4f]', 'flat2DAnimerge_v45Sharp.safetensors'],
        schedulers: ['DPM++ 2M Karras', 'DPM++ SDE Karras', 'DPM++ 2M SDE Exponential', 'DPM++ 2M SDE Karras', 'Euler a', 'Euler', 'LMS', 'Heun', 'DPM2', 'DPM2 a', 'DPM++ 2S a', 'DPM++ 2M', 'DPM++ SDE', 'DPM++ 2M SDE', 'DPM++ 2M SDE Heun', 'DPM++ 2M SDE Heun Karras', 'DPM++ 2M SDE Heun Exponential', 'DPM++ 3M SDE', 'DPM++ 3M SDE Karras', 'DPM++ 3M SDE Exponential', 'DPM fast', 'DPM adaptive', 'LMS Karras', 'DPM2 Karras', 'DPM2 a Karras', 'DPM++ 2S a Karras', 'Restart', 'DDIM', 'PLMS', 'UniPC'],
        handfixes: ['disabled', 'hands_only', 'image_and_hands'],
        output_formats: ['webp', 'jpg', 'png'],
        tiling_widths: [16, 32, 48, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240, 256].map(q => String(q)),
        tiling_heights: [16, 32, 48, 64, 80, 96, 112, 128, 144, 160, 176, 192, 208, 224, 240, 256].map(q => String(q)),
        dropdowns: {
            tiling_width: '',
            tiling_height: '',
            sd_model: '',
            scheduler: '',
            handfix: '',
            output_format: ''
        }
      };
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.ImageUpscalerAPI;
        },

        sizeResult() {
            const originalSize = this.$lib.app.originalSize;
            const w = originalSize.w * this.payload.scale_factor;
            const h = originalSize.h * this.payload.scale_factor;

            return `${originalSize.w} x ${originalSize.h} <svg style="margin: 0 3px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_242_4125)">
            <path d="M2.8125 9H15.1875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.125 3.9375L15.1875 9L10.125 14.0625" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_242_4125">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
            </svg> ${w} x ${h}`
            //return `${originalSize.w} x ${originalSize.h} → ${w} x ${h}`;
        }
    },
  
    methods: {
        async generatePayload(image) {
            return { ...this.payload, image, seed: 0 };
        },
    }
  };
</script>

<style scoped>
    .input-size-t {
        color: #EEEEEE;
        text-align: center;
        pointer-events: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-size-t svg {
        margin: 0 3px;
    }
</style>
