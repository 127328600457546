export default new class {
	constructor() {
		this.inited = false;
		this.clientId = '190067089373-br4c15fhvgu2gfkv68sfto70gie567v8.apps.googleusercontent.com';//"829065923570-jerpvjd0elvksc78qrk7raas7fkp89dd.apps.googleusercontent.com";
		this.developerKey = 'AIzaSyDz5CSzch2XuUWuMsE-N1J_ULFp4yQAtnE'; //'AIzaSyCA4YSzuSgObBlqhpt5TDeTyvPHfq9vBIU';
		this.appId = "stable-healer-338220";
		this.oauthToken = false;
		this.pickerApiLoaded = false;
		this.scope = ['https://www.googleapis.com/auth/drive.file'];
	}

	async init() {
		await new Promise((res) => { gapi.load('auth', function () { res(true); }) });
		await new Promise((res) => {
			gapi.auth.authorize({ 'client_id': this.clientId, 'scope': this.scope, 'immediate': false },
				(authResult) => {
					if (authResult && !authResult.error) {
						this.oauthToken = authResult.access_token;
					}
					res(true);
				}
			);
		})
		await new Promise((res) => { gapi.load("client", function () { res(true) }); })
		await new Promise((res) => {
			gapi.load('picker', function () { res(true); });
		})
		await gapi.client.load("https://content.googleapis.com/discovery/v1/apis/drive/v3/rest");
		this.inited = true;
	}

	async getFolders(parent = 'root') {
		return new Promise((res) => {
			gapi.client.drive.files.list({
				q: `mimeType='application/vnd.google-apps.folder' and trashed=false and '${parent}' in parents`,
				spaces: 'drive',
			}).execute(function (data) {
				if (data.files) {
					res(data.files);
				} else {
					res(false);
				}
			})
		})
	}

	async getFile() {
		return new Promise((res) => {
			try {
				var pickerCallback = async (data) => {
					if (data.action == google.picker.Action.CANCEL) {
						res(false);
						return false;
					}

					if (data.action == google.picker.Action.PICKED) {
						var fileId = data.docs[0].id;
						let file = await gapi.client.drive.files.get({ "fileId": fileId });
						if (!file.result) {
							res(false);
						}
						var accessToken = gapi.auth.getToken().access_token;
						var file_name = data.docs[0].name;
						var downloadUrl = "https://www.googleapis.com/drive/v3/files/" + fileId + "?alt=media"
						let blob = await this.getBlob(downloadUrl, accessToken);
						res(blob);
						return false;
					}
					return false;
				}

				if (this.oauthToken) {
					console.log("this.oauthToken", this.oauthToken);
					var view = new google.picker.View(google.picker.ViewId.DOCS);
					view.setMimeTypes("image/jpeg");
					var picker = new google.picker.PickerBuilder()
						.enableFeature(google.picker.Feature.NAV_HIDDEN)
						.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
						.setAppId(this.appId)
						.setOAuthToken(this.oauthToken)
						.addView(view)
						.addView(new google.picker.DocsUploadView())
						.setDeveloperKey(this.developerKey)
						.setCallback(pickerCallback)
						.build();
					picker.setVisible(true);
				}

				return false;
			} catch (e) {
				console.error(e);
				res(false)
			}
		});
	}

	async getBlob(url, accessToken) {
		let response = await fetch(url, {
			mode: "cors",
			cache: "no-cache",
			redirect: "follow",
			headers: new Headers({
				'Authorization': 'Bearer ' + accessToken,
			}),
		});

		let data = await response.blob();
		return data;
	}

	async saveFile(blob, name = "unnamed.jpg") {
		return new Promise(async (res) => {
			try {
				const folder = await new Promise(resolve => {
					const pickerCallback = async (data) => {
						if (data.action == google.picker.Action.CANCEL) {
							return resolve(false);
						}

						if (data.action == google.picker.Action.PICKED) {
							resolve(data.docs[0].id);
							return;
						}

						return false;
					};

					const docsView = new google.picker.DocsView()
						.setIncludeFolders(true)
						.setMimeTypes('application/vnd.google-apps.folder')
						.setSelectFolderEnabled(true);

					const picker = new google.picker.PickerBuilder()
						.setAppId(this.appId)
						.setTitle('Select a Folder')
						.setOAuthToken(this.oauthToken)
						.setDeveloperKey(this.developerKey)
						.addView(docsView)
						.setCallback(pickerCallback)
						.build();

					picker.setVisible(true);
				});

				if (!folder) return false;

				var metadata = {
					name: name,
					mimeType: blob.type,
					parents: [folder],
				};
				/* if (parentId) {
					metadata['parents'] = [parentId];
				} */

				var accessToken = gapi.auth.getToken().access_token;
				var form = new FormData();
				form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
				form.append('file', blob);
				await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", {
					method: 'POST',
					headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
					body: form,
				});
				res(true);
			} catch (e) {
				console.error(e);
				res(false)
			}
		})
	}
}


