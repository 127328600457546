<template>
    <div>
        <div class="pop_up_closer" v-if="getPopupStatus" @click="popUpPropChange('popupsToggleStatus', false)"></div>
        <Tips v-if="getPopupStatus">
            <template v-slot:body>
                <div class="tooltip_row_item">
                    <p class="simple_light_p">
                        <VideoPreviewTooltip :filename="filename"/>
                    </p>
                    <br/>

                    <p class="simple_light_p" v-for="(item, i) of list" :key="i" v-html="item"></p>
                    <p class="simple_light_p tool-hr" v-if="!isPremium || !allowAiTools">
                        <svg width="20" style="vertical-align: sub;" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.3125 4.53125H14.6875L17.0312 8.4375L10.3906 15.8594C10.3397 15.9113 10.2789 15.9526 10.2119 15.9808C10.1448 16.009 10.0728 16.0235 10 16.0235C9.92724 16.0235 9.85522 16.009 9.78815 15.9808C9.72107 15.9526 9.66029 15.9113 9.60938 15.8594L2.96875 8.4375L5.3125 4.53125Z" stroke="#4ADABA" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.4375 10L6.875 8.28125L7.34375 7.5" stroke="#4ADABA" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        Indicates the tool is a Total AI feature.<br/>
                        <a class="btn_textual" @click="showAIPopup" style="text-transform: inherit;">
                            Get Total AI
                        </a>
                    </p>
                </div>
            </template>
        </Tips>
    </div>
</template>

<script>
import VideoPreviewTooltip from '../../VideoPreviewTooltip.vue';
import Tips from "@/components/Tips.vue";

export default {
    props: {
        list: {
            type: Array,
            default: () => ([])
        },

        filename: {
            type: String,
            default: () => ''
        }
    },

    components: { VideoPreviewTooltip, Tips },
    computed: {
        getPopupStatus() {
            return this.$store.state.global.popupStatus.value;
        },

        allowAiTools() {
            return this.$store.state.allowAiTools;
        },

        isPremium() {
            return this.$store.state.isPremiumAccount;
        },
    },

    methods: {
        showAIPopup() {
            this.$store.dispatch("popupsToggle", { property: 'stripeCheckoutPopup', value: true });
        },

        popUpPropChange(property, value) {
            this.$store.dispatch("popupsToggleStatus", { property, value });
        }
    }
}
</script>

<style scoped>
    .tool-hr {
        border-top: 1px solid #303030;
        margin-top: 10px;
        padding-top: 10px;
    }
</style>