<template>
  <div class="toggle_check_wrpr" :class="toggleCheckClass">
    <label class="text_label" v-if="toggleCheckLabel">{{ toggleCheckLabel }}</label>
    <slot name="popup_slot" v-if="thisToggleCheckChecked"></slot>
    <label class="toggle_check_btn">
      <input type="checkbox" v-bind:checked="thisToggleCheckChecked" @change="changeToggle" />
      <div class="toggle_check_view">
        <span class="toggle_check_circle"></span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "ToggleCheck",
  props: {
    toggleCheckClass: String,
    toggleCheckLabel: String,
    toggleCheckHandle: Function,
    thisToggleCheckChecked: Boolean,
    onChangeChecked: Function
  },
  data: function() {
    return {
      toggleCheckChecked: ""
    };
  },

  methods: {
    changeToggle(e) {
      this.$emit("toggleCheckHandle", e);

      if(this.onChangeChecked) {
        this.onChangeChecked(e.target.checked);
      }
    }
  }
};
</script> 