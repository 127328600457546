<template>
  <div class="popup save_popup" v-if="savePopupShow" :class="{'portret_image':portretImage}">
    <div class="popup_header">
      <h3>{{ titlePopup }}</h3>
      <a
        class="close_popup_btn"
        v-on:click="changePopup('saveToComputer', false)"
      >
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="save_project_img">
        <img :src="projectPreview" alt id="project_preview_img" @load="previewImageLoad" />
      </div>
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <label class="text_label has_mb">Filename</label>
          <div class="input_text_wrpr">
            <input
              type="text"
              name
              id
              class="text_input"
              autofocus
              v-model="textInputValue"
              v-on:input="checkInputChange"
            />
            <span
              class="input_empty_error"
              v-bind:class="{ active: inputEmptyError }"
              >Oops... Filename missing</span
            >
          </div>
        </div>
        <div class="popup_body_row_item">
          <div class="save_img_block">
            <tabs :onSelect="changeFileFormat" :defaultIndex="defaultIndex">
              <tab title="JPG">
                <RangeLib
                  :rangeSliderValue="imageQuality"
                  key="saveImageJpgQuality"
                  rangeSliderId="saveImageJpgQuality"
                  rangeSliderLabel="Quality"
                  rangeSliderClass="white_dot quality_range"
                  :minValue="0"
                  :sliderBoxClass="sliderBoxClass"
                  :maxValue="100"
                  @dragend="updateQuality"
                  @update="renderQuality"
                />
                <div class="left_right_simple_text">
                  <span class="left_text">File Size</span>
                  <span class="right_text">{{ fileSize }}</span>
                </div>
              </tab>
              <tab title="PNG">
                <RangeLib
                  :rangeSliderValue="100"
                  key="saveImagePngQuality"
                  rangeSliderId="saveImagePngQuality"
                  rangeSliderLabel="Quality"
                  rangeSliderClass="white_dot quality_range"
                  sliderBoxClass="disabled"
                  :minValue="0"
                  :maxValue="100"
                  @update="updateQuality"
                />
                <div class="left_right_simple_text">
                  <span class="left_text">File Size</span>
                  <span class="right_text">{{ fileSize }}</span>
                </div>
              </tab>
              <tab title-slot="pdf_title">
                <section>
                  <RangeLib
                    :rangeSliderValue="imageQuality"
                    key="saveImagePdfQuality"
                    rangeSliderId="saveImagePdfQuality"
                    rangeSliderLabel="Quality (For Print)"
                    rangeSliderClass="white_dot quality_range"
                    :minValue="0"
                    :sliderBoxClass="blockSave ? 'disabled' : sliderBoxClass"
                    :interval="1"
                    :maxValue="100"
                    @dragend="updateQuality"
                    @update="renderQuality"
                  />
                </section>
                <div class="left_right_simple_text">
                  <span class="left_text">{{ calculateDPI }} DPI</span>
                  <!-- <span class="right_text" v-if="!blockSave">{{ fileSize }}</span> -->
                </div>
              </tab>
              <template slot="pdf_title">
                <div class="pdf_tab">
                  PDF
                  <span
                    v-if="!isPremiumAccount"
                    class="inch-icon inch-plus-membership-icon"
                  ></span>
                </div>
              </template>
            </tabs>
          </div>
        </div>
        <div class="popup_body_row_item">
          <div class="btns-group mt0">
            <a
              v-on:click="changePopup('saveToComputer', false)"
              class="grey_btn"
              >Cancel</a
            >
            <a class="blue_btn" v-on:click="startSaveFile(textInputValue)"
              >save</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab } from "vue-slim-tabs";
import RangeLib from "@/components/RangeLib.vue";

export default {
  components: {
    Tabs,
    Tab,
    RangeLib,
  },
  name: "SaveToComputer",
  props: {
    savingThisProject: Function,
    saveProjectImg: String,
  },

  data: function () {
    return {
  	  defaultIndex: this.$store.state.saveConfig.type,
      blobResult: null,
      fileSize: "",
      projectPreview: "",
      saveTo: "jpeg",
      textInputValue: "",
      inputEmptyError: false,
      savePopupShow: true,
      imageQuality: 80,
      sliderBoxClass: "",
      blockSave: false,
      portretImage: false
    };
  },

  computed: {
    saveConfigPresets: {
      get() { return this.$store.state.saveConfigPresets; },
      set(val) { this.$store.state.saveConfigPresets = val; },
    },

    titlePopup() {
      if(this.isGoogleDrive) return  'Save on google drive';
      return 'Save Image';
    },

    isGoogleDrive() {
      if(this.saveToComputer && typeof this.saveToComputer == 'object') {
        return this.saveToComputer.type == 'googleDrive';
      }

      return false;
    },

    saveToComputer() {
      return this.$store.state.popups.saveToComputer;
    },

    calculateDPI() {
      return Math.round(this.$lib.lib.conv(this.imageQuality, 72, 300));
    },

    isPremiumAccount() {
      return this.$store.state.isPremiumAccount;
    },

    getSliderBoxClass() {
      return this.sliderBoxClass;
    },

    isShowSaveAd() {
      return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.save_ad?.is_save_ad) ?? false;
    },

  },

  methods: {  	
    previewImageLoad(e){
      if(e.target && e.target.clientWidth < e.target.clientHeight){
        this.portretImage = true;
      } else {
        this.portretImage = false;
      }
    },
    renderQuality(imageQuality) {
      this.imageQuality = imageQuality;
    },

    async updateQuality(imageQuality) {
      this.imageQuality = imageQuality;
      this.saveConfigPresets = {
        ...this.saveConfigPresets,
        [this.saveTo]: {
          ...this.saveConfigPresets[this.saveTo],
          quality: imageQuality
        }
      };

      this.sliderBoxClass = "disabled";
      
      //this.$store.state.saveConfig.quality = imageQuality;
      await this.renderAndSetSize();
    },

    async changeFileFormat(ev, it, lockRender = false) {
      this.blockSave = false;
      switch (it) {
        case 0:
          this.saveTo = "jpeg";
          this.imageQuality = this.saveConfigPresets.jpeg.quality;
          break;
        case 1:
          this.saveTo = "png";
          this.imageQuality = this.saveConfigPresets.png.quality;
          break;
        case 2: {
          if (!(await this.$store.dispatch("isPremiumCurrentUser"))) {
            // if (window.abVariant && window.abVariant == 2) {
              this.$store.dispatch("popupsToggle", {
                property: "stripeCheckoutPopup",
                value: true,
              });
            // }
            // else{
            //   this.$store.dispatch("popupsToggle", {
            //     property: "upgradePopup",
            //     value: true,
            //   });
            // }
            this.blockSave = true;
            return;
          } else {
            this.imageQuality = this.saveConfigPresets.pdf.quality;
            this.saveTo = "pdf";
          }

          break;
        }
      }
      
      this.$store.state.saveConfig.type = it;
      if(!lockRender) await this.renderAndSetSize();
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    async startSaveFile(file_name) {
      if (
        this.blockSave &&
        !(await this.$store.dispatch("isPremiumCurrentUser"))
      ) {
        // if (window.abVariant && window.abVariant == 2) {
          this.$store.dispatch("popupsToggle", {
            property: "stripeCheckoutPopup",
            value: true,
          });
        // }
        // else{
        //   this.$store.dispatch("popupsToggle", {
        //     property: "upgradePopup",
        //     value: true,
        //   });
        // }
        return;
      }

      if (file_name.length > 0) {
        if (this.saveTo == "pdf") {
          this.blobResult = await this.$lib.app.getExportImage(
            this.saveTo,
            this.imageQuality
          );
        }

        if(this.isGoogleDrive) {
          this.$store.state.preloader = { message: 'Saving...', isShow: true };
          try {
            await googleDrive.init();
            const result = await googleDrive.saveFile(this.blobResult, `${file_name}.${this.saveTo}`);

            if(!result) {
              this.$store.state.preloader = { message: 'Something went wrong', isShow: true };
            }  else {
              this.changePopup('saveToComputer', false);
              this.$store.state.preloader = { message: 'Saved!', isShow: true };
            }
          } catch (err) {
            this.$store.state.preloader = { message: 'Something went wrong', isShow: true };
            console.error(err);
          }

          setTimeout(() => {
            this.$store.state.preloader = { message: '', isShow: false };
          }, 2000);
          return;
        }
        
        let is_iframe = (window.location != window.parent.location);
        try{
        	var ev_data = {cartoon_event: "cartoonize_onImageSave", blob: this.blobResult, name: file_name, saveTo: this.saveTo}
			var location_url = (window.location != window.parent.location) ? document.referrer: document.location;
			if(window.parent){
				window.parent.postMessage(ev_data, location_url);
			}
        }catch(e){
        	console.error(e);
        }
        
        if(!is_iframe){
		    this.$lib.app.downloadImageByBlob(
		      this.blobResult,
		      file_name,
		      this.saveTo
		    );
        }
        if(this.isShowSaveAd) this.$store.state.popups.afterSaveAd = true;

        this.$store.state.popups.saveToComputer = false;
        $(".menu-closer").click();
      } else {
        this.inputEmptyError = true;
        let input = document.querySelector("[autofocus]");
        input.focus();
      }
    },

    async renderAndSetSize() {
      //if (this.saveTo != "pdf") {
        this.blobResult = await this.$lib.app.getExportImage(
          this.saveTo == 'pdf' ? 'png' : this.saveTo,
          this.saveTo == 'pdf' ? 1 : this.imageQuality
        );

        this.fileSize = this.minifyFileSize(this.blobResult.size);
        this.projectPreview = URL.createObjectURL(this.blobResult);
      //}

      this.sliderBoxClass = "";
    },

    minifyFileSize(size) {
      let suffix = "B";
      let tSize = size;

      if (tSize > 1000) {
        tSize = parseInt(size / 1000);
        suffix = "KB";
      }

      if (tSize > 1024) {
        suffix = "MB";
        tSize = (size / 1000 / 1024).toFixed(1);
      }

      return `${tSize} ${suffix}`;
    },

    checkInputEnter(value) {
      if (value.length) {
        this.savingThisProject(
          "Saving artwork...",
          "Artwork downloaded",
          1500,
          "without_bg"
        );
        this.savePopupShow = false;
        setTimeout(() => {
          this.changePopup("saveToComputer", false);
          this.savePopupShow = true;
        }, 3000);
      } else {
        this.inputEmptyError = true;

        let input = document.querySelector("[autofocus]");
        input.focus();
      }
    },
    checkInputChange() {
      if (this.textInputValue.length) {
        this.inputEmptyError = false;
      }
      
      this.$store.state.saveConfig.filename = this.textInputValue;
    },
  },

  async mounted() {
  	this.$nextTick(async () => {
      await this.changeFileFormat(false, this.$store.state.saveConfig.type, true);
      this.textInputValue = this.$store.state.saveConfig.filename;

      await this.renderAndSetSize();
      let input = document.querySelector("[autofocus]");
      if (input) {
        input.focus();
      }
    });
  },
};
</script>

<!-- optionally use our default style -->
<style src="vue-slim-tabs/themes/default.css"></style>
