<template>
  <div class="right_sidebar_wrpr">
    <aside class="right_sidebar">
      <div class="tool-item-panel">
        <div class="panel-top layers_panel_header">
          <div class="back">
            <h3>Layers</h3>
          </div>
          <div class="help-box" @click.stop="popUpPropChange()">
            <img src="@/assets/img/help-icon.svg" alt />
          </div>
        </div>
        <div v-bar>
          <div class="panel-content wo_pad">
            <PanelAccordion
              accordionTitle="layer properties"
              :accordActiveClass="true"
              accordAdditionalClass="with-border"
              accordBodyClass="pb-8 layer-panel"
            >
              <template v-slot:panel_accordion_body>
                <div :class="{ hide_panel: !isActiveObject }">
                  <div class="box_align pt_8">
                    <a class="btn-action" @click="setAlign('top')" v-tooltip.top="'Align Top'">
                      <span class="inch-align-top-icon"></span>
                    </a>
                    <a class="btn-action" @click="setAlign('middle')" v-tooltip.top="'Align Middle'">
                      <span class="inch-align-vertical-center-icon"></span>
                    </a>
                    <a class="btn-action" @click="setAlign('bottom')" v-tooltip.top="'Align Bottom'">
                      <span class="inch-align-bottom-icon"></span>
                    </a>
                    <a class="btn-action" @click="setAlign('left')" v-tooltip.top="'Align Left'">
                      <span class="inch-align-left-icon"></span>
                    </a>
                    <a class="btn-action" @click="setAlign('center')" v-tooltip.top="'Align Center'">
                      <span class="inch-align-horizontal-center-icon"></span>
                    </a>
                    <a class="btn-action" @click="setAlign('right')" v-tooltip.top="'Align Right'">
                      <span class="inch-align-right-icon"></span>
                    </a>
                  </div>
                  <div class="double_btns_block panel_row">
                    <div class="double_btns_left">
                      <label class="text_label has_mb">Order</label>
                      <div class="btns_wrpr">
                        <a
                          @click="setOrder('backward')"
                          class="btn-action-left btn-action btn-action-w-74"
                          v-tooltip.top="'Move Backwards'"
                        >
                          <span class="inch-icon inch-move-backwords-icon">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </span>
                        </a>
                        <a
                          @click="setOrder('forward')"
                          class="btn-action-right btn-action btn-action-w-74"
                          v-tooltip.top="'Move Forwards'"
                        >
                          <span class="inch-icon inch-move-forwards-icon">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div class="double_btns_right">
                      <label class="text_label has_mb">Flip</label>
                      <div class="btns_wrpr">
                        <a
                          @click="setFlip('x')"
                          class="btn-action-left btn-action btn-action-w-74"
                          v-tooltip.top="'Flip Horizontal'"
                        >
                          <span class="inch-icon inch-flip-horizontal-icon"></span>
                        </a>
                        <a
                          @click="setFlip('y')"
                          class="btn-action-right btn-action btn-action-w-74"
                          v-tooltip.top="'Flip Vertical'"
                        >
                          <span class="inch-icon inch-flip-verical-icon"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="panel_row">
                    <DropdownSelect
                      textLabel="Blend Mode"
                      :key="configLayerProperties.blendMode || dispatchUpdateTrigger"
                      :current="getCurrentBlendMode()"
                      :selectClass="blendDropdown"
                      :selectOptions="layerProperties.blendModes"
                      @toggleDropdown="openDropdown"
                      @selectDropdown="selectDrop"
                      @resetSelectClass="(blendDropdown = '')"
                    />
                  </div>
                  <div class="panel_row">
                    <Range
                      rangeSliderId="editOpacity"
                      :key="dispatchUpdateTrigger"
                      :rangeSliderValue="configLayerProperties.opacity"
                      rangeSliderLabel="Opacity"
                      rangeSliderMeasure="%"
                      rangeSliderClass="range-blue"
                      :minValue="0"
                      :maxValue="100"
                      :changeValue="setOpacity"
                      @dragEnd="setOpacity($event, true)"
                    />
                  </div>
                  <div class="panel_row mb_10">
                    <div class="btns-group">
                      <a
                        @click="duplicateLayer(activeLayer)"
                        class="button btn-action-all btn-action duplicate"
                      >Duplicate</a>

                      <a
                        @click="deleteLayer(activeLayer)"
                        class="button btn-action-all  btn-action duplicate red_text_btn"
                      >Delete</a>
                    </div>
                  </div>
                </div>
              </template>
            </PanelAccordion>
            <PanelAccordion
              accordionTitle="layers"
              :accordActiveClass="true"
              accordAdditionalClass
              accordBodyClass="pb-8 layer-panel"
              :key="getUpdateCache"
            >
              <template v-slot:panel_accordion_body>
                <LayerList
                  lockAxis="y"
                  :useDragHandle="true"
                  v-model="layers"
                  helperClass="drag-item"
                  @sort-start="sortStarted"
                  @sort-end="sortEnded"
                  :listClass="listClass"
                >
                  <LayerItem
                    v-for="(layer, index) in layers"
                    :index="index"
                    :key="layer.id"
                    :layerId="layer._id"
                    :title="layer.title"
                    :imgIcon="layer.icon"
                    :imgPath="layer.image"
                    :hidden="layer.hidden"
                    :active="layer.active"
                    :isText="layer.isText"
                    :textTitle="layer.textTitle || ''"
                    @toggleHidden="toggleHidden(layer)"
                    @toggleActive="toggleActiveLayer(layer._id, $event)"
                    @titleChange="editChangeTitle($event, layer._id)"
                  />
                </LayerList>
              </template>
            </PanelAccordion>
          </div>
        </div>
      </div>
    </aside>
    <div class="pop_up_closer" v-if="getPopupStatus" @click="popUpPropChange()"></div>
    <Tips
      v-if="getPopupStatus"
      :tipsHide="popUpPropChange"
      addClass="position-right"
      :text="tipsText"
    >
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p">Use layers to organize your project.</p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Double-click layer to rename it
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Drag & drop layer to rearrange order
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            Use
            <i>Layer Properties</i> to control the layer's
            <br />overall opacity, alignment and order.
          </p>
          <br />
          <p class="simple_light_p">
            For more artistic results, try experimenting
            <br />with layer blend modes.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import LayerList from "@/components/LayerList.vue";
import LayerItem from "@/components/LayerItem.vue";
import Tips from "@/components/Tips.vue";
import PanelAccordion from "@/components/PanelAccordion.vue";
import DropdownSelect from "@/components/DropdownSelect.vue";
import Range from "@/components/Range.vue";

export default {
  name: "RightSidebar",
  components: {
    PanelAccordion,
    DropdownSelect,
    Range,
    LayerList,
    LayerItem,
    Tips,
  },
  data: function () {
    return {
      keyGlobalLayerPopUp: false,
      tipsText:
        "Layers are like stacked sheets. When a new element is added to a project (e.g. a graphic or text) a new layer is created.",
      getPopupStatus: false,
      activeLayer: 1,
      layers: [],
      listClass: "default",
      uniq: 0,
      d: 1,
      isDidabledList: false,
      blendDropdown: "",
      layerProperties: {
        align: false,
        order: false,
        opacity: 100,
        blendModes: [],
      },
      configLayerProperties: {},
      dispatchUpdateTrigger: false,
      isActiveObject: false,
    };
  },
  methods: {
    getCurrentBlendMode() {
      try {
        return (
          this.layerProperties.blendModes.find(
            (opt) =>
              opt.toLowerCase() ==
              this.configLayerProperties.blendMode.toLowerCase()
          ) || this.layerProperties.blendModes[0]
        );
      } catch (err) {
        return this.layerProperties.blendModes[0];
      }
    },

    setAlign(value) {
      this.$lib.fabric.setPosition(value);
      this.layerProperties.align = value;
      this.$store.dispatch("isUpdateCache");
    },

    openDropdown() {
      this.blendDropdown = "active";
    },

    dispatchConfigLayerProperties() {
      this.$store.dispatch("globalLayerPopUpParams", {
        ...this.$store.state.globalLayerPopUp.params,
        ...this.configLayerProperties,
      });

      this.$store.dispatch("isUpdateCache");
    },

    setOpacity(opacity, toCache) {
      this.$lib.fabric.setOpacity(opacity, toCache);
      this.configLayerProperties.opacity = opacity;

      this.dispatchConfigLayerProperties();
    },

    setOrder(value) {
      this.$lib.fabric.changeOrder(value);
      this.layerProperties.order = value;
      this.$store.dispatch("isUpdateCache");
    },

    setFlip(value) {
      // Intensity

      this.$lib.fabric.flipSelectedObject(value);
      this.layerProperties.flip = value;
      this.$store.dispatch("isUpdateCache");
    },

    setBlendMode(value) {
      this.layerProperties.blendMode = value;
    },

    async duplicateLayer(/* id */) {
      await this.$lib.fabric.dublicateSelectedObject();
      this.$store.dispatch("isUpdateCache");
      /* let target = JSON.parse(
        JSON.stringify(this.layers.find(layer => layer.id === id))
      );
      target.id = this.layers.length + 1;
      this.layers.push(target); */
    },

    deleteLayer(/* id */) {
      this.$lib.fabric.deleteSelectedObject();
      this.$store.dispatch("isUpdateCache");
      /*  let target = this.layers.find(layer => layer.id === id);
      this.layers.splice(this.layers.indexOf(target), 1); */
    },

    selectDrop(blendMode) {
      if (this.configLayerProperties.blendMode != blendMode) {
        this.$lib.fabric.setBlendMode(
          blendMode,
          this.configLayerProperties.intensity,
          this.configLayerProperties.color,
          false,
          undefined,
          true
        );

        this.configLayerProperties.blendMode = blendMode;
        this.dispatchConfigLayerProperties();
      }

      this.blendDropdown = "";
    },

    popUpPropChange() {
      this.getPopupStatus = !this.getPopupStatus;
    },

    async toggleHidden(layerItem) {
      /* const layerIndex = this.layers.findIndex(layer => layer._id == layerItem._id);
      if (layerIndex <= -1) return;
      this.layers[layerIndex].hidden = !this.layers[layerIndex].hidden; */

      await this.$lib._cache.toggleHideChange(
        layerItem.cacheKeys,
        !!layerItem.hidden
      );

      this.isDidabledList = true;
      //await this.$lib._cache.renderSaveByTimestamp(Date.now());

      this.$store.dispatch("isUpdateCache");
      this.isDidabledList = false;
    },

    async toggleActiveLayer(_id) {
      let selectedIndex;

      this.layers.forEach((layer, index) => {
        if (layer._id == _id) {
          selectedIndex = index;
        } else {
          this.layers[index].active = false;
        }
      });

      if (selectedIndex <= -1) {
        return;
      }

      this.isDidabledList = true;

      const toggleActive = await this.$lib._cache.selectLayerById(_id);

      this.layers[selectedIndex].active = true; // !this.layers[selectedIndex].active;
      this.isDidabledList = false;
    },

    editChangeTitle(data) {
      this.$lib._cache.changeNameInHistory(data._id, data.name);
      this.$store.dispatch("isUpdateCache");
    },
    sortStarted(e) {
      console.log({ sortStarted: e });
      this.listClass = "sorting";
    },

    sortEnded(e) {
      console.log(e);
      if (e.oldIndex != e.newIndex) {
        const isUp = e.oldIndex > e.newIndex;

        const old = this.layers[e.oldIndex];
        // const newInd = this.layers[e.newIndex].id;
        const res = this.$lib._cache.moveInHistory(
          old.cacheKeys,
          e.newIndex,
          isUp
        );
        console.log("RES", res);
        this.$store.dispatch("isUpdateCache");
      }

      this.listClass = "default";
    },

    setupBlendModeDispatch(blendMode) {
      if (blendMode.mode) {
        this.layerProperties.blendMode =
          this.layerProperties.selectOptions.find((opt) => {
            return opt.toLowerCase() == blendMode.mode.toLowerCase();
          }) || this.layerProperties.blendMode;
      }
    },

    setupLayerPopupParams(params) {
      if (params.opacity) {
        this.layerProperties.opacity = parseInt(params.opacity * 100);

        this.keyGlobalLayerPopUp = !this.keyGlobalLayerPopUp;
      }
    },

    selectChangedLayer() {
      const selectedLayers = this.$lib.app.fabricCanvas.getActiveObjects();
      this.layers = this.layers.map((layer) => {
        if (
          layer.cacheKeys &&
          layer.cacheKeys[0] &&
          selectedLayers.find(
            (selectedLayer) => layer.cacheKeys[0] == selectedLayer.cacheKey
          )
        ) {
          layer.active = true;
        } else {
          layer.active = false;
        }

        return layer;
      });
    },

    setLayerProperties(configLayerProperties) {
      this.isActiveObject = !!this.$lib.app.fabricCanvas.getActiveObject();
      for (const key in configLayerProperties) {
        if (this.configLayerProperties[key] != configLayerProperties[key]) {
          this.configLayerProperties = configLayerProperties;

          this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
          break;
        }
      }
    },
  },

  computed: {
    getUpdateCache() {
      return this.$store.state.isUpdatedCache;
    },

    onFabricBodyDown() {
      return this.$store.state.incrBodyDownFabric;
    },

    getGlobalLayerParams() {
      return {
        ...this.$store.state.globalLayerPopUp.default,
        ...this.$store.state.globalLayerPopUp.params,
      };
    },
  },

  watch: {
    onFabricBodyDown() {
      this.selectChangedLayer();
    },

    getUpdateCache() {
      this.layers = this.$lib._cache.getLayersHistoryChanges();
    },

    getGlobalLayerParams(configLayerProperties) {
      this.setLayerProperties(configLayerProperties);
    },
  },

  mounted() {
    this.layers = this.$lib._cache.getLayersHistoryChanges();

    this.layerProperties.blendModes = this.$store.state.globalLayerPopUp.blendModes;
    this.setLayerProperties({
      ...this.$store.state.globalLayerPopUp.default,
      ...this.$store.state.globalLayerPopUp.params,
    });
  },
};
</script>

<style scoped>
.hide_panel {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.right_sidebar_wrpr .tool-item-panel {
  height: calc(100vh - 94px) !important;
}

.right_sidebar_wrpr {
  background: #1d1d1d;
}
</style>
