<template>
    <div class="panel_accordion sc" v-bind:class="[{ active: accordActive }, accordAdditionalClass]">
        <div class="panel_accordion_header" v-on:click="accordHandle">
            <h3 class="accordion_title">{{ !accordActive ? 'Advanced' : 'Basic' }}</h3>
            <span class="accordion_arrow" style="padding: 5px">
                <span class="inch-down-arrow-icon"></span>
            </span>
        </div>
            
        <div class="panel_accordion_body" :class="accordBodyClass">
            <slot name="panel_accordion_body"></slot>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'PanelAccordionAi',
    props: {
        accordionTitle: String,
        accordActiveClass: Boolean,
        accordAdditionalClass: String,
        accordBodyClass: String
    },
    data: function() {
        return {
            accordActive: this.accordActiveClass || false
        }
    },
    methods: {
        accordHandle: function() {
            this.accordActive = !this.accordActive;
        }
    }
}
</script>
<style scoped>
    .panel_accordion.sc {
        margin-top: 4px;
        border-bottom: none;
    }

    .sc .accordion_title {
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.05px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-transform: none;
        color: #fff !important;
    }

    .sc .panel_accordion_header .inch-down-arrow-icon:before {
        color: #fff !important;
    }

    .sc .panel_accordion_header {
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }

    .sc .panel_accordion_header::before,
    .sc .panel_accordion_header::after {
        content: "";
        flex-grow: 1;
        height: 1px;
        background: #3F3F3F;
        margin: 0 10px;
    }
</style>