<template>
    <div class="popup" v-custom-drag>
        <div class="popup_header">
            <h3>OUTLINE</h3>
            <a class="close_popup_btn" v-on:click="closePopups">
                <span class="inch-icon inch-close-icon"></span>
            </a>
        </div>
        <div class="popup_body">
            <div class="popup_body_row">
                <div class="popup_body_row_item">
                    <ColorIput
                        textLabel="Color"
                        :defColor="textOutlineParams.color"
                        :changeColorValue="changeColor"
                    />
                </div>
                <div class="popup_body_row_item">
                    <Range
                    rangeSliderId="editOpacity"
                    :rangeSliderValue = "textOutlineParams.width"
                    :changeValue="changeWidthValue"
                    rangeSliderLabel="Width"
                    rangeSliderMeasure="%"
                    @dragEnd="onChangeWidth"
                    rangeSliderClass="range-blue" 
                    :minValue = 0
                    :defaultValue="20"
                    :maxValue = 100 />
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import ColorIput from "@/components/ColorInput";
    import Range from '@/components/Range.vue';

    export default {
        name: 'PopupTextOutline',
        components: {
            Range,
            ColorIput
        },
        props: {
            popUpVisible: Boolean,
            popUpPropChange: Function,
            popUpLabel: String,
            closePopups: Function,
            textOutlineParams: Object,
            changeTextOutlineParams: Function
        },
        methods: {
            changeColor(color) {
                this.textOutlineParams.color = color.hex;
                this.changeTextOutlineParams(this.textOutlineParams, true);
            },

            changeWidthValue(width) {
                width = Number(width);
                if(!width) return;
                this.textOutlineParams.width = width;
                this.changeTextOutlineParams(this.textOutlineParams, true);
            },

            onChangeWidth(width) {
                this.changeWidthValue(width);

                this.$emit('changeParams', this.textOutlineParams);
            }
        }
    }

</script>