<template>
    <div>
        <div class="shortcuts-block" :class="{ 'open':open }" style="position:fixed; left:25px; bottom:25px;">
            <div class="shortcuts-block__head">
                Shortcuts
                <div class="shortcuts-block__head-btn" @click="closePopup()">
                <svg xmlns="http://www.w3.org/2000/svg" draggable="false" width="8.001" height="8" viewBox="0 0 8.001 8">
                    <g transform="translate(-154 -546.001)">
                    <rect class="a" width="10.335" height="0.979" rx="0.489" transform="translate(161.309 554.001) rotate(-135)"></rect>
                    <rect class="a" width="10.335" height="0.979" rx="0.489" transform="translate(154 553.309) rotate(-45)"></rect>
                    </g>
                </svg>
                </div>
            </div>

            <div class="shortcuts-block__body">
                <ul class="shortcuts-block__body-list">
                    <li v-for="(short, index) in shortcuts" :key="index">
                        {{ short.title }}
                        <div class="key-combo">
                        <span v-for="(key, i) in short.keys" :key="i" :class="key.class">{{ key.val }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="settings-overlay" :style="{ background: `rgba(0,0,0,.5)` }" @click="closePopup"></div>
  </div>
</template>

<script>
export default {
  name: "Shortcuts",
  
  data: function() {
    return {
      open: true,
      shortcuts: [{
        title: 'Copy',
        keys: [
          { class: 'long', val: 'Ctrl' },
          { class: 'small', val: 'c' }
        ]
      }, {
        title: 'Paste',
        keys: [
          { class: 'long', val: 'Ctrl' },
          { class: 'small', val: 'v' }
        ]
      }, {
        title: 'Drag Element',
        keys: [
          {  class: 'small', val: '←'  },
          {  class: 'small', val: '↑'  },
          {  class: 'small', val: '→'  },
          {  class: 'small', val: '↓'  }
        ]
      }, {
        title: 'Fast Drag Element',
        keys: [
          {  class: 'long', val: 'Shift+'  },
          {  class: 'small', val: '←'  },
          {  class: 'small', val: '↑'  },
          {  class: 'small', val: '→'  },
          {  class: 'small', val: '↓'  }
        ]
      }, {
        title: 'Zoom In/Out',
        keys: [
          {  class: 'long', val: 'Ctrl'  },
          {  class: 'small', val: ' + \\ - '  }
        ]
      }, {
        title: 'Zoom w/Mouse',
        keys: [
          // {  class: 'long', val: 'Ctrl'  },
          {  class: 'long', val: 'Scroll'  },
        ]
      }, {
        title: 'Undo',
        keys: [
          {  class: 'long', val: 'Ctrl'  },
          {  class: 'small', val: 'z'  },
        ]
      }, {
        title: 'Redo',
        keys: [
          {  class: 'long', val: 'Ctrl'  },
          {  class: 'long', val: 'Shift'  },
          {  class: 'small', val: 'z'  },
        ]
      }, {
        title: 'Move Canvas',
        keys: [
          {  class: 'long', val: 'Space'  },
          {  class: 'long', val: 'LMB'  },
        ]
      }]
    }
  },

  methods: {
    closePopup() {
      this.open = false;
      setTimeout(() => {
        this.$store.state.showShortcuts = false;
      }, 200);
      setTimeout(() => {
        this.$store.dispatch('sidebarPanelToggle', { panelSettings: 'show' });
        this.open = true;
      }, 210);
    }
  }
};
</script>

<style scoped>
  .shortcuts-block__head-btn img{
    opacity: .5;
    transition: all 0.15s ease-in;
  }

  .shortcuts-block__head-btn:hover img{
      opacity: 1;    
  }

	@keyframes slidein {
		from {
      opacity: 0;
      transform: translateY(50px);
		}
		to {
      opacity: 1;
      transform: translateY(0px);
		}
	}
	@keyframes slideout {
		from {
      opacity: 1;
      transform: translateY(0px);
		}
		to {
      opacity: 0;
      transform: translateY(50px);
		}
	}

  .shortcuts-block.open {
      animation: slidein .2s ease-in forwards;
      pointer-events: all;  
  }

  .shortcuts-block {
    width: 260px;
    background-color: #2A2A2A;
    border-radius: 4px;
    box-shadow: 1px 1px 2px #1D1D1DB3;
    transition: all .3s ease-in;
    opacity: 0;
    transform: translateY(50px);
    animation: slideout .2s ease-in forwards; 
    pointer-events: none;
  }
  .shortcuts-block__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 18px;
      color: #D7DCDD;
      font-size: 16px;
      border-bottom: 2px solid #303030;
  }

  .ai {
      text-transform: uppercase;
      color: #4adaba;
      font-size: 8px;
      margin: 0 0 0 3px;
  }
  .shortcuts-block__head-btn {
      padding:20px 16px;
      cursor: pointer;
      font-size: 12px;
      line-height: 9px;
  }

  .shortcuts-block__body-list li{
      padding: 15px 16px 15px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between; 
      color: #8C8C8C;
      font-size: 14px;
      font-weight: normal;
  }
  .key-combo span{    
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      width: 18px;
      height: 18px;
      padding: 0 5px 0 4px;
      color: #666666;
      background-color: #303030;
      border: 1px solid #3F3F3F;
      border-radius: 2px;
      letter-spacing: 0;
      line-height: 17px;
  }

  .key-combo .small {
    width: auto;
    margin-right: 2px;
  }

  .key-combo .long {
      width: auto;
      min-width: 38px;
      margin-right: 2px;
  }

  .key-combo {
      display: flex;
  }

  .shortcuts-info {
      color: #D7DCDD;
      display: flex;
      align-items: center; 
      cursor: pointer;      
  }

  .shortcuts-info img {
      margin-right: 10px;
  }

  .shortcuts-block__head-btn svg {
    fill: #fff;
  }

  .shortcut {
    padding: 10px 15px;
    color: #bfc3c4;
    cursor: pointer;
  }

  .shortcut:hover {
    color: #fff;
  }

  .short {
    padding: 3px 7px;
    background: #8c8c8c;
    color: #2a2a2a;
    border-radius: 6px;
    font-weight: bold;
    font-size: 9px;
    vertical-align: middle;
    margin-right: 6px;
  }

  .shortcut:hover .short {
    background-color: #fff;
  }

  .settings-overlay {
      z-index: 24;
  }

  .shortcuts-block {
      z-index: 25;
  }
</style>