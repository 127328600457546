<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Details</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            rangeSliderLabel="Sharpen"
            rangeSliderMeasure="%"
            :defaultValue="20"
            rangeSliderClass="range-blue"
            :rangeSliderValue="sharpen"
            :minValue="0"
            :maxValue="100"
            @update="usharpen"
          />
        </div>

        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="clarity"
            rangeSliderLabel="Clarity"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            @update="uclarity"
          />
        </div>

        <div class="panel_row">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="blur"
            rangeSliderLabel="Blur"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            @update="ublur"
          />
        </div>

        <div class="d-flex between two-column panel_row pt_36">
          <div class="half">
            <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div class="half">
            <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p ">
            The Details tool allows you to emphasize <br />
            texture and draw viewer focus with <i>Sharpen</i>,<br />
            <i>Clarity</i> and <i>Blur</i> adjusters.<br />
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import RangeLib from "@/components/RangeLib.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditDetailsPanel",
  components: {
    RangeLib,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    return {
      tipsText: "",
      helpIcon: require("@/assets/img/help-icon.svg"),
      details: false,
      sharpen: 20,
      clarity: 0,
      blur: 0,
    };
  },

  beforeDestroy() {
    this.closeCurrentEditor();
  },

  methods: {
    usharpen(val) {
      this.sharpen = val;
      this.updateEffect(this.sharpen, this.clarity, this.blur);
    },
    uclarity(val) {
      this.clarity = val;
      this.updateEffect(this.sharpen, this.clarity, this.blur);
    },
    ublur(val) {
      this.blur = val;
      this.updateEffect(this.sharpen, this.clarity, this.blur);
    },
    updateEffect(sharpen, clarity, blur) {
      this.details.setupEffect({
        clarity,
        blur,
        sharpen,
      });
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    async applyIt() {
      await this.$lib._cache.addToBgCacheUpdating(
        this.$lib.CONST.MAP_CHANGES.BG_DETAILS_EFFECT,
        {},
        true,
        { isThumb: true }
      );

      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
    },
    closeIt() {
      this.closeCurrentEditor(this.$lib);
    },
    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();

      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },
  },
  mounted: function() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.details = new this.$lib.effects.DetailEffect(
      this.$lib.app.stage.children[0]
    );

    this.updateEffect(this.sharpen, this.clarity, this.blur);
  },

  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },

    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
  },
};
</script>
