<template>
  <div class="popup save_popup upgrade-popup sign-in">
    <a class="close_popup_btn" v-on:click="changePopup('register', false)">
      <span class="inch-icon inch-close-icon"></span>
    </a>
    <div class="form_loading" v-if="isLoading"></div>
    <h2 class="title sm">Register</h2>
    <div class="form-group">
      <div class="label">Name</div>
      <input type="text" v-model="data.name" class="form-control" />
    </div>
    <div class="form-group">
      <div class="label">E-Mail Address</div>
      <input type="email" v-model="data.email" class="form-control" />
    </div>
    <div class="form-group">
      <div class="label">Password</div>
      <input type="password" v-model="data.password" class="form-control" />
    </div>
    <div class="form-group">
      <div class="label">Confirm Password</div>
      <input type="password" v-model="data.password_confirmation" class="form-control" />
    </div>
    <div class="form_errors" v-if="formErrors">
      <ul id="example-1">
        <li v-for="item in formErrors">{{ item[0] }}</li>
      </ul>
    </div>
    <div class="btn-wrap">
      <a href="#" class="btn-orange" @click="signUp">Register</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Registration",
  data: () => {
    return {
      formErrors: [],
      isLoading: false,
      data: {
        name: "",
        email: "",
        password: "",
        password_confirmation: ""
      }
    };
  },
  methods: {
    async signUp() {
      this.isLoading = true;

      const result = await fetch(`${this.$store.state.prefixURL}/register3`, {
        method: "POST",
        body: JSON.stringify(this.data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content")
        }
      }).then(res => res.json());

      this.formErrors = result.errors;
      this.isLoading = false;

      if (result.success) {
        this.$store.state.USER = result.data.user;
        this.changePopup("register", false);
      }
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    }
  }
};
</script>