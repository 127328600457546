<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Prompt Generation</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    Prompt Generation
                </a>
            </div>

            <div class="panel_row">
                <div class="input_width_height_wrpr">
                    <label class="text_label has_mb">Prompt Output</label>
                    <textarea ref="promptTextarea" disabled placeholder="Click “Generate Prompt” to receive prompt output..." class="text_input input_width_height textarea-gen" v-model="result.prompt"></textarea>
                </div>
            </div>
            
            <div class="panel_row actions-group">
                <a class="button btn-action-all btn-action duplicate full_w_btn copy_btn" @click="copyPrompt()" :class="{ disabled: !result.prompt }">Copy Prompt</a>
            </div>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Generate creative prompts or ideas for image editing and enhancement.']"
            filename="Prompt_Generation"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {},

        result: {
            prompt: ''
        }
      };
    },

    methods: {
        copyPrompt() {
            console.log('Copying prompt');
            const textarea = document.createElement("textarea");
            textarea.setAttribute('style', '    width: 0px; border: none;opacity: 0;position: absolute;z-index: -1;left: -20px;top: -20px;');
            textarea.value = this.result.prompt;
            document.body.appendChild(textarea);
            
            textarea.focus();
            textarea.select();
            document.execCommand('copy');

            textarea.remove();
        },

        async applyIt() {
            if(!this.isPremium) {
                return this.$store.dispatch("popupsToggle", {
                    property: "backgroundRemoverPopup", value: true,
                });
            }

            this.$store.dispatch("preloader", { message: "Applying the effect...", isShow: true });

            try {
                const image = await this.$aitool.getCurrentStateImage();
                const output = await this.$aitool.processingEffect(this.toolApi, await this.generatePayload(image));
                
                this.result.prompt = output;
                // this.applyChanges(this.$lib);
                this.$store.dispatch('changesApplied');
            } catch (err) {
                this.showErrorMessage(false, err);
            }

            this.$store.dispatch("preloader", { isShow: false });
        },
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.ImageToPromptAPI;
        }
    }
  };
</script>

<style scoped>
    .textarea-gen {
        color: #A4A2A2;
        font-family: Proxima Nova;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.05px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        resize: none;
        border: 1px solid #3F3F3F;
        width: 222px;
        height: 300px;
        padding: 14px 10px 14px 12px;
        gap: 12px;
        border-radius: 4px 0px 0px 0px;
    }

    .copy_btn {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
</style>
