<template>
  <div
    :key="updRange"
    :class="['range_slider_box', sliderBoxClass, {'mobile-slider': isMobileView}]"
    @dblclick.stop="dbClickToDefault()"
    ref="rangeBox"
  >
    <div v-if="!isMobileView" class="range-top-info">
      <span>{{ rangeSliderLabel }}</span>
      <div class="range-output">
        <template v-if="rangeSliderMeasure">
          <div class="output-value">{{ rangeSliderValue }}</div>
          <span class="range_measure">{{rangeSliderMeasure}}</span>
        </template>
        <template v-else>
          <div class="output-value">{{ rangeSliderValue }}</div>
        </template>
      </div>
    </div>
    <VueSlider
      :value="(rangeSliderValue || rangeSliderValue == 0) ? rangeSliderValue : 80"
      tooltip="none"
      :class="'vue_slider ' + rangeSliderClass ? rangeSliderClass : 'range-blue'"
      @change="onChange"
      @drag-end="onDragEnd"
      :min="minValue ? minValue : 0"
      :max="maxValue ? maxValue : 100"
      :interval="interval ? interval : 1"
      ref="rangeSlider"
    >
      <template v-slot:process="{ start, end }">
        <div class="vue-slider-process line-progress">
          <div class="line-left" :style="{width: 'calc('+end +'% - 2px)'}"></div>
          <div class="line-right" :style="{width: 'calc('+(100 - end) +'% - 2px)'}"></div>
        </div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
export default {
  name: "RangeSlider",
  components: {
    VueSlider
  },

  computed: {
    dotLeft() {
      return this.leftWidth + "%";
    },
    dotRight() {
      return this.rightWidth + "%";
    }
  },

  props: {
    isMobileView: Boolean,
    rangeSliderId: String,
    rangeSliderValue: Number,
    rangeSliderLabel: String,
    rangeSliderMeasure: String,
    rangeSliderClass: String,
    minValue: Number,
    maxValue: Number,
    interval: Number,
    sliderBoxClass: String,
    defaultValue: Number
  },

  data: function() {
    return {
      thisRangeValue: 50,
      updRange: false
    };
  },

  computed: {
    getRangeDefault() {
      return this.rangeDefault;
    }
  },

  methods: {
    dbClickToDefault() {
      this.updRange = !this.updRange;
      this.$emit('update', this.defaultValue || 0);
    },

    onChange(value) {
      this.thisRangeValue = value;
      this.$emit('update', value);
    },

    onDragEnd() {
      this.$emit('dragend', this.thisRangeValue);
    }
  }
};
</script>

<style>
.vue-slider {
  border-radius: 15px;
  overflow: hidden;
}
.mobile-slider .vue-slider {
  padding: 12px 0 !important;
  border-radius: 0px;
  overflow: visible;
}
.line-left {
  position: absolute;
  height: 2px;
  width: 222px;
  border-radius: 100px;
  left: -8px;
  top: 0;
  pointer-events: none;
}
.line-right {
  position: absolute;
  height: 2px;
  width: 222px;
  border-radius: 100px;
  right: -8px;
  top: 0;
  pointer-events: none;
}
.range-blue .line-left {
  background-color: #249acf;
}
.range-blue .line-right {
  background-color: #8c8c8c;
}

body .main_content_wrpr .vue-slider-rail {
  width: calc(100% - 16px);
  margin: 0 auto;
  background: transparent;
}

body .vue-slider-process {
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vue-slider-dot {
  width: 16px !important;
  height: 16px !important;
  background-color: #fff;
  transition: none !important;
  border-radius: 100%;
  cursor: pointer;
  z-index: 99999 !important;
}

.mobile-slider .vue-slider-dot {
  width: 32px !important;
  height: 32px !important;
  border: 4px solid #2a2a2a;
}

.vue-slider-dot:hover {
  background-color: #d7dcdd;
}

.vue-slider-dot:active {
  background-color: #d7dcdd;
}

.range-blue .vue-slider-dot {
  background-color: #249acf;
}

.range-blue .vue-slider-dot:hover {
  background-color: #1d7ea8;
}

.range-blue .vue-slider-dot:active {
  background-color: #1d7ea8;
}

.line-left,
.line-right {
  background-size: 222px;
  background-position: right;
}
.line-left {
  background-position: left;
}
.range-hue .line-left,
.range-hue .line-right {
  background-image: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
}
.range-saturation .line-left,
.range-saturation .line-right {
  background-image: linear-gradient(to right, #727272 0%, #ff0000 100%);
}
.range-temperature .line-left,
.range-temperature .line-right {
  background-image: linear-gradient(to right, #1f8ded 0%, #f88d00 100%);
}
.range-tint .line-left,
.range-tint .line-right {
  background-image: linear-gradient(to right, #24933d 0%, #871d99 100%);
}
.range-vibrance .line-left,
.range-vibrance .line-right {
  background-image: linear-gradient(to right, #ffffff 0%, #fe0206 100%);
}
.quality_range .line-left,
.quality_range .line-right {
  background-image: linear-gradient(
    to right,
    #ff5353 35%,
    #fa9d1c 60%,
    #4adaba 100%
  );
  background-size: 356px;
}
.vue-slider-dot-handle {
  box-shadow: none;
  pointer-events: none;
  display: none;
}
</style>
