<template>
  <div
    class="toggles-for-period"
    :class="{ reg_mode_toggle: isRegistrationMode }"
  >
    <div class="btn--plan-toggle-background">
      <button
        class="btn--plan-period"
        @click="changePeriod('monthly')"
        :class="{ active: period === 'monthly' }"
      >
        Monthly
      </button>
      <button
        class="btn--plan-period"
        @click="changePeriod('yearly')"
        :class="{ active: period === 'yearly' }"
      >
        Yearly
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    period: {
      type: String,
      default: 'yearly', // yearly
    },

    isRegistrationMode: {
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    changePeriod(period) {
      this.$emit('change', period);
    },
  },
};
</script>

<style scoped>
.btn--plan-period {
  border-radius: 8px;
  border: none;
  padding: 7px 28px;
  font-family: Proxima Nova;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  /* margin: 2px; */
  background: #f5f5f5;
  transition: 0.3s;
  color: #727272;
}

.btn--plan-period.active {
  background: #ff9360;
  box-shadow: 0px 1px 2px 0px #0000000f;
  box-shadow: 0px 1px 3px 0px #0000001a;
  border-radius: 8px;
  transition: 0.3s;
  color: #fff;
}

.btn--plan-toggle-background {
  background: #f5f5f5;
  padding: 3px;
  border-radius: 8px;
  margin-top: -15px;
}

.toggles-for-period {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 552px;
}

.reg_mode_toggle {
  width: auto !important;
  margin-bottom: 24px;
}

.reg_mode_toggle .btn--plan-toggle-background {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg_mode_toggle .btn--plan-period {
  width: 100%;
}
</style>
