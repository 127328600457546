//window.temp = Loading;

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import VueDragscroll from 'vue-dragscroll'
import Vuebar from 'vuebar';
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VueOffline from 'vue-offline'
import AITool from './libs/AITools';
export const EventBus = new Vue();


if (typeof _LIB === 'undefined') {
    var _LIB = {};
}

Vue.use(VueDragscroll)
Vue.use(Vuebar)
Vue.use(Tooltip);
Vue.use(VueOffline)


Vue.config.productionTip = false

Vue.directive('custom-drag', {
    bind: function(el) {
        let pos1, pos2, pos3, pos4, popupWindow = el;

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            popupWindow.style.top = (popupWindow.offsetTop - pos2) + "px";
            popupWindow.style.left = (popupWindow.offsetLeft - pos1) + "px";
            popupWindow.style.bottom = 'auto';
        }

        function closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        }

        function movePopupStart(e) {
            e.preventDefault();
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        }
        el.firstElementChild.onmousedown = movePopupStart
        el.classList.add('draggable-popup');
    }
});

Vue.directive('custom-upload', {
    bind: function(el) {
        var input = el

        var self = this;


        input.addEventListener('change', async function(e) {

            console.log("===============");
            console.log(this);
            console.log("self", self);
            if(!navigator.onLine){
            	return true;
            }

            e.preventDefault();
            var tokenEl = document.querySelector('meta[name="csrf-token"]');
            var token = tokenEl.getAttribute('content');
            console.log({ token });

            console.log("input is", input);
            console.log("e is ", e);

            var data = new FormData()
            data.append('file', input.files[0])
            data.append("user_id", document.head.querySelector("[name=uniq_id][content]").content);
            data.append('type', el.attributes['data-type'].value);

            await fetch(`${store.state.prefixURL}/files/upload`, {
                method: 'POST',
                headers: { 'X-CSRF-TOKEN': token },
                body: data
            }).then(
                response => response.json()
            ).then(success => function() {
                console.log(success);
            }).catch(
                error => console.log(error)
            )

        });

    }
});

Vue.directive('click-outside', {
    bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
            if (!(el == event.target || el.contains(event.target)) &&
                vnode.context[binding.expression]
            ) {
                vnode.context[binding.expression](event, binding.arg);
            }
        };

        document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Vue.prototype.$lib = window._LIB;

(async() => {
    await store.dispatch('refreshUser');
    const app = new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
    window.ww = app;

    Vue.prototype.$aitool = new AITool(app);
    window.ww.$on('initLib', function() {

        this.$lib.initialLib();
    });

})();
