<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="editPanelPropChange">
        <div class="back">
            <a href="#" class="back-icon">
                <span class="inch-icon inch-back-icon"></span>
            </a>

            <h3>Photo to Anime</h3>
        </div>

        <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt/>
        </div>
        </div>

        <div v-bar>
        <div class="text_panel_scroll">
            <div class="panel_row">
                <a href="#" class="blue_btn full_w_btn" @click="applyIt">
                    Photo to Anime
                </a>
            </div>

            <PanelAccordionAi accordionTitle="Advanced" :accordActiveClass="false" accordAdditionalClass="with-border" accordBodyClass="pb-8">
                <template v-slot:panel_accordion_body>
                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Prompt</label>
                            <input type="text" class="text_input input_width_height" v-model="payload.prompt"/>
                        </div>
                    </div>

                    <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Negative Prompt</label>
                            <input type="text"  class="text_input input_width_height" v-model="payload.negative_prompt"/>
                        </div>
                    </div>

                    <div class="panel_row">
                        <Range
                            rangeSliderId="strength"
                            :rangeSliderValue="payload.strength"
                            rangeSliderLabel="Strength"
                            rangeSliderClass="range-blue"
                            :minValue=".1" :stepSlider="0.01" :maxValue="1"
                            @dragEnd="changeRange('strength')($event)"
                            :changeValue="changeRange('strength')"
                        />
                        <span class="custom-label-text">The weight of image</span>
                    </div>

                    <div class="d-flex between two-column panel_row">
                        <div class="half">
                            <label class="text_label has_mb">Width</label>
                            <div class="btns_wrpr">
                                <InputWidthHeight
                                    :inputValue="payload.width"
                                    :maxValue="1024" :minValue="24"
                                    @change="changeRange('width')(parseInt($event.value))"
                                    inputName="width"
                                    :isChangeInputValueIncrement="true"
                                />
                            </div>
                        </div>

                        <div class="half">
                            <label class="text_label has_mb">Height</label>
                            <div class="btns_wrpr">
                                <InputWidthHeight
                                    :inputValue="payload.height"
                                    :maxValue="1024" :minValue="24"
                                    @change="changeRange('height')(parseInt($event.value))"
                                    inputName="height"
                                    :isChangeInputValueIncrement="true"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- <div class="panel_row disabled-o">
                        <Range
                            rangeSliderId="num_outputs"
                            :rangeSliderValue="payload.num_outputs"
                            rangeSliderLabel="Num Outputs"
                            rangeSliderClass="range-blue"
                            :minValue="1" :stepSlider="1" :maxValue="3"
                            @dragEnd="changeRange('num_outputs')($event)"
                            :changeValue="changeRange('num_outputs')"
                        />
                        <span class="custom-label-text">Number of images to output.</span>
                    </div> -->
                    
                    <div class="panel_row">
                        <Range
                            rangeSliderId="num_inference_steps"
                            :rangeSliderValue="payload.num_inference_steps"
                            rangeSliderLabel="Num Inference Steps"
                            rangeSliderClass="range-blue"
                            :minValue="1" :stepSlider="1" :maxValue="100"
                            @dragEnd="changeRange('num_inference_steps')($event)"
                            :changeValue="changeRange('num_inference_steps')"
                        />
                        <span class="custom-label-text">Number of denoising steps</span>
                    </div>

                    <div class="panel_row">
                        <DropdownSelect
                            textLabel="Scheduler" key="scheduler"
                            :current="payload.scheduler"
                            :selectOptions="schedulers"
                            :selectClass="dropdowns['scheduler']"
                            @toggleDropdown="changeDropdown('scheduler')"
                            @selectDropdown="changeRange('scheduler')($event)"
                            @resetSelectClass="changeDropdown('scheduler', false)"
                            :skipHeight="true"
                        />
                    </div>

                    <div class="panel_row">
                        <Range
                            rangeSliderId="guidance_scale"
                            :rangeSliderValue="payload.guidance_scale"
                            rangeSliderLabel="Guidance Scale"
                            rangeSliderClass="range-blue"
                            :minValue="1" :stepSlider="1" :maxValue="20"
                            @dragEnd="changeRange('guidance_scale')($event)"
                            :changeValue="changeRange('guidance_scale')"
                        />
                        <span class="custom-label-text">Scale for classifier-free guidance</span>
                    </div>

                    <!-- <div class="panel_row">
                        <div class="input_width_height_wrpr">
                            <label class="text_label has_mb">Seed</label>
                            <input type="number" class="text_input input_width_height" v-model="payloadTemp.seed"/>
                        </div>
                        <span class="custom-label-text">Leave blank to randomize the seed</span>
                    </div> -->
                </template>
            </PanelAccordionAi>
        </div>

        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>

        <TooltipAI
            :list="['Transform photos into anime-style illustrations with artistic flair.']"
            filename="Photo_to_Anime"
        />
    </div>
</template>

<script>
  import BaseComponent from './BaseComponent.js';

  export default {
    mixins: [BaseComponent],
    data: function() {  
      return {
        payload: {
            "width": 1024,
            "height": 1024,
            "prompt": "anime",
            "strength": 0.5,
            "scheduler": "K_EULER_ANCESTRAL",
            "num_outputs": 1,
            "guidance_scale": 6,
            "negative_prompt": "",
            "num_inference_steps": 20
        },

        payloadTemp: {
            seed: 0,
        },

        schedulers: ['DDIM', 'DPMSolverMultistep', 'HeunDiscrete', 'KarrasDPM', 'K_EULER_ANCESTRAL', 'K_EULER', 'PNDM'],
        dropdowns: {
            scheduler: ''
        }
      };
    },

    computed: {
        toolApi() {
            return this.$aitool.tools.PhotoToAnimeAPI;
        }
    },
  
    methods: {
        async generatePayload(image) {
            return { ...this.payload, image, seed: 0 };
        },

    	async validateFaces(){
		    let blob = await this.$aitool.getCurrentBlobImage();
		    let b64 = await this.blobToB64(blob);
		    let faces = await this.detectFaces(b64);
		    if(faces>1){
				return {success: false, error: "More than one face detected in the image. Please upload an image that contains exactly one face."};
		    }
		    


			return {success: true};
    	}
        
    }
  };
</script>
