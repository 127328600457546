<template>
  <div>
    <label v-if="textLabel" class="text_label has_mb">{{ textLabel }}</label>
    <div class="dropdown_select" :class="selectClass">
      <div class="dropdown_select_current" v-on:click="$emit('toggleDropdown')">
        <span class="select_current_text">
          <span v-if="placeholder && !printCurrent">{{ placeholder }}</span>
          {{ printCurrent }}
        </span>
        <ArrowDownIcon />
      </div>
      <div class="dropdown_select_wrap">
        <div v-bar style="height: 368px;">
          <ul class="dropdown_select_list">
            <li
              v-for="(option, index) in options" :key="index"
              :class="[
                isCurrent(option), isDivider(option), tabActive(option, index)
              ]" v-on:click="emitSelectDropdown(option, index)"
              @mousemove="openOptions($event, option, index)"
            >
              <template v-if="option.type == 'tab'">
                <div>{{ printOption(option) }}</div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="4.164" height="8" viewBox="0 0 4.164 8" style="transition: .3s;">
                    <path :fill="!($store.state.dropdownMenuOption.index == index) ? '#8c8c8c' : '#d7dcdd'" class="a" d="M1.836,6.25l-.493.563L5.1,10.191l.246.223.246-.223L9.344,6.813,8.851,6.25,5.344,9.405Z" transform="translate(-6.25 9.344) rotate(-90)"/>
                  </svg>

                  <!-- <defs>
                    <style>.a{fill:#8c8c8c;}</style></defs> -->
                  
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" style="transform: rotate(-90deg);" width="9" height="5" class="">
                    <path d="M5.04 4.841a.574.574 0 01-.4.159h-.282a.59.59 0 01-.4-.159L.111 1.128a.351.351 0 010-.512L.644.104a.378.378 0 01.525 0l3.329 3.214L7.829.106a.386.386 0 01.533 0l.525.512a.351.351 0 010 .512z" fill="#8c8c8c"></path>
                  </svg> -->
                </div>
              </template>
              <template v-else>{{ printOption(option) }}</template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowDownIcon from "@/assets/img/arrow_down.svg?inline";
import utils from '../libs/utils';

export default {
  name: "DropdownSelect",
  components: {
    ArrowDownIcon
  },
  props: {
    textLabel: String,
    selectClass: String,
    current: {
      type: [String, Object],
      default: () => ''
    },
    selectOptions: Array,
    placeholder: String
  },
  data: function() {
    return {
      openTab: 0,
      options: this.selectOptions,
      toggle: false,
      id: utils.uuidv4()
    };
  },
  computed: {
    printCurrent() {
      if(typeof this.current == 'object') {
        let title = this.current.item ? this.current.item.title : this.current.title;
        if(this.current.item && this.current.preset) {
          const preset = this.selectOptions.find(so => so && so.id == this.current.preset);
          if(preset) {
            title = `${preset.title} - ${this.current.item.title}`;
          }
        }

        return title;
      }

      return this.current;
    },

    triggerDropdown() {
      return this.$store.state.triggerDropdown;
    },
    
    dropdownMenuOption() {
      return this.$store.state.dropdownMenuOption;
    },

    selectGlobalDropdown() {
      return this.$store.state.selectGlobalDropdown;
    }
  },

  watch: {
    selectGlobalDropdown(val) {
      if(val && val.id == this.id) {
        this.$emit("selectDropdown", val);
      }
    },

    triggerDropdown() {
      this.$emit("resetSelectClass");
    }
  },

  methods: {
    tabActive(option, index) {
      const hoverActive = this.dropdownMenuOption && this.dropdownMenuOption.id == this.id && this.dropdownMenuOption.index == index;

      return {
        flex_drop: option.type == 'tab',
        active: hoverActive || this.current && typeof this.current == 'object' && this.current.preset == option.id
      }
    },

    openOptions(ev, option, index) {
      if(typeof option != 'object') {
        this.$store.state.dropdownMenuOption = false;
      } else {
        const position = JSON.parse(JSON.stringify(ev.target.getBoundingClientRect()));
        this.$store.state.dropdownMenuOption = {
          current: this.current,
          list: option.presets, preset: option.id,
          index, position, id: this.id
        };
      }
    },

    isCurrent(value) {
      return value === this.current ? "active" : "";
    },
    isDivider(value) {
      return value === "_divider" ? "divider" : "";
    },

    emitSelectDropdown(option, index) {
      if(typeof option == 'object' && option.type == 'tab') {
        this.openTab = index;

        return;
      }

      if (option == "_divider") return;
      this.$emit("selectDropdown", option);
    },

    printOption(option) {
      let opt = option;
      if(typeof opt == 'object') {
        opt = option.title;
      }

      return opt == "_divider" ? "" : opt;
    }
    /* addClick() {
      this.$nextTick(() => {
        if (this.selectClass === "active" && this.toggle) {
          this.$emit("selectDropdown", this.current);
          document.removeEventListener("click", this.addClick);
          this.toggle = false;
        } else {
          this.toggle = true;
        }
      });
    },
 */
    /* setPresetStyle(option) {
                if(!option) {
                    return {
                        padding: 0,
                        'border-top': '1px solid #3e4049'
                    };
                } else {
                    return {};
                }

                :style="setPresetStyle(option)"
            } */
  }
  /* updated() {
    if (this.selectClass === "active") {
      document.addEventListener("click", this.addClick);
    }
  },
  destroyed() {
    if (this.selectClass === "active") {
      document.removeEventListener("click", this.addClick);
    }
  }, */
};
</script>

<style>
  .dropdown-overlay {
    position: absolute;
  }

  .flex_drop {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }

  .flex_drop div {
    pointer-events: none;
  }

  .select_current_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
  }

</style>
