<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt()">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Rotate</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="d-flex double_btns_block between two-column panel_row">
          <div class="half">
            <label class="text_label has_mb">Rotate</label>
            <div class="btns_wrpr">
              <a
                href="#"
                class="btn-action btn-action-left"
                @click="rotate(-90, true)"
                v-tooltip.top="'Counter Clockwise'"
              >
                <span class="inch-icon inch-rotate-left-icon"></span>
              </a>
              <a
                href="#"
                class="btn-action btn-action-right"
                @click="rotate(90, true)"
                v-tooltip.top="'Clockwise'"
              >
                <span class="inch-icon transform inch-rotate-left-icon"></span>
              </a>
            </div>
          </div>
          <div class="half">
            <label class="text_label has_mb">Flip</label>
            <div class="btns_wrpr">
              <a
                href="#"
                class="btn-action btn-action-left"
                @click="flip(1)"
                v-tooltip.top="'Flip Horizontal'"
              >
                <span class="inch-icon inch-flip-horizontal-icon"></span>
              </a>
              <a
                href="#"
                class="btn-action btn-action-right"
                @click="flip(2)"
                v-tooltip.top="'Flip Vertical'"
              >
                <span class="inch-icon inch-flip-verical-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="panel_row margin_bottom_15">
          <RangeLib
            rangeSliderId="editOpacity"
            :rangeSliderValue="currentDeg"
            rangeSliderLabel="Straighten"
            rangeSliderMeasure="°"
            rangeSliderClass="range-blue"
            :minValue="-180"
            :maxValue="180"
            :stepSlider="1"
            @update="sliderUpdate"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div  class="half">
            <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            The Rotate tool allows you to rotate an image clockwise or counter
            clockwise, as well as straighten it up or flip it as a mirror image.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import RangeLib from "@/components/RangeLib.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditRotatePanel",
  components: {
    RangeLib,
    Tips,
    AdLeftPanel
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    return {
      tipsText: "",
      helpIcon: require("@/assets/img/help-icon.svg"),
      libRotate: false,
      currentDeg: 0,
      changes: false,
    };
  },
  mounted: async function() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.libRotate = new this.$lib.edit.Rotate(this.$lib.app.stage.children[0]);
    await this.libRotate.init()
    // Костыль
    // this.$store.dispatch("zoomDashboard", 1);
    // this.$lib.app.scaleStage(1);
    // this.$lib.app.setZoomProcentage(1, true);
    // document.getElementsByClassName("footer")[0].classList.add("disabled");
    // Конец костыля
  },

  async beforeDestroy() {
    if (this.changes) {
      this.$lib.app.resetStage();
      await this.$lib._cache.renderSaveByTimestamp();
    }
    //document.getElementsByClassName("footer")[0].classList.remove("disabled");
  },

  methods: {
    async applyIt() {
      /* await this.$lib._cache.addToBgCacheUpdating(
        this.$lib.CONST.MAP_CHANGES.ROTATE_BACKGROUND,
        {},
        true,
        { isThumb: true }
      ); */
      await this.libRotate.addToCache();
      await this.$lib.app.addTransparentPNGMask();
      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
    },

    async closeIt() {
      if (this.changes) {
        this.$lib.app.resetStage();
        await this.$lib._cache.renderSaveByTimestamp();
      }
      this.closeCurrentEditor(this.$lib);
    },
    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },
    async rotate(deg, isIncrement) {
      if (!isIncrement) {
        //range slider
        this.currentDeg = deg;
        await this.libRotate.rotateAngle(deg, isIncrement);
      } else {
        /* //rotate 90deg
                    if((deg > 0 && this.currentDeg <= 270) || (deg < 0 && this.currentDeg >= -270)) {
                        this.currentDeg += deg;
                        this.libRotate.rotateAngle(deg, isIncrement); 
                    } else if(deg > 0 && this.currentDeg > 270) {
                        //max limit
                        this.currentDeg = 360
                        this.libRotate.rotateAngle(360, false); 
                    } else if(deg < 0 && this.currentDeg < -270) {
                        //min limit
                        this.currentDeg = -360
                        this.libRotate.rotateAngle(-360, false);
                    }  */

        await this.libRotate.rotateAngle(deg, true);
      }
      this.$lib.app.setFabricZoom();
      this.changes = true;
    },
    async flip(mode) {
      await this.libRotate.flipSprite(mode);
      this.changes = true;
    },
    async sliderUpdate(event) {
      if (event >= -180 && event <= 180) {
        await this.rotate(event, false);
      }
    },
  },
  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },

    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
  },
};
</script>
