import { render, staticRenderFns } from "./SaveAsProject.vue?vue&type=template&id=0861de05&scoped=true&"
import script from "./SaveAsProject.vue?vue&type=script&lang=js&"
export * from "./SaveAsProject.vue?vue&type=script&lang=js&"
import style0 from "./SaveAsProject.vue?vue&type=style&index=0&id=0861de05&scoped=true&lang=css&"
import style1 from "./SaveAsProject.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0861de05",
  null
  
)

export default component.exports