<template>
    <div>
        <svg
            v-if="!isSucking && !suckerPixels"
            :class="{ active: isOpenSucker }"
            class="sucker"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-12 -12 48 48"
            @click="openSucker"
        >
            <path class="colorpicker_icon" d="M13.458,2a2.53,2.53,0,0,0-1.8.75l-2.237,2.2-.611-.63-.648.63.648.649.041.044c-.479.46-3.3,3.169-3.975,3.848a5.3,5.3,0,0,0-1.135,1.5,3.1,3.1,0,0,0-.26,1.1,1.6,1.6,0,0,1-.137.638,4.006,4.006,0,0,1-.848,1.1l-.487.5L3.687,16l.492-.479c.977-.941,1.163-.873,1.718-.944A3.4,3.4,0,0,0,7,14.279a5.884,5.884,0,0,0,1.515-1.133l0,0,0-.006C9.21,12.451,12.05,9.725,12.5,9.29l.561.561.6-.628-.6-.634,2.2-2.235A2.552,2.552,0,0,0,13.458,2ZM8.43,7.37S9.513,6.354,9.513,6.354l2.261,2.235c-.47.452-3.147,2.98-3.886,3.719a6.339,6.339,0,0,1-1.239,1.009,4.693,4.693,0,0,1-1.053.344l-.947.333-.37-.235c.09-.142-.274-.31-.214-.442a3.2,3.2,0,0,0,.344-1.009,2.437,2.437,0,0,1,.147-.792,4.172,4.172,0,0,1,.854-1.177C6.027,9.723,7.97,7.814,8.43,7.37Z" fill="#8c8c8c"/>
        </svg>
        <svg
            v-if="isSucking"
            class="sucker"
            viewBox="-16 -16 68 68"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#9099a4"
        >
            <g
                fill="none"
                fill-rule="evenodd"
            >
                <g
                    transform="translate(1 1)"
                    stroke-width="4"
                >
                    <circle
                        stroke-opacity=".5"
                        cx="18"
                        cy="18"
                        r="18"
                    />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                        />
                    </path>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import imgSucker from '../img/sucker.png';

export default {
    props: {
        suckerCanvas: {
            type: null, // HTMLCanvasElement
            default: null
        },
        suckerArea: {
            type: Array,
            default: () => []
        },

        suckerPixels: {
            type: [Uint8Array, Boolean],
            default: () => false
        }
    },
    data() {
        return {
            isOpenSucker: false, // 是否处于吸管状态
            suckerPreview: null, // 吸管旁边的预览颜色dom
            isSucking: false // 是否处于吸管等待状态
        }
    },

    mounted() {
        window.sucker = this;
    },

    watch: {
        suckerCanvas(newVal) {
            this.isSucking = false;
            this.suckColor(newVal);
            newVal.style.cursor = `url(${imgSucker}) 0 32, default`
        }
    },

    computed: {
        pixiColorPicker() {
            return this.$store.state.pixiColorPicker;
        }
    },

    methods: {
        openSucker() {
            if (!this.isOpenSucker) {
                this.isOpenSucker = true
                this.isSucking = true
                this.$emit('openSucker', true)
                document.addEventListener('keydown', this.keydownHandler)
            } else {
                // 和按下esc键的处理逻辑一样
                this.keydownHandler({ keyCode: 27 })
            }
        },

        keydownHandler(e) {
            // esc
            if (e.keyCode === 27) {
                this.isOpenSucker = false
                this.isSucking = false
                this.$emit('openSucker', false)
                document.removeEventListener('keydown', this.keydownHandler)
                document.removeEventListener('mousemove', this.mousemoveHandler)
                document.removeEventListener('mouseup', this.mousemoveHandler)
                if (document.querySelector('.sucker-preview')) {
                    document.body.removeChild(this.suckerPreview)
                }

                this.suckerPreview = null
            }
        },

        getPixel(pxls, x, y, w, h) {
            const defaultResult = { data: [0, 0, 0, 255], isShow: false };
            const css = _LIB.lib.getStylesObject($(".centered-content"));
            const cssZoom = parseFloat((css.transform || '').replace(/[a-zA-Z]|\(|\)/g, ''));
            if(cssZoom) {
                const canvasSize = _LIB.app._canvasSize;
                const diffX = canvasSize.w * cssZoom / 2;
                const diffY = canvasSize.w * cssZoom / 2;
                // w *= cssZoom;
                // h *= cssZoom;
                x = (x / cssZoom);// - diffX;
                y = (y / cssZoom);// - diffY;
            }

            x = Math.ceil(x);
            y = Math.ceil(y);

            if(x < 0) return defaultResult;
            if(x > w) return defaultResult;
            if(y < 0) return defaultResult;
            if(y > h) return defaultResult;

            const pos = (x * 4) + (y * Math.floor(w) * 4);
            const rgba = new Array(4).fill(null).map((val, ind) => pxls[pos + ind]);
            return { data: rgba, isShow: true };

            const hex = _LIB.lib.rgbaToHexAlpha(`rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3] / 255})`);
            return hex;
        },

        mousemoveHandler(e) {
            const { clientX, clientY, target } = e;

            if(this.suckerCanvas.id == 'canvasDashboard' && !this.suckerPixels) {
                 let [r, g, b, a] = this.pixiColorPicker.move.rgba;
                const style = this. 
                Object.assign(style, {
                    position: 'absolute',
                    left: clientX + 20 + 'px',
                    top: clientY - 36 + 'px',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    border: '2px solid #fff',
                    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
                    background: `rgba(${r}, ${g}, ${b}, ${a/255})`, 
                    zIndex: 2 // 吸管的小圆圈预览色的层级不能超过颜色选择器
                });

                return;
            }
            
            let { top: domTop, left: domLeft, width, height } = this.suckerCanvas.getBoundingClientRect();
            // width = this.$lib.app.fabricCanvas.width;
            // height = this.$lib.app.fabricCanvas.height;

            let width1 = (this.suckerCanvas.getContext('2d').canvas.width) / window.devicePixelRatio;
            let height1 = (this.suckerCanvas.getContext('2d').canvas.height) / window.devicePixelRatio;

            const x = clientX - domLeft - (width1 - width)/2 // * coeff
            const y = clientY - domTop - (height1 - height)/2  // * coeff

            const ctx = this.suckerCanvas.getContext('2d');
            let imgData;

            const left_ = Math.min(x, width - 1) * window.devicePixelRatio;
            const top_ = Math.min(y, height - 1) * window.devicePixelRatio;

            if(this.suckerPixels) {
                const dom = this.suckerCanvas;

                const { top, left, width, height } = dom.getBoundingClientRect();
                const x = clientX - left;
                const y = clientY - top;

                imgData = this.getPixel(this.suckerPixels, x, y, _LIB.app.stage.width, _LIB.app.stage.height);
            } else {
                imgData = ctx.getImageData(left_, top_, 1, 1);
            }

            let [r, g, b, a] = imgData.data
            a = parseFloat((a / 255).toFixed(2))
            const style = this.suckerPreview.style
            Object.assign(style, {
                position: 'absolute',
                left: clientX + 20 + 'px',
                top: clientY - 36 + 'px',
                width: '24px', height: '24px',
                borderRadius: '50%',
                border: '2px solid #fff',
                boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
                background: `rgba(${r}, ${g}, ${b}, ${a})`,
                zIndex: 2 // 吸管的小圆圈预览色的层级不能超过颜色选择器
            })

            if(this.suckerPixels) {
                style.display = imgData.isShow ? '' : 'none';
            } else {
                if (
                    clientX >= this.suckerArea[0] &&
                    clientY >= this.suckerArea[1] &&
                    clientX <= this.suckerArea[2] &&
                    clientY <= this.suckerArea[3] &&
                    target.id === 'fabricDashboard'
                ) {
                    style.display = ''
                } else {
                    style.display = 'none'
                }
            }
        },

        suckColor(dom) {
            if (dom && dom.tagName !== 'CANVAS') {
                return
            }

            this.suckerPreview = document.createElement('div')
            this.suckerPreview.classList.add('sucker-preview')
            document.body.appendChild(this.suckerPreview)

            document.addEventListener('mousemove', this.mousemoveHandler)
            document.addEventListener('mouseup', this.mousemoveHandler)
            dom.addEventListener('click', e => this.mouseClick(e));
        },

        mouseClick(e) {
            const dom = this.suckerCanvas;
            if(this.suckerCanvas.id == 'canvasDashboard') {
                let [r, g, b, a] = this.pixiColorPicker.mousedown.rgba;
                a = parseFloat((a / 255).toFixed(2));

                this.$emit('selectSucker', { r, g, b, a, isUpdated: true });
                return;
            }

            const { clientX, clientY } = e
            const { top, left, width, height } = dom.getBoundingClientRect();
            const x = clientX - left;
            const y = clientY - top;
            const ctx = dom.getContext('2d');

            const left_ = Math.min(x, width - 1) * window.devicePixelRatio;
            const top_ = Math.min(y, height - 1) * window.devicePixelRatio;
            
            let imgData;
            if(this.suckerPixels) {
                imgData = this.getPixel(this.suckerPixels, x, y, _LIB.app.stage.width, _LIB.app.stage.height);
            } else {
                imgData = ctx.getImageData(left_, top_, 1, 1);
            }

            let [r, g, b, a] = imgData.data
            a = parseFloat((a / 255).toFixed(2))
            this.$emit('selectSucker', { r, g, b, a, isUpdated: true })
        } 
    }
}
</script>

<style lang="scss">
.sucker {
    width: 30px;
    fill: #9099a4;
    background: #2e333a;
    cursor: pointer;
    transition: all 0.3s;
    &:hover,
    &.active {
        fill: #1593ff;
    }
}
</style>
