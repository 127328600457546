<template>

	<div>
		<div :style="calculatePositionStyle"></div>
		<AdPanel v-if="isLeftAd" :content="isLeftAdContent" adPosition="left" :marginLeft="marginLeft"  />
	</div>

</template>

<script>
	import AdPanel from "@/components/AdPanel.vue";

	export default {
		name: "AdLeftPanel",
		components: {
			AdPanel
		},
		props: {
			margin:  {
				type: String,
				required: true
			},
			marginLeft:  {
				type: String,
				default: "14px"
			},
		},
		computed: {
			isLeftAd() {
				return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
			},
			isLeftAdContent() {
				if(!this.isLeftAd) return ''
				return this.$store.state.ADINFO?.left_panel_ad?.left_panel_script ?? '' ;
			},
			calculatePositionStyle() {
					return {
						position:'relative',
						width:'221px',
						textAlign: 'center',
						margin: this.margin,
						borderTop: '1px solid rgb(48, 48, 48)',
					};
			},

		},

		methods: {


		},
	};
</script>

<style scoped>

</style>
