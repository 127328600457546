<template>
	<div class="popup save_popup">
		<div v-if="!isMobileView" class="popup_header">
			<h3>Error</h3>
			<a class="close_popup_btn" @click="closePopup()">
				<span class="inch-icon inch-close-icon"></span>
			</a>
		</div>
		<div class="popup_body">
			<div class="popup_body_row_item reset_popup_content">
				<p>{{ popupState.facesPopup }}</p>
			</div>
			<div class="popup_body_row_item">
				<div class="btns-group mt0">
					<a class="blue_btn" @click="closePopup(true)">Ok</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "facesPopup",
	props: {
		content: String
	},
	mounted() {
		this.isMobileView = this.$store.state.isMobileView;
	},

	computed: {
		popupState() {
			return this.$store.getters.POPUPS;
		},
	},
	methods: {
		async closePopup(result) {
			this.$store.dispatch("popupsToggle", {
				property: "facesPopup",
				value: false
			});

		}
	},
	data: function() {
		return {
			isMobileView: false,
		};
	}
};
</script>
<style scoped>
	.mobileView_popup_confirm {
		margin: 0px 36px;
		color: #8C8C8C;
		background: #1D1D1D;
	}
	.mobileView_popup_body_head {
		color: #FFFFFF;
		margin-bottom: 12px;
	}
	.mobileView_popup_confirm .popup_body {
		background: #1D1D1D;
		padding: 24px;
	}
	.mobileView_popup_body_head h3 {
		font-size: 18px;
		line-height: 22px;
		letter-spacing: -0.72px;
		font-weight: 600;
	}
	.mobileView_popup_confirm .save_popup .popup_body_row_item {
		padding-left: 0;
		padding-right: 0;
	}
	.mobileView_popup_confirm .popup_body_row_item {
		padding: 0;
	}
	.mobileView_popup_confirm .btns-group {
		margin-top: 36px;
	}
	.mobileView_popup_confirm .reset_popup_content p {
		font-size: 15px;
		line-height: 20px;
	}
	.mobileView_popup_confirm .btns-group a {
		text-transform: capitalize;
		font-size: 13px;
		line-height: 18px;
	}
</style>
