<template>
    <BackgroundRemoverPopupOld v-if="showOldPopup"/>
    <BackgroundRemoverPopupOld v-else/>
</template>

<script>
    import BackgroundRemoverPopupOld from './Popups/Old/BackgroundRemoverPopup.vue';
    import BackgroundRemoverPopupNew from './Popups/AI/BackgroundRemoverPopup.vue';

    export default {
        name: "BackgroundRemoverPopup",

        components: {
            BackgroundRemoverPopupOld,
            BackgroundRemoverPopupNew
        },

        computed: {
            showOldPopup() {
                return ['IN', 'BR', 'ID'].includes(window.subLocation);
            }
        }
    };
</script>