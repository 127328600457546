<template>
  <div :class="[{'upgrade_mobile' : isMobileView}]">
    <div class="form_loading" v-if="isLoading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

<!-- Register variant -->
<div v-if="!isLoggedIn || isSubmitting">
  <div class="form_loading" v-if="isSubmitting"></div>
  <div
      class="popup save_popup upgrade-popup subs_popup reg-var"
      v-if="confirmSubscriptionPopup && isOnline"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="30"
        viewBox="0 0 36 30"
      >
        <g id="emblem" transform="translate(-586 -239)">
          <path
            id="bg"
            d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
            transform="translate(586 239)"
            fill="#ff9360"
          />
          <path
            id="plus"
            d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
            transform="translate(552.992 245)"
            fill="#fff"
          />
        </g>
      </svg>

      <div :class="{ is_pwa_popup: is_pwa }">
        <div class="content" style="margin-top: 19px; width: 348px">
          <div class="">
            <div class="">
              <h2
                class="title old_update_popup_1"
                :style="{ 'text-align': 'left', 'font-size': '26px', 'margin-bottom': sortedPlans[0].location == '' ? '24px' : '10px' }"
              >
                Upgrade to Pro{{ sortedPlans[0].location == '' ? ' to unlock all effects & premium features' : '' }}
              </h2>
              <p
                class="all-plus-future"
                :style="{ 'margin-left': '0px', 'margin-bottom': sortedPlans[0].location == '' ? '32px' : '12px' }"
              >
                Starting at <b>just {{ formatCurrency(sortedPlans[0].location == '' ? sortedPlans[1].billed_mo : sortedPlans[0].price, sortedPlans[0].currency) }}/{{ sortedPlans[0].location == '' ? 'mo' : 'week' }}</b>, get access to all Pro features for one low price. {{ sortedPlans[0].location == '' ? 'Pay by month or the year.' : '' }} Cancel anytime.
              </p>

            </div>
          </div>

          <div
            v-if="!is_pwa"
            :style="{ 'text-align': 'center', 'margin-top': '36px' }"
          >
          <div class="">
            <form v-on:submit.prevent="onSubmit" class="reg-form">
              <div>
                <input type="email" ref="xemail" :v-model="email" class="form-control" name="email" id="email" placeholder="Email address" required>
              </div>
              <div style="margin-top:16px">
                <input type="password" ref="xpassword" :v-model="password" class="form-control" name="password" id="password" placeholder="Password" required minlength="5">
              </div>
              <div class="form_errors" v-if="formErrors">
                <ul id="example-1">
                  <li v-for="(item, index) in formErrors" :key="index">{{ item[0] }}</li>
                </ul>
              </div>
              
              <ul v-if="['IN', 'BR', 'ID'].includes(userLocation)" class="plans">
                <li v-for="plan in sortedPlans" :key="plan.id">
                  <input
                    :value="plan.interval"
                    v-model="selectedPlan"
                    ref="subscription"
                    type="radio"
                    :id="`${plan.interval}-plan`"
                    name="subscription"
                  >
                  <label :for="`${plan.interval}-plan`">
                    <strong>{{ formatCurrency(plan.price, plan.currency) }} {{ plan.interval === 'year' ? 'per year' : plan.interval === 'month' ? 'per month' : 'for 1 week' }}</strong>
                    <span v-if="plan.interval !== 'week'" class="text-green"> save {{ calculateSavings(plan, sortedPlans[0]) }}%</span>
                  </label>
                  <div class="checkk"><div class="inside"></div></div>
                </li>
              </ul>

              <div style="margin-top:16px">
                <button type="submit" class="btn-orange"
              style="color: #fff; margin: 0; max-width: 100%; height: 48px; font-size:12px;box-shadow: 0px 2px 24px #FA640066;">SIGN UP</button>
              </div>
            </form>
          </div>

          </div>
          <div style="text-align: center; margin-top: 14px">
            <span class="already-member">
              Already a member?
              <a
                href="#"
                @click.prevent="showLoginPopUp"
                style="
                  text-align: left;
                  text-decoration: underline;
                  font: normal normal 300 14px/20px Soleil;
                  letter-spacing: -0.28px;
                  color: #fa6400;
                "
              >
                Sign in
              </a>
            </span>
          </div>

          <div class="agree-links">
            <span>By signing up, you agree to the Colorcinch <a href="https://www.cartoonize.net/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://www.cartoonize.net/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>.</span>
          </div>
        </div>
        <div class="teaser-modal__image"></div>
        <div class="plus-benefits">
          <p
                style="
                  font-size: 16px;
                  color: #1d1d1d;
                  letter-spacing: -0.64px;
                  text-align: left;
                  font-weight: normal;
                  font-family: Soleil;
                  margin-bottom: 12px;
                "
              >
                <strong>Pro users also get:</strong>
              </p>
              <ul class="list-plus">
                <li>AI one-click background remover</li>
                <li>Artistic filters, overlays & masks</li>
                <li>Smart editing tools & more</li>
              </ul>
        </div>
        <div>
          <a
            class="close_popup_btn"
            v-on:click="changePopup('stripeCheckoutPopup', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g id="x" transform="translate(-198 -160)">
                <circle
                  id="Ellipse_106"
                  data-name="Ellipse 106"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(198 160)"
                  fill="#000"
                  opacity="0.5"
                />
                <g
                  id="x_icon"
                  data-name="x icon"
                  transform="translate(203 165)"
                >
                  <rect
                    id="Rectangle_435"
                    data-name="Rectangle 435"
                    width="10.335"
                    height="0.979"
                    rx="0.489"
                    transform="translate(7.308 8) rotate(-135)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_436"
                    data-name="Rectangle 436"
                    width="10.335"
                    height="0.979"
                    rx="0.489"
                    transform="translate(0 7.308) rotate(-45)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </a>
          <div v-if="!isMobileView" id="img-area">
            <div id="img-area2" style="opacity: 0"></div>
          </div>
        </div>
      </div>
    </div>
</div>
    

  <!--  Normal Variant -->
    <div v-else>
      <div class="form_loading" v-if="isRedirecting"></div>
      <div
      class="popup save_popup upgrade-popup subs_popup"
      v-if="confirmSubscriptionPopup && isOnline"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="30"
        viewBox="0 0 36 30"
      >
        <g id="emblem" transform="translate(-586 -239)">
          <path
            id="bg"
            d="M0,0H36a0,0,0,0,1,0,0V28a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
            transform="translate(586 239)"
            fill="#ff9360"
          />
          <path
            id="plus"
            d="M51.532,3a.209.209,0,0,0-.221.221V4.328a.652.652,0,0,1-.664.664H47.924a.677.677,0,0,1-.619-.421l-.2-.531a.2.2,0,0,0-.354-.066L44.206,6.519a.678.678,0,0,0-.045.907l6.64,7.747a.641.641,0,0,0,.509.221.721.721,0,0,0,.509-.221L57.95,8.024a.228.228,0,0,0-.177-.376H51.31v4.161a.686.686,0,0,1-.221.531.6.6,0,0,1-.443.177.666.666,0,0,1-.62-.443L48.256,7.205a.645.645,0,0,1,.089-.6.612.612,0,0,1,.421-.266c.044,0,.088-.022.133-.022h8.809a.221.221,0,0,0,.155-.376L55.1,3.2A.6.6,0,0,0,54.63,3ZM46,6.32a.664.664,0,1,1-.664.664A.652.652,0,0,1,46,6.32Z"
            transform="translate(552.992 245)"
            fill="#fff"
          />
        </g>
      </svg>

      <div :class="{ is_pwa_popup: is_pwa }">
        <div class="content" style="margin-top: 19px; width: 340px">
          <div class="">
            <div class="">
              <h2
                class="title old_update_popup_1"
                :style="{ 'text-align': 'left', 'font-size': '26px', 'margin-bottom': sortedPlans[0].location == '' ? '24px' : '10px' }"
              >
                Upgrade to Pro{{ sortedPlans[0].location == '' ? ' to unlock all effects & premium features' : '' }}
              </h2>
              <p
                class="all-plus-future"
                :style="{ 'margin-left': '0px', 'margin-bottom': sortedPlans[0].location == '' ? '32px' : '12px' }"
              >
                Starting at <b>just {{ formatCurrency(sortedPlans[0].location == '' ? sortedPlans[1].billed_mo : sortedPlans[0].price, sortedPlans[0].currency) }}/{{ sortedPlans[0].location == '' ? 'mo' : 'week' }}</b>, get access to all Pro features for one low price. {{ sortedPlans[0].location == '' ? 'Pay by month or the year.' : '' }} Cancel anytime.
              </p>
              <p
                style="
                  font-size: 16px;
                  color: #1d1d1d;
                  letter-spacing: -0.64px;
                  text-align: left;
                  font-weight: normal;
                  font-family: Soleil;
                  margin-bottom: 20px;
                "
              >
                <strong>Pro users also get:</strong>
              </p>
              <ul class="list-plus">
                <li>AI one-click background remover</li>
                <li>Artistic filters, overlays & masks</li>
                <li>Smart editing tools</li>
                <li>Extended graphics library</li>
                <li>Fullscreen mode</li>
              </ul>

              <ul v-if="['IN', 'BR', 'ID'].includes(userLocation)" class="plans">
                <li v-for="plan in sortedPlans" :key="plan.id">
                  <input
                    :value="plan.interval"
                    v-model="selectedPlan"
                    ref="subscription"
                    type="radio"
                    :id="`${plan.interval}-plan`"
                    name="subscription"
                  >
                  <label :for="`${plan.interval}-plan`">
                    <strong>{{ formatCurrency(plan.price, plan.currency) }} {{ plan.interval === 'year' ? 'per year' : plan.interval === 'month' ? 'per month' : 'for 1 week' }}</strong>
                    <span v-if="plan.interval !== 'week'" class="text-green"> save {{ calculateSavings(plan, sortedPlans[0]) }}%</span>
                  </label>
                  <div class="checkk"><div class="inside"></div></div>
                </li>
              </ul>
            </div>
          </div>

          <div
            v-if="!is_pwa"
            :style="{ 'text-align': 'center', 'margin-top': sortedPlans[0].location == '' ? '36px' : '14px' }"
          >
            <a
              href="javascript:void(0)"
              @click="redirectToUpgrade"
              class="btn-orange"
              style="color: #fff; margin: 0; max-width: 100%; height: 48px"
              >Upgrade Now</a
            >
          </div>
          <div v-if="!isLoggedIn" style="text-align: center; margin-top: 14px">
            <span class="already-member">
              Already a member?
              <a
                href="#"
                @click.prevent="showLoginPopUp"
                style="
                  text-align: left;
                  text-decoration: underline;
                  font: normal normal 300 14px/20px Soleil;
                  letter-spacing: -0.28px;
                  color: #fa6400;
                "
              >
                Sign in
              </a>
            </span>
          </div>
        </div>
        <div class="teaser-modal__image"></div>
        <div>
          <a
            class="close_popup_btn"
            v-on:click="changePopup('stripeCheckoutPopup', false)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <g id="x" transform="translate(-198 -160)">
                <circle
                  id="Ellipse_106"
                  data-name="Ellipse 106"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(198 160)"
                  fill="#000"
                  opacity="0.5"
                />
                <g
                  id="x_icon"
                  data-name="x icon"
                  transform="translate(203 165)"
                >
                  <rect
                    id="Rectangle_435"
                    data-name="Rectangle 435"
                    width="10.335"
                    height="0.979"
                    rx="0.489"
                    transform="translate(7.308 8) rotate(-135)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_436"
                    data-name="Rectangle 436"
                    width="10.335"
                    height="0.979"
                    rx="0.489"
                    transform="translate(0 7.308) rotate(-45)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </a>
          <div v-if="!isMobileView" id="img-area">
            <div id="img-area2" style="opacity: 0"></div>
          </div>
        </div>
      </div>
    </div>
    </div>



  </div>
</template>
<script>
import Acc from "@/libs/acc.js";
const analitics = require("@/libs/analytics");

export default {
  name: "StripeCheckoutPopupOld",
  props: {
    yesUpgrade: Function,
  },

  destroyed() {
    clearInterval(this.myInterval);
  },
  created() {
    this.myInterval = window.setInterval(function () {
      const img2 = document.querySelector("#img-area2");
      if (img2) {
        if (getComputedStyle(img2).opacity == "1") {
          img2.style.opacity = "0";
        } else {
          img2.style.opacity = "1";
        }
      }
    }, 2000);
  },

  data: function () {
    return {
      selectedPlan: "week",
      formErrors: null,
      acc: new Acc(),
      isSubmitting: false,
      isRedirecting: false,
      email: "",
      password: "",
      isMobileView:false,
      myInterval: null,
      is_pwa: false,
      confirmSubscriptionPopup: true,
      isLoading: false,
      userLocation: window.subLocation,
    };
  },
  mounted(){
    this.isMobileView = this.$store.state.isMobileView;
  },
  methods: {
    formatCurrency(amount, currency) {
      const formattedAmount = Number(amount).toLocaleString();
      return currency === "INR" ? `₹${formattedAmount}` : (currency === "IDR" ? `Rp${formattedAmount}` : (currency === "BRL" ? `R$${formattedAmount}` : `$${formattedAmount}`));
    },

    calculateSavings(plan, firstPlan) {
      const multiplier = plan.interval == "year" ? 1 : 12;
      const savings = (((firstPlan.price * 52) - (plan.price * multiplier)) / (firstPlan.price * 52)) * 100;
      return savings.toFixed(0);
    },

    async redirectToUpgrade(){
      window.sendinblue.track('checkout',{ checkoutType:'direct'});
      this.isRedirecting = true;
      if (['IN', 'BR', 'ID'].includes(this.userLocation)) {
        window.location.replace(window.location.origin + "/upgrade-now/" + this.selectedPlan);
      }
      else{
        window.location.replace(window.location.origin + "/upgrade-now");
      }
    },

    async onSubmit() {
            this.isSubmitting = true;
            this.formErrors = null;

            const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            const email = this.$refs.xemail.value;
            const password = this.$refs.xpassword.value;

            if (!emailRegex.test(email)) {
              this.formErrors = [["Invalid email address."]];
              this.isSubmitting = false;
              return;
            }
            
            var resp = await this.acc.registerBeforeCheckout({
              email: email,
              password: password,
            });
            console.log("after register");

            this.formErrors = resp.errors;
            
            if (resp.success) {
                console.log("resp.success", resp.success);
        analitics.sendCustomEvent("sign_up");
                this.$store.state.USER = resp.data.user;
                if(resp.data.user) {
          window.sendinblue.track('signup', { singupType:'upgrade'});
          window.sendinblue.identify(resp.data.user.email, {'STATUS': 'abandoned'});
          document.querySelector('meta[name="uniq_id"]').setAttribute("content", resp.data.user.id);
          window.sendinblue.track('checkout', { checkoutType:'after_signup'});
          if (['IN', 'BR', 'ID'].includes(this.userLocation)) {
            window.location.replace(window.location.origin + "/upgrade-now/" + this.selectedPlan);
          }
          else{
            window.location.replace(window.location.origin + "/upgrade-now");
          }
                }
            }
      else{
        this.isSubmitting = false;
      }
        },
    showLoginPopUp() {
      this.closeAllPopups();
      this.$store.dispatch("popupsToggle", {
        property: "signIn",
        value: true,
      });
    },

    changePopup(property, value) {
      clearInterval(this.myInterval);
      this.$store.dispatch("popupsToggle", { property, value });
    },

    closeAllPopups() {
      this.$store.dispatch("popupsToggle", {
        property: "stripeCheckoutPopup",
        value: false,
      });
    },
  },
  computed: {
    sortedPlans() {
      return window.cartoonize_plans.sort((a, b) => b.order - a.order);
    },
    isLoggedIn() {
                return this.$store.state.USER;
            },
  }
};
</script>

<style scoped>
  .plans{
    list-style: none;
    margin: 12px 0 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
  }

.plans li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 45px;
    border-bottom: 1px solid #333;
}
.plans li:last-child{
  border-bottom: 0px;
}

.plans li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.plans li label{
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.15em;
  padding: 8px 15px 15px 40px;
  margin: 5px auto 0;
  height: 35px;
  z-index: 9;
  cursor: pointer;
  text-align: left;
  -webkit-transition: all 0.25s linear;
}

.plans li:hover label{
    color: #333;
}

.plans li .checkk{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 27px;
  width: 27px;
  top: 10px;
  left: 0px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.plans li:hover .checkk {
  border: 5px solid #333;
}

.plans li .checkk::before {
  display: block;
  position: absolute;
    content: '';
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 3px;
    left: 3px;
  margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.plans input[type=radio]:checked ~ .checkk {
  border: 5px solid #E05A00;
}

.plans input[type=radio]:checked ~ .checkk::before{
  background: #E05A00;
}

.plans input[type=radio]:checked ~ label{
  color: #E05A00;
}

  .text-green {
    color: green;
  }
  .reg-form .form_loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #8080809c;
    z-index: 10000;
  }

  #img-area {
    flex: 0 0 50%;
    max-width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/prem_before.jpg");
    background-size: cover, auto;
    background-position: center;
    transition: opacity 1s ease-in-out;
    z-index: -1;
    border-radius: 0 6px 6px 0;
  }
  #img-area2 {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/prem_after.jpg");
    background-size: cover, auto;
    background-position: center;
    transition: opacity 1s ease-in-out;
    z-index: -1;
    border-radius: 0 6px 6px 0;
  }

  .already-member {
    text-align: left;
    font: normal normal 300 14px/20px Soleil;
    letter-spacing: -0.28px;
    color: #727272;
  }

  .list-plus li {
    margin-left: 30px;
    margin-top: 12px;
    text-align: left;
    font: normal normal 300 16px/20px Soleil;
    letter-spacing: -0.32px;
    color: #727272;
    opacity: 1;
  }

  .list-plus li:before {
    content: url("../../../assets/svg/plus.svg"); /* FontAwesome Unicode */
    font-family: icomoon;
    color: #fa9d1c;
    display: inline-block;
    margin-left: -20px;
    margin-right: 10px;
    width: 1.3em; /* same as padding-left set on li */
  }
  .content {
    flex: 0 0 50%;
    max-width: 50%;
    position: absolute;
    /*left:0;*/
    /*top:0;*/
    width: 100%;
    height: 100%;
  }

  .popup.save_popup.upgrade-popup {
    text-align: unset !important;
    padding: 0px 35px 0px 40px !important;
    max-width: 100%;
    width: 840px;
    height: 605px;
    overflow: visible;
  }
  .popup.save_popup.upgrade-popup.reg-var{
      padding: 0px 35px 0px 36px !important;
  }
  .form_errors {
    text-align: left;
    font: normal normal normal 14px/34px Soleil;
    color: #ff5353;
  }
  .plus-benefits{
      position: absolute;
      bottom: 1.4rem;
      right: 0;
      background: #fff;
      width: 47%;
      padding: 20px;
  }
  .reg-var .list-plus li{
    margin-top: 8px;
    height: 18px;
  }
  .reg-var .list-plus li:before{
    vertical-align: middle;
    margin-top: -2px;
  }
  .agree-links{
    text-align: left;
    font: normal normal normal 12px/16px Soleil;
    letter-spacing: 0px;
    color: #828C9C;
    margin-top: 25px;
  }
  .agree-links a{
    text-decoration: underline;
  }
  .reg-form input{
    box-shadow: 0px 1px 2px #00000014;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    opacity: 1;
    width: 100%;
  }

  .form_loading {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .all-plus-future {
    text-align: left !important;
    font: normal normal 300 16px/20px Soleil !important;
    letter-spacing: -0.32px !important;
    color: #727272 !important;
    opacity: 1;
  }

  .popup.save_popup.upgrade-popup {
    padding: 70px 100px 60px 100px;
  }

  .decoration {
    text-decoration: line-through;
  }

  .upgrade-popup {
    transition: 0.2s;
  }
  .upgrade_mobile{
    width: 100%;
    max-width: 400px;
  }
  .upgrade_mobile .content {
    max-width: 100%!important;
    position: relative;
    margin: auto;
  }
  .upgrade_mobile .popup.save_popup.upgrade-popup.subs_popup {
    width: auto;
    border-radius:6px;
  }
  .upgrade_mobile .plus-benefits{
    position: relative;
    bottom: -6px;
    right: 0;
    background: #fff;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    display: none;
  }
  .upgrade_mobile .plus-benefits p{
    text-align: center;
    text-transform: uppercase;
  }
</style>
