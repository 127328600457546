<template>
  <div
    class="panel_accordion"
    v-bind:class="[{ active: accordActive }, accordAdditionalClass]"
  >
    <div class="panel_accordion_header" v-on:click="accordHandle">
      <h3 class="accordion_title">{{ accordionTitle }}</h3>
      <span class="accordion_arrow" style="padding: 5px" v-tooltip.left="!accordActive ? 'Expand' : 'Collapse'">
        <span class="inch-down-arrow-icon"></span>
      </span>
    </div>
    <div class="panel_accordion_body" :class="accordBodyClass">
      <slot name="panel_accordion_body"></slot>
    </div>
  </div>
</template>

<script>

export default {
    name: 'PanelAccordion',
    props: {
        accordionTitle: String,
        accordActiveClass: Boolean,
        accordAdditionalClass: String,
        accordBodyClass: String
    },
    data: function() {
        return {
            accordActive: this.accordActiveClass || false
        }
    },
    methods: {
        accordHandle: function() {
            this.accordActive = !this.accordActive;
        }
    }
}
</script>
