<template>
	<div class="tool-item-panel with_popup img_panel" :class="sidebarPanelClass">
		<div class="panel-top without_hover">
			<div class="back">
				<h3>Your Images</h3>
			</div>
			<div
				class="help-box"
				@click.stop="popUpPropChange('popupsToggleStatus', true)"
			>
				<img :src="helpIcon" alt />
			</div>
		</div>
		
		<div class="panel-content wo_pad wo_pad_wo_overfl upload_image_panel">
			<div class="panel_row panelrow_w_pb">
				<a href="#" class="blue_btn full_w_btn" @click="$refs.lib_file.click()">
					UPLOAD IMAGE
				</a>
				<input
					style="display: none"
					data-type="1"
					type="file"
					ref="lib_file"
					accept="image/*"
					@change="handleFileChange"
				/>

				<a href="#" class="unspl" @click="changePopup('popupUnsplash', true)" v-tooltip.top="'Search Stock Images'">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="48"
						height="36"
						viewBox="0 0 48 36"
						class="radius-4"
					>
						<rect fill="#3f3f3f" width="48" height="36" rx="2" />
						<g transform="translate(16 11)">
							<path
								fill="#d7dcdd"
								d="M2.39,5A.39.39,0,0,0,2,5.39V16.317a.39.39,0,0,0,.39.39H15.659a.39.39,0,0,0,.39-.39V5.39a.39.39,0,0,0-.39-.39Zm.39.78H15.268v6.473L13.2,10.188a.388.388,0,0,0-.534-.012L9.764,13.081a.39.39,0,0,1-.552-.552l1.252-1.251L6.971,7.468a.39.39,0,0,0-.55-.025L2.78,10.752ZM12.927,6.951a.78.78,0,1,0,.78.78A.78.78,0,0,0,12.927,6.951Zm3.9,0v.78h.39V17.878H4.732v-.39h-.78v.78a.39.39,0,0,0,.39.39H17.61a.39.39,0,0,0,.39-.39V7.341a.39.39,0,0,0-.39-.39Z"
								transform="translate(-2 -5)"
							/>
						</g>
					</svg>
				</a>
			</div>

			<PanelAccordion accordionTitle="Your Images" :accordActiveClass="true">
				<template v-slot:panel_accordion_body>
					<div v-bar>
						<div class="panel_row panel_upload_imgs">
							<div class="upload_image_panel_wrpr" :key="updUploadImagesList">
								<div
									class="loader-wrapper"
									v-if="panelLoading"
									style="width: 100%"
								>
									<div class="lds-ring">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</div>

								<template v-for="(value, index) in changedUploadImgs">
									<UploadImgsItem
										:key="value.id"
										:id="value.id"
										:img="value.image"
										:imgPath="value.path"
										:imgThumb="getThumbURL(value)"
										:popUpImgPropChange="popUpImgPropChange"
										@uploadImgDelete="uploadImgDelete"
									/>
								</template>
							</div>
						</div>
					</div>
				</template>
			</PanelAccordion>
		</div>


		<Tips v-if="getPopupStatus" :text="tipsText">
			<template v-slot:body>
				<div class="tooltip_row_item">
					<p class="simple_light_p">
						Click on an image to add it as a new layer to<br />
						your current project, or to start a new<br />
						project.
					</p>
					<br />
					<p class="simple_light_p">
						You can also drag & drop images from
						<i
							>Image<br />
							Manager</i
						>
						directly unto your project.
					</p>
				</div>
			</template>
		</Tips>
	</div>
</template>

<script>
import PanelAccordion from "@/components/PanelAccordion.vue";
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";
import UploadImgsItem from "@/components/UploadImgsItem.vue";
import Tips from "@/components/Tips.vue";

import Offline from "@/components/Offline.vue";

export default {
	name: "ImagePanel",
	props: {
		sidebarPanelClass: String,
	},
	components: {
		PanelAccordion,
		Range,
		ColorInput,
		UploadImgsItem,
		Tips,
		Offline,
	},
	data: function () {
		return {
			isPopupLayerPropShow: false,
			dispatchUpdateTrigger: false,
			tipsText: "Upload and organize images from your computer.",
			helpIcon: require("@/assets/img/help-icon.svg"),

			
			rangeSliderValue: 50,
			popUpImgProp: false,
			uploadImgs: [],
			dispatchUpdatedLayer: false,
			layerPropConfig: {},
			panelLoading: false,
			updUploadImagesList: false,
			uploadFiles: [],
		};
	},
	methods: {
		uploadImgDelete({ id }) {
			this.uploadImgs = this.uploadImgs.filter((file) => file.id != id);
		},

		getThumbURL(image = {}) {
			if (image.thumb && image.thumb.path) return image.thumb.path;

			return image.thumb || "";
		},

		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},

		deleteSelectedGraphic() {
			this.$lib.fabric.deleteSelectedObject();
			this.$store.state.global.popupLayerPropVisible = false;
			this.isPopupLayerPropShow = false;
			this.$store.dispatch("isUpdateCache");
		},

		setOverlayColor(color, toCache) {
			const parsedRGBA = this.$lib.lib.rgbaToHexAlpha(color);

			this.layerPropConfig.color = !parsedRGBA.alpha ? undefined : color;

			this.setBlendMode(toCache);
		},

		dispatchConfigLayerProperties() {
			this.$store.dispatch("globalLayerPopUpParams", {
				...this.$store.state.globalLayerPopUp.params,
				...this.layerPropConfig,
			});

			this.$store.dispatch("isUpdateCache");
		},

		/* calculateIntensity(intensity) {
			return intensity < 0 ? 50 - Math.abs(intensity) / 2 : 50 + intensity / 2;
		}, */

		setBlendMode(toCache) {
			this.$lib.fabric.setBlendMode(
				!this.layerPropConfig.color ? "clear_filters" : "tint",
				this.layerPropConfig.intensity,
				this.layerPropConfig.color,
				true,
				undefined,
				toCache
			);

			this.dispatchConfigLayerProperties();
		},

		setOverlayIntensity(intensity, toCache) {
			this.layerPropConfig.intensity = intensity;

			this.setBlendMode(toCache);
		},

		async handleFileChange(e) {
			this.$store.dispatch("preloader", {
				message: "Uploading...",
				isShow: true,
			});

			var input = e.target;
			//el
			// input.files[0]
			//TODO upload files here
			const response = await this.$store.dispatch("uploadUserPhoto", {
				file: input.files[0],
				type: input.attributes["data-type"].value,
			});
			console.log("image panel response ", response);

			//console.log(JSON.parse(JSON.stringify(response.image)));
			//var response = await this.getUserLibrary();
			this.uploadImgs.push(response.image); // = response.files;
			this.updUploadImagesList = !this.updUploadImagesList;

			this.$store.dispatch("preloader", {});
			this.$refs.lib_file.value = "";
		},

		popUpPropChange(property, value) {
			this.$store.dispatch("popupsToggleStatus", { property, value });
		},

		popUpImgPropChange() {
			//this.popUpImgProp = !this.popUpImgProp;

			if (this.$store.state.global.popupLayerPropVisible) {
				this.$store.state.global.popupLayerPropVisible = false;
				this.isPopupLayerPropShow = false;
			}
		},

		popupLayerPropShow(isShow) {
			this.isPopupLayerPropShow =
				typeof isShow == "boolean" ? isShow : !this.isPopupLayerPropShow;
			return this.isPopupLayerPropShow;

			/* this.$store.state.global.popupLayerPropVisible = !this.$store.state.global.popupLayerPropVisible;
			return this.$store.state.global.popupLayerPropVisible; */
		},

		closeAllPopups() {
			this.popUpImgProp = false;
			this.$store.state.global.popupLayerPropVisible = false;
			this.isPopupLayerPropShow = false;
		},

		async getUserLibrary(isMounted = false) {
			if(!navigator.onLine){
				return {success: true, files: []};
			}
			let response = await this.$store.dispatch("getUserLibrary");
			if (isMounted) {
				this.$store.state.userUploadFiles = response.files.map(file => {
					file.path = file.path.replace('https://color2.webstaginghub.com', location.origin);

					return file;
				});
			}
			response.files = await this.parseFiles(response.files);

			return response;
		},

		setLayerConfig(layerPropConfig) {
			for (const key in layerPropConfig) {
				if (this.layerPropConfig[key] != layerPropConfig[key]) {
					this.layerPropConfig = layerPropConfig;

					this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
					break;
				}
			}
		},

		async parseFiles(files = []) {
			return files;
			if (files && files.length) {
				const tempFiles = [];

				for (const file of files) {
					const cacheImage = this.uploadFiles.find(
						(uf) => uf.id == file.id && uf.image
					);
					if (cacheImage) {
						tempFiles.push(cacheImage);
					} else if (file.image) {
						tempFiles.push(file);
					} else {
						let img = new Image();
						img.src = file.path;

						tempFiles.push({
							...file,
							image: await new Promise(
								(resolve) => (img.onload = () => resolve(img))
							),
						});
					}
				}

				return tempFiles;
			}

			return files;
		},
	},

	mounted: async function () {
		this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
		this.panelLoading = true;

		const storeFiles = this.$store.state.userUploadFiles;
		if (storeFiles && storeFiles.length) {
			this.uploadFiles = storeFiles;
		}

		var data = await this.getUserLibrary(true);
		this.$data.uploadImgs = data.files;

		this.panelLoading = false;
	},

	computed: {


		changedUploadImgs() {
			return (this.uploadImgs || []).reverse();
		},

		getPopupStatus() {
			return this.$store.state.global.popupStatus.value;
		},

		popupLayerPropVisible() {
			return this.$store.state.global.popupLayerPropVisible;
		},

		getGlobalLayerParams() {
			return {
				...this.$store.state.globalLayerPopUp.default,
				...this.$store.state.globalLayerPopUp.params,
			};
		},

		userUploadFiles() {
			return this.$store.state.userUploadFiles;
		},
	},

	watch: {
		async userUploadFiles(uploadFiles = []) {
			if (!this.$store.state.popups.popupUnsplash) return;

			this.uploadImgs = await this.parseFiles(uploadFiles);
		},

		changedUploadImgs(images) {
			this.$store.state.userUploadFiles = images;
		},

		popupLayerPropVisible(isVisible) {
			if (!isVisible) {
				this.isPopupLayerPropShow = false;
			}
		},

		getGlobalLayerParams(layerPropConfig) {
			this.setLayerConfig(layerPropConfig);
		},
	},

	created() {
		this.setLayerConfig({
			...this.$store.state.globalLayerPopUp.default,
			...this.$store.state.globalLayerPopUp.params,
		});
	},
};
</script>

<style scoped>
.panelrow_w_pb {
	display: flex;
	flex-direction: row;
}

.unspl {
	margin-left: 10px;
}

.unspl:hover rect {
	fill: #383838;
}
</style>
