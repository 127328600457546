export default class {
    constructor(promo_code = {}, plans = {}) {
        this.promo_code = promo_code;
        this.plans = plans;
    }

    replacePlanDescription(plan) {
        if (plan.interval == "month") {
            if(window.isMobileView) {
                return plan.description + " at $" + plan.price;
            } else
            return plan.description;
        } else if (plan.interval == "year" && this.promo_code && this.promo_code.data.plan_id && this.promo_code.data.plan_id == plan.stripe_id) {
            if (plan.description.includes("$" + plan.price)) {
                const newPrice = Number(this.getChangedPrice(true) || 0);
                if (newPrice == 0) return plan.description;

                const monthDiscount = Number(plan.billed_mo) - newPrice;
                const price = (Number(plan.price) - monthDiscount * 12).toFixed(2);

                return `${plan.description}`.replace(
                    new RegExp(`${plan.price}`),
                    `${price}`
                );
            } else {
                return plan.description;
            }
        } else {
            return plan.description;
        }
    }

    getChangedPrice(isFull = false) {
        if (this.promo_code.data && this.promo_code.data.plan_id) {
            const plan = this.plans.find(
                (pl) => pl.stripe_id == this.promo_code.data.plan_id
            );

            if (!plan) return "";
            const promo = this.promo_code.data;
            const billed_mo = Number(plan.billed_mo);

            const amountPercent = billed_mo - billed_mo * (promo.percent_off / 100);
            const amountFull = billed_mo - promo.amount_off;
            const price = promo.percent_off ? amountPercent : amountFull;

            return isFull ? price : price.toFixed(2);
        } else {
            if (this.plans) {
                const plan = this.plans.find(pl => pl.current);

                if (!plan) return '';
                return isFull ? plan.billed_mo : plan.plan_price;
            } else {
                return '';
            }
        }
    }

    getPromoSavingPrice() {
        if (this.promo_code.data && this.promo_code.data.plan_id) {
            const plan = this.plans.find(
                (pl) => pl.stripe_id == this.promo_code.data.plan_id
            );

            if (!plan) return "";
            const promo = this.promo_code.data;
            const isYear = plan.interval == "year";
            const realPrice = isYear
                ? Number(plan.billed_mo) * 12
                : Number(plan.billed_mo);

            const billed_mo = Number(plan.billed_mo);
            const duration = this.promo_code.data.duration;


            const amountPercent = billed_mo - billed_mo * (promo.percent_off / 100);
            const amountFull = billed_mo - promo.amount_off;
            const price = promo.percent_off ? amountPercent : amountFull;
            const procentage = parseInt(100 - ((price) / billed_mo) * 100);
            let result = '';

            switch (duration) {
                case 'once': {
                    if (price <= 0) {
                        result = `First Month Free - Saving $${billed_mo} (${procentage}%)`;
                    } else {
                        result = `First Month - Saving $${(billed_mo - price).toFixed(2)} (${procentage}%)`;
                    }
                    break;
                }

                case 'forever': {
                    if (price <= 0) {
                        result = `Forever Free - Saving $${billed_mo.toFixed(2)}/mo (${procentage}%)`;
                    } else {
                        result = `Saving $${(billed_mo - price).toFixed(2)}/mo (${procentage}%) - Forever`;
                    }

                    break;
                }

                default: {
                    if (price <= 0) {
                        result = `First ${duration} Months Free - Saving $${billed_mo}/mo (${procentage}%)`;
                    } else {
                        result = `First ${duration} Months - Saving $${(billed_mo - price).toFixed(2)}/mo (${procentage}%)`;
                    }
                }
            }

            return result;
        } else {
            return "";
        }
    }

    currencySymbol(currency){
        switch (currency) {
            case 'IDR':
                return 'Rp'
            case 'INR':
                return '₹'
            case 'BRL':
                return 'R$'
            default:
                return '$';
        }
    }

    parsePlanPrice(plan = {}, isFull = false) {
        const promo = this.promo_code.data;
        const isYear = plan.interval == "year";
        const realPrice = isYear
            ? Number(plan.billed_mo) * 12
            : Number(plan.billed_mo);
        const cSymbol = this.currencySymbol(plan.currency)

        if (
            promo &&
            promo.plan_id == plan.stripe_id &&
            (promo.percent_off || promo.amount_off)
        ) {
            const billed_mo = Number(plan.billed_mo);
            const duration = this.promo_code.data.duration;

            const amountPercent = billed_mo - billed_mo * (promo.percent_off / 100);
            const amountFull = billed_mo - promo.amount_off;
            const price = promo.percent_off ? amountPercent : amountFull;
            const procentage = parseInt(100 - ((price) / billed_mo) * 100);
            let result = '';

            switch (duration) {
                case 'once': {
                    if (price <= 0) {
                        result = `${cSymbol}${billed_mo}/mo`;
                    } else {
                        result = `${cSymbol}${price.toFixed(2)}/mo`;
                    }
                    break;
                }

                case 'forever': {
                    if (price <= 0) {
                        result = `FREE`;
                    } else {
                        result = `${cSymbol}${price.toFixed(2)}/mo`;
                    }

                    break;
                }

                default: {
                    if (price <= 0) {
                        result = `${cSymbol}${billed_mo}/mo`;
                    } else {
                        result = `${cSymbol}${price.toFixed(2)}/mo`;
                    }
                }
            }

            return result;
        } else {
            return cSymbol + plan.billed_mo;
        }
    }
}
