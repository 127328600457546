<template>
	<!-- <div class="announcements_outer" >
		<div class="announcement_item" v-for="(item, index) in announcements">
			<div class="announcement_image">
				<img :src="item.image">
			</div>
			
			<div class="announcement_bottom">
				<div class="announcement_title_outer">
					<div class="announcement_is_new" v-if="item.is_new">new</div>
					<div class="announcement_title">
						{{item.title}}
					</div>
				</div>
				<div class="announcement_description">
					{{item.description}}
				</div>
				<div class="announcement_learn_more" v-if="item.link">
					<a :href=item.link target="_blank">Learn more</a>
				</div>
			</div>
		</div>
	</div> -->

	<div class="news-popup" :class="{ open: isOpen, close: !isOpen }">
		<a class="news-popup__close-btn" @click="closeNewsPopup">
			<svg xmlns="http://www.w3.org/2000/svg" width="8.001" height="8" viewBox="0 0 8.001 8">
				<g transform="translate(-114 -546.001)">
					<rect fill="#8c8c8c"  width="10.335" height="0.979" rx="0.489" transform="translate(121.309 554.001) rotate(-135)"/>
					<rect fill="#8c8c8c"  width="10.335" height="0.979" rx="0.489" transform="translate(114 553.309) rotate(-45)"/>
				</g>
			</svg>
		</a>

		<div class="news-popup__header">
			<div class="slider1">
				<div class="slider1__item" v-for="(item, index) in announcements" :key="index">
					<img v-if="item.type=='image'" class="slider1__item-img" :src="item.image">

					<video @click="toggleVideo" style="height: 100%" v-if="item.type=='video'"
						preload="auto" no-controls autoplay loop playsinline muted>
						<source :src="item.image" type="video/mp4">
						Sorry, your browser doesn't support embedded videos.
					</video>
				</div>
			</div>
		</div>

		<div class="news-popup__body">
			<div class="slider2">
				<div class="slider2__item" v-for="(item, index) in announcements" :key="index">
					<div class="slider2__item-content">
						<div class="slider2__item-header">
							<div class="slider2__item-cat" v-if="item.is_new">New</div>
							<div class="slider2__item-title">{{item.title}}</div>
						</div>
						<p class="slider2__item-description">
							{{item.description}}
						</p>
						<a class="slider2__item-link-more" :href="item.link" target="_blank" v-if="item.link" >Learn more</a>
					</div>
				</div>

			</div>
		</div>


	</div>

</template>

<script>
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = require('@/libs/slick.js');

export default {
	components: {
	},
	name: "Announcement",
	props: { },

	data: function () {
		return {
			isOpen: true,
			announcements: window.announcements,
			sliders: null,

			sliderOneOpts: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
				asNavFor: '.slider2',
				autoplay: true,
				autoplaySpeed: 7000,
				// speed: 500,
			},
			sliderTwoOpts: {
				slidesToShow: 1,
				slidesToScroll: 1,
				asNavFor: '.slider1',
				dots: false,
				arrows: false,
				focusOnSelect: true,
				// autoplay: true,
				// autoplaySpeed: 4000,
				// speed: 500,
				// fade: true,
				// cssEase: 'linear'
			}
		};
	},

	watch: {
		announcements() {
			if(!this.sliders) return
			this.sliders.sliderOne.unslick();
			this.sliders.sliderTwo.unslick();

			this.$nextTick(()=> {
				this.sliders.sliderOne.slick(this.sliderOneOpts);
				this.sliders.sliderTwo.slick(this.sliderOneOpts);
			})
		}
	},

	computed: {
	 
	},

	methods: {
		toggleVideo(e){
			let video = (e.target);
			if (video.paused) 
				video.play(); 
			else 
				video.pause();
		},
		test(e){
		
		},

		closeNewsPopup() {
			console.log('CLOSE NEWS POPUP');
			document.cookie = `viewed_announcements=${JSON.stringify(announcements.map((item)=>{	return item.id }))}; path=/`;
			this.isOpen = false
		}
		
	},
	// Attach Slick
	async mounted() {

		$.fn.slick = function() {
			var _ = this,
				opt = arguments[0],
				args = Array.prototype.slice.call(arguments, 1),
				l = _.length,
				i,
				ret;
			for (i = 0; i < l; i++) {
				if (typeof opt == 'object' || typeof opt == 'undefined')
					_[i].slick = new Slick(_[i], opt);
				else
					ret = _[i].slick[opt].apply(_[i].slick, args);
				if (typeof ret != 'undefined') return ret;
			}
			return _;
		};

		const sliderOne = $('.slider1').slick(
			this.sliderOneOpts
		);
		const sliderTwo = $('.slider2').slick(
			this.sliderTwoOpts
		);

		this.sliders = { sliderOne, sliderTwo }
	},
	// Detach Slick
	beforeDestroy() {
		if(!this.sliders) return
		this.sliders.sliderOne.unslick()
		this.sliders.sliderTwo.unslick()
	},
};
</script>


<style>
	.news-popup {
		position: fixed;
		right: 24px;
		bottom: 60px;
		width: 280px;
		height: 350px;
		border-radius: 4px;
	}

	.news-popup__close-btn {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		z-index: 10;
		background: #fff;
		opacity: 0.5;
	}

	.news-popup__header {
		min-height: 190px;
		border-radius: 4px 4px 0 0;
		border-bottom: 1px solid #e6e8e9;
	}

	.news-popup__header .slick-dotted.slick-slider {
		margin: 0;
	}

	.news-popup__body {
		background: white;
		border-radius: 0 0 4px 4px;
		height: calc(100% - 190px);
	}

	.slider1__item {
		position: relative;
		height: 190px;
	}

	.slider1__item-img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.slider1 .slick-track {
		height: 190px;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		border-radius: 4px 4px 0 0;
	}

	.slider2 {
		padding: 18px 24px 28px 24px;
		height: 100%;
	}

	.slider2 .slick-list.draggable,
	.slider2 .slick-track {
		height: 100%;
	}

	.slider2__item-content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.slider2__item-header {
		display: flex;
		align-items: center;
		margin: 0 0 15px 0;
	}
	.hover_dropdown_list.active {
		opacity: 1;
		visibility: visible;
	}
	.slider2__item-cat {
		padding: 3px 10px;
		margin: -1px 6px 0 0;
		font-size: 10px;
		font-weight: 400;
		letter-spacing: 0.4px;
		color: #ffffff;
		text-transform: uppercase;
		background: #FF5353;
		border-radius: 40px;	
	}

	.slider2__item-title {
		font-size: 18px;
		font-weight: 600;
		color: #1d1d1d;
		letter-spacing: -0.72px;
	}

	.slider2__item-description {
		font-size: 14px;
		color: #8c8c8c;
		line-height: 18px;
		letter-spacing: -0.56px;
	}

	.slider2__item-link-more {
		margin-top: auto;
		font-weight: 600;
		font-size: 14px;
		color: #249ACF;
	}
	.slider2__item-link-more:hover {
		color:#1D7EA8;
	}

	.slider1 .slick-dots {
		bottom: 5px;
	}

	.slider1 .slick-dots:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		border-radius: 40px;
		background: #fff;
		opacity: 0.5;
	}

	.slider1 .slick-dots li button:before {	
		content: "";
		background: #000;
		opacity: 0.2;
		border-radius: 50%;
		width: 5px;
		height: 5px;
		line-height: 100%;
		left: 50%;	
		transform: translateX(-50%);	
		color: transparent;
	}

	.slider1 .slick-dots li.slick-active button:before {
		opacity: 0.5;
		color: transparent;
		background: #000;
	}

	.slider1 .slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 0.5;
	}

	.slider1 .slick-dots li {
		width: 10px;
		z-index: 9;
	}

	.slider1 .slick-dots {
		bottom: 10px;
		display: inline-flex;
		align-items: center;
		width: unset;
		padding: 4px 2px;
		margin: 0;
		list-style: none;
		text-align: center;
		justify-content: center;
		border-radius: 40px;
		left: 50%;
		transform: translateX(-50%);
	}

	.slider1 .slick-dots li {
		position: relative;
		width: 10px;
		height: 5px;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}

	.slider1 .slick-dots li button {
		width: 5px;
		height: 5px;
		padding: 0px;
	}

	.news-popup {
		transform: translateY(200px);
		opacity: 0;    
		pointer-events: none;
	}
	.news-popup.open {
		animation: open-animation .25s ease-in forwards;
		pointer-events: all;
	}
	.news-popup.close {
		animation: close-animation .25s ease-in forwards;
	}

	@keyframes open-animation {
		from {
			opacity: 0;
			transform: translateY(200px);
		}
		to {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	@keyframes close-animation {
		from {
			opacity: 1;
			transform: translateY(0px);
		}
		to {
			opacity: 0;
			transform: translateY(200px);
		}
	}

	/* .announcements_outer{
		width: 280px;
		height: 350px;
		position: fixed;
		right: 24px;
		bottom: 60px;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 12px #00000029;
		border-radius: 4px;
		overflow: hidden;
		
	}
	
	.announcement_image{
		width: 280px;
		height: 192px;	
	}
	.announcement_image{
		max-width: 100%;
		max-height: 100%;
	}
	.announcement_title_outer{
		display: flex;
		margin-top: 10px;
	}
	.announcement_title{
		letter-spacing: -0.72px;
		color: #1D1D1D;
		font-weight: 600;
		font-size: 18px;
	}
	
	.announcement_bottom{
		margin-left: 24px;
		margin-right: 24px;
	}
	
	.announcement_is_new{
		width: 23px;
		height: 12px;
		background: #ff5353;
	}
	.announcement_content{
		
	}
	
	.announcement_learn_more{
		color: #249ACF;
		font-size: 14px;
		letter-spacing: -0.56px;
	} */

	.slick-slide > div {
		height: 100%;
		display: flex;
	}

	.slider2__item-header {
		margin-bottom: 10px;
	}
</style>
