<template>
  <div :class="[{'tool-item-panel': !isMobileView}, sidebarPanelClass]">
    <div v-if="!isMobileView" class="panel-top without_hover">
      <div class="back">
        <h3>masks</h3>
      </div>
    </div>
    <div :class="{'panel-content': !isMobileView, 'wo_pad': !isMobileView, 'wo_pad_wo_overfl': !isMobileView}">
      <div v-if="!isMobileView" v-bar>
        <div class="panel_row effects_items_wrpr effects_categories_wrpr">
          <Favorites
            v-if="favoritesList.length"
            :favoritesCount="favoritesList.length"
            :favoritesOpen="favoritesOpen"
          />

          <a class="category_card" @click="toolLinkClick('Custom Text')">
            <div class="img_wrpr">
              <img :src="textImage" alt />
            </div>
            <div class="text_wrpr">
              <span>Custom Text</span>
            </div>
          </a>

          <template v-for="cardItem in panels">
            <a
              href="#"
              class="category_card"
              @click="toolLinkClick(cardItem.title, cardItem.id)"
              v-bind:key="cardItem.id"
            >
              <div class="img_wrpr">
                <img :src="cardItem.url" alt />
                <SimpleSVG :src="cardItem.url" />
              </div>
              <div class="text_wrpr">
                <span>{{ cardItem.title }}</span>
              </div>
            </a>
          </template>
          <AdLeftPanel v-if="isLeftAd" margin="20px 0px" marginLeft="0" />
        </div>
      </div>
      <transition name="panel-slide">
        <div v-if="isMobileView && showPanel" class="panel_row_mobile">
          <a v-if="!isMobileView" class="category_card_mobile" @click="toolLinkClick('Custom Text')">
            <div class="img_wrpr">
              <img class="cardImg" :src="textImage" alt />
            </div>
            <div class="text_wrpr">
              <span>Custom Text</span>
            </div>
          </a>

          <template v-for="cardItem in panels">
            <a
                href="#"
                class="category_card_mobile"
                @click.once="toolLinkClick(cardItem.title, cardItem.id)"
                v-bind:key="cardItem.id"
            >
              <div class="img_wrpr">
                <img class="cardImg" :src="cardItem.url_m || cardItem.url" alt />
                <SimpleSVG :src="cardItem.url_m || cardItem.url" />
              </div>
              <div class="text_wrpr">
                <span>{{ cardItem.title }}</span>
              </div>
            </a>
          </template>
        </div>
      </transition>

    </div>

    <transition name="panel-slide">
      <template v-for="panel in panels">
        <ThumbsPanel
          :appliedPresetId="appliedPresetId"
          v-bind:key="panel.id"
          v-if="panelOpened === panel.title"
          :pannelInfo="pannelInfo"
          :panelName="panel.title"
          :panelItems="panel.panelItems"
          :filterPreview="maskPreview"
          :applyFilter="applyMask"
          :sliderUpdate="sliderUpdate"
          :configOpacity="popMaskConfig.opacity"
          :panelPropChange="panelPropChange"
          @panelView="panelView"
          :popUpPropChange="popUpPropChange"
          :panelClass="panelClass"
          :panelLoading="panelLoading"
          :favoritesPropChange="favoritesPropChange"
          :panelId="panel.id"
          :popUpVisible="popUpVisible"
          @loadSecondPageIfBigScreen="loadSecondPageIfBigScreen"
          @contentUpload="contentUpload"
          :isLoading="pageControl.isLoading"
          :sliderDefaultValue="defaultPopMaskConfig.opacity"
          :sliderVal="defaultPopMaskConfig.opacity"
        >
          <template v-slot:popup>
            <PopupMasks
              :popUpVisible="popUpVisible"
              :popUpLabel="popUpLabel"
              :popUpPropChange="popUpPropChange"
              :popMaskConfig="popMaskConfig"
              @setupMaskParams="setConfigMask"
              @applyMask="applyMask"
              :freezePopMaskConfig="freezePopMaskConfig"
            />
          </template>
          <!-- <template v-slot:with-mask>
                                <div class="effect_category_item_mask">
                                  <MaskCircle />
                                </div>
          </template>-->
        </ThumbsPanel>
      </template>
      <MaskTextPanel
        v-if="panelOpened === 'Custom Text'"
        @loadFontList="$emit('loadFontList')"
        :panelPropChange="panelPropChange"
        :panelClass="panelClass"
      />
    </transition>

    <transition name="panel-slide">
      <ThumbsPanel
        :appliedPresetId="appliedPresetId"
        v-if="panelOpened === 'Favorites'"
        panelName="Favorites"
        :pannelInfo="pannelInfo"
        :panelItems="favoritesList"
        :panelPropChange="panelPropChange"
        :popUpPropChange="popUpPropChange"
        :panelClass="panelClass"
        :panelLoading="panelLoading"
        :favoritesPropChange="favoritesPropChange"
        :filterPreview="maskPreview"
        :applyFilter="applyMask"
        :sliderUpdate="sliderUpdate"
        :sliderDefaultValue="defaultPopMaskConfig.opacity"
      >
        <template v-slot:popup>
          <PopupMasks
            :popUpVisible="popUpVisible"
            :popUpLabel="popUpLabel"
            :popUpPropChange="popUpPropChange"
            :popMaskConfig="popMaskConfig"
            @setupMaskParams="setConfigMask"
            @applyMask="applyMask"
            :freezePopMaskConfig="freezePopMaskConfig"
          />
        </template>
      </ThumbsPanel>
    </transition>
  </div>
</template>


<script>
import { SimpleSVG } from "vue-simple-svg";
import ThumbsPanel from "@/components/ThumbsPanel.vue";
import PopupMasks from "@/components/popupPanel/PopupMasks.vue";
import Favorites from "@/components/Favorites.vue";
import MaskTextPanel from "./MaskTextPanel.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";
// import MaskCircle from "@/assets/img/mask_circle.svg?inline";
const analitics = require('../../../libs/analytics');
import LoaderMixin from '@/mixin/LoaderMixin';
import { EventBus } from '@/main.js';

export default {
  name: "MasksPanel",
  props: {
    sidebarPanelClass: String,
  },
  mixins: [LoaderMixin],
  components: {
    MaskTextPanel,
    SimpleSVG,
    ThumbsPanel,
    Favorites,
    PopupMasks,
    AdLeftPanel,
    // MaskCircle
  },
  data: function () {
    const popMaskConfig = {
      opacity: 80,
      color: "#FFFFFF",
      alpha: 0.8,
      blur: 30,
      x: 0,
      y: 0,
      size: 50
    };

    return {
      isMobileView: false,
      showPanel: true,
      appliedPresetId: false,
      girlImg: require("@/assets/img/girl_img.png"),
      textImage: require("@/assets/images/mwtjm1zU.png"),
      pannelInfo: {
        pannelTitle: "",
        pannelList: [
          { icon: "slider_icon", text: "Adjust the mask intensity" },
          { icon: "icon_heart", text: "Add selected mask to Favorites" },
          {
            icon: "icon_tune",
            text: "Access advanced settings to further fine-tune the mask",
          },
        ],
        pannelFooter: `<span data-v-e02b15b2="" class="inch-icon inch-plus-membership-icon"></span>Indicates the mask is a Pro feature. <a href='#get_plus'>Get Pro</a>`,
      },

      panelNameFavorite: "masks",
      popMaskConfig: JSON.parse(JSON.stringify(popMaskConfig)),
      defaultPopMaskConfig: popMaskConfig,
      favoritesCount: 0,
      favoritesList: [],
      favoritesPanelVisible: false,
      popUpVisible: false,
      popUpLabel: "",
      panelLoading: true,
      panelOpened: "",
      panelClass: "",
      panels: [],
      selectedPresetId: null,
      selectedPreview: false,
      pageControl: {
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      },
      selectedPanelId: null,
      freezePopMaskConfig: {},
    };
  },

  async mounted() {
    this.isMobileView = this.$store.state.isMobileView;
    // await this.$store.dispatch("fontsList", {});
    // this.$emit('loadFontList');
    this.favoritesList = this.$store.state.favorites.masks;

    /* if (this.$store.state.USER.email) {
              try {
                this.$store.state.favorites.masks = await fetch(
                  `/api/masks/favorites`
                ).then(res => res.json());
              } catch (err) {
                console.error(err);
              }
            } */
    this.panels = await fetch(
      `${this.$store.state.prefixURL}/api/masks`
    ).then((res) => res.json());
    /* this.favoritesList = await fetch("/api/masks/favorites").then(res =>
                      res.json()
                    ); */

    this.favoritesList = this.favoritesList.map((fav) => {
      fav.favoritesIconIsChecked = true;
      return fav;
    });
    // this.favoritesList = this.$store.state.favorites.masks;
  },

  beforeDestroy() {
    this.discardChanges();
    this.$lib.watermark.remove();
  },

  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyMask();
        if(this.$store.state.saveOnMobile){
          EventBus.$emit("save-on-mobile");
        }
      } catch(err) {}
      this.$store.state._callbackSave += 1;
    }
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
  },

  methods: {

	async loadSecondPageIfBigScreen(){
		if(!this.pageControl.isLoading && this.pageControl.currentPage < this.pageControl.totalPages){
			this.contentUpload(false, true);

		}
	},

    async contentUpload(event, skip_scroll_check=false) {
      let scrolled = false;
      if(event){
        if (this.isMobileView) {
          scrolled = (event.target.scrollLeft*window.devicePixelRatio) >=
              ((event.target.scrollWidth - event.target.clientWidth));
        } else {
          scrolled = (event.target.scrollTop*window.devicePixelRatio) >=
              event.target.scrollHeight - event.target.clientHeight;
        }
      }

      if (
        (scrolled &&
        !this.pageControl.isLoading &&
        this.pageControl.currentPage < this.pageControl.totalPages) || skip_scroll_check
      ) {
        const panel = this.panels.find((p) => p.id == this.selectedPanelId);
        if (!panel) return;

        this.pageControl.isLoading = true;

        const items = await this.getMasksByParams(
          this.pageControl.currentPage + 1
        );

        await this.checkAfterLoad([...panel.panelItems, ...items]);
        this.pageControl.isLoading = false;
      }
    },

    initDragMask() {
      this.$lib.app.dragCallbacks.dragMask = async (data = {}) => {
        let ratio = this.isMobileView ? 4 : 2;
        this.selectedEffect.uniforms = {
          ...this.selectedEffect.uniforms,
          x: data.newCoords.x - data.bounding.x - (this.$lib.app.fabricCanvas.width / ratio),
          y: data.newCoords.y - data.bounding.y - (this.$lib.app.fabricCanvas.height / ratio),
        };

        this.setupMask();
        // this.$lib.lib.debounce(this.setupMask, 1);
      };
    },

    async setConfigMask(config) {
      delete config.x;
      delete config.y;

      this.popMaskConfig = {
        ...this.popMaskConfig,
        ...config,
      };

      if (this.selectedEffect) {
        this.selectedEffect.uniforms = {
          ...(this.selectedEffect.uniforms || {}),
          ...config,
          alpha: config.opacity,
          angle: config.rotation,
          blur: config.blur,
        };

        await this.setupMask();
      }
    },

    async discardChanges() {
      if (this.$store.state.premiumStatus) {
        this.$store.commit("changePremiumStatus", false);
      }

      this.$lib.watermark.remove(true);

      if (this.selectedPreview) {
        this.selectedPreview = false;
        this.$lib.app.stage.filters = [];
        await this.$lib._cache.renderSaveByTimestamp();
        this.$store.dispatch("fabricSlide", false);
        this.$lib.app.dragCallbacks.dragMask = undefined;

        this.$store.dispatch("isUpdateCache");
      }
    },

    setupPreviewPopMaskConfig(data) {
      this.popMaskConfig = JSON.parse(
        JSON.stringify({
          ...this.defaultPopMaskConfig,
          mode: data.params.mode,
          color: data.params.color || "#ffffff",
          blur:
            typeof Number(data.params.blur) == "number"
              ? Number(data.params.blur)
              : this.defaultPopMaskConfig.blur,
          rotation:
            typeof Number(data.params.rotation) == "number"
              ? Number(data.params.rotation)
              : this.defaultPopMaskConfig.rotation,
          size:
            typeof Number(data.params.size) == "number"
              ? Number(data.params.size)
              : this.defaultPopMaskConfig.size,
        })
      );

      this.freezePopMaskConfig = JSON.parse(JSON.stringify(this.popMaskConfig));
    },

    async maskPreview(data) {
      if(this.appliedPresetId == data.id) {
        this.appliedPresetId = null;
        this.discardChanges();

        return;
      }

      this.$store.dispatch("preloader", { message: "Loading...", isShow: true });

      await this.discardChanges();
      this.setupPreviewPopMaskConfig(data);
      this.$store.dispatch("checkPremiumStatus", {
        panels: this.panels,
        favorites: this.favoritesList,
        presetId: data.id,
      });

      this.$store.dispatch("fabricSlide", true);
      this.selectedPreview = true;
      this.setSelectedEffect(data);
      if (data && data.params && data.params && data.params.mode != "pattern") {
        this.initDragMask();
      }

      this.$lib.app.stage.filters = [new this.$lib.PIXI.filters.FXAAFilter()];
      await this.setupMask();

      this.$store.dispatch("preloader", { message: "Loading...", isShow: false });
      this.appliedPresetId = data.id;
    },

    async setupMask(params = {}) {
      if (!this.selectedEffect.id && this.selectedEffect.id !== 0) return;

      let preset = this.panels
        .reduce((arr, panel) => {
          if (panel.panelItems) {
            arr = [...arr, ...panel.panelItems];
          }

          return arr;
        }, [])
        .find((p) => p.id == this.selectedEffect.id);

      if (!preset) {
        preset = this.favoritesList.find((f) => f.id == this.selectedEffect.id);
        if (!preset) return;
      }

      if (preset.isPremium && params.isApply) {
        const isHavePremium = await this.$store.dispatch(
          "isPremiumCurrentUser"
        );
        if (!isHavePremium) {
          // if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
          return;
        }
      }

      //const presetItem = this.getPresetById(this.selectedEffect.id) || {};
      let STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
        preset.url.replace('https://color2.webstaginghub.com', location.origin)
      );

      if (!params.updateId) {
        params.updateId = this.$lib.CONST.MAP_CHANGES.BG_MASK_EFFECT;
      }

      if (!STexture) return;
      this.$lib.watermark.renderPremium(preset.isPremium && !this.$store.state.isPremiumAccount, undefined, [false, true]);

      await this.$lib[this.selectedEffect.effectName].setup({ STexture,
          originalSprite: this.$lib.app.stage.children[0],
        }, JSON.parse(JSON.stringify({ ...this.selectedEffect.uniforms, isMask: true })),
        params
      );

      return true;
    },

    getPresetById(id) {
      if (!id) return null;

      return this.panels
        .reduce((arr, panel) => {
          if (panel && panel.panelItems && panel.panelItems.length) {
            arr = [...arr, ...panel.panelItems];
          }

          return arr;
        }, []).find((preset) => preset.id == id);
    },

    setSelectedEffect(data) {
      this.selectedEffect = {
        id: data.id,
        effectName: data.effect || "Mask",
        params: data.params,
        uniforms: {
          ...this.popMaskConfig,
          ...data.params,
        },
      };
    },

    async sliderUpdate(opacity) {
      if (this.selectedEffect) {
        this.selectedEffect.uniforms = {
          ...(this.selectedEffect.uniforms || {}),
          opacity: opacity,
        };

        this.popMaskConfig.opacity = opacity;
        await this.setupMask();
      }
    },

    async applyMask(data, slider=false) {
      if (!this.appliedPresetId) return;
      if (!this.selectedEffect) {
        return data;
      }

      await this._preloader(true);
      const res = await this.setupMask({ isApply: true });
      if (!res) {
        await this._preloader(false);
        return;
      }
      if(slider) {
        let height =this.$lib.app.fabricCanvas.height;
        let nowHeight, zoom;

        nowHeight = height - 31;
        zoom = nowHeight/this.$lib.app.stage.height;
        $(".centered-content").css({'transform': `scale(${zoom})`});
        $(".drag-wrapper").css({'height': 'calc(100vh - 280px)'})
        this.$lib.app.setFabricZoom(zoom);
      }
      this.$store.dispatch("changesApplied");
      this.$store.dispatch("isUpdateCache");
      this.$store.dispatch("fabricSlide", false);
      await this._preloader(false);

      this.panelOpened = "";
      this.panelClass = "";
      this.showPanel = true;
    },

    panelView(val) {
      this.showPanel = val;
    },

    async favoritesPropChange(itemId, panelId) {
      const { favoritesList, panels } = await this.$store.dispatch("favoritesPropChange", {
          panelOpened: this.panelOpened,
          panels: this.panels,
          favoritesList: this.favoritesList,
          panelName: this.panelNameFavorite,
          itemId,
          panelId,
        }
      );

      this.favoritesList = favoritesList;
      this.panels = panels;
    },

    popUpPropChange(label) {
      if (
        this.popUpVisible !== false &&
        this.popUpLabel !== label &&
        label !== ""
      ) {
        return (this.popUpLabel = label);
      } else {
        return (
          (this.popUpVisible = !this.popUpVisible), (this.popUpLabel = label)
        );
      }
    },

    async getMasksByParams(page = 1) {
      this.pageControl.currentPage = page;
      const masks =
        (await fetch(
          `${this.$store.state.prefixURL}/api/masks/${this.selectedPanelId}?page=${page}`
        ).then((res) => res.json())) || [];
      this.pageControl.totalPages = masks.total_pages;

//      setTimeout(() => {
//        masks.data.forEach(mask => fetch(mask.url));
//      }, 1000);

      return masks.data;
    },

    async toolLinkClick(panelName, panelId) {
      this.selectedPanelId = panelId;
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      const panelIndex = this.panels.findIndex((panel) => panel.id === panelId);
      if (panelIndex == -1 && panelName !== "Custom Text") return;

      if (this.panelOpened === panelName) {
        this.panelOpened = "";
        this.panelClass = "";
        this.isLoading = true;
      } else {
        analitics.sendEvent(panelName, analitics.categ.mask);

        this.panelOpened = panelName;
        this.panelClass = "active";

        if(panelName == "Custom Text") return;
        this.panelLoading = true;

        let items = await this.getMasksByParams();
        await this.checkAfterLoad(items);
      }
      this.showPanel = false;
    },

    async checkAfterLoad(items = []) {
      const panelIndex = this.panels.findIndex(
        (panel) => panel.id === this.selectedPanelId
      );
      if (panelIndex == -1) return;

      items = items.map((item) => {
        if (this.favoritesList.find((fi) => fi.id == item.id)) {
          item.favoritesIconIsChecked = true;
        }

        return item;
      });
      //this.panels[panelIndex].panelItems = items;

      await new Promise((resolve) => {
        this.$lib.TexturesHelper.loadingTextures(
          undefined,
          { paths: items.map((i) => i.thumb), isServer: true },
          async (err, res) => {
            if (err) {
              return this.toolLinkClick(panelName, this.selectedPanelId);
            }

            await this.generatePreviewMasks(res, this.selectedPanelId, items);
            this.panelLoading = false;

            resolve();
          }
        );
      });
    },

    async generatePreviewMasks(resources = {}, panelId, items = []) {
      const stageBase64 = await this.$lib._cache.getLastThumbBase64(); // await this.$lib.app.extractStage();
      const cachedResource = await this.$lib.app.addToLoader(
        `${Date.now()}-thumb`,
        stageBase64
      );

      const panelIndex = this.panels.findIndex((panel) => panel.id == panelId);
      for (let i = 0; i < items.length; i++) {
        if (items[i] && !items[i].genImage) {
          let preset = items[i];
          let STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
            preset.thumb
          );

          if (!STexture) return;
          const result = await this.$lib.Mask.previewByParams(
            cachedResource.texture.clone(),
            STexture, {
              ...JSON.parse(JSON.stringify(preset.params || {})),
              opacity: this.defaultPopMaskConfig.opacity,
              x: this.defaultPopMaskConfig.x,
              y: this.defaultPopMaskConfig.y,
          });

          //this.panels[panelIndex].panelItems[i].url = result;
          items[i].imgPath = result;
          items[i].genImage = true;
        }
      }

      this.panels[panelIndex].panelItems = items;
      this.panels = [...this.panels];

      /* console.log(resources);
                const panelIndex = this.panels.findIndex(panel => panel.id === panelId);

                const stageBase64 = await this.$lib.app.extractStage();

                for (let i = 0; i < this.panels[panelIndex].panelItems.length; i++) {
                  console.log(this.panels[panelIndex].panelItems[i].url);
                  this.panels[panelIndex].panelItems[i].st = `
                    mask-image: url("${this.panels[panelIndex].panelItems[i].url}");
                    mask-size: 186px 124px;
                    -webkit-mask-image: url(${this.panels[panelIndex].panelItems[i].url});
                    -webkit-mask-size: 186px 124px;
                  `;

                  this.panels[panelIndex].panelItems[i].imgPath = stageBase64;
                } */
    },

    async panelPropChange() {
      this.appliedPresetId = false;
      await this.discardChanges();

      this.panelOpened = "";
      this.panelClass = "";
      this.panelLoading = true;
      this.popUpVisible = false;
    },

    async favoritesOpen() {
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      this.panelOpened = "Favorites";
      this.panelClass = "active";
      this.panelLoading = true;
      const stageBase64 = await this.$lib._cache.getLastThumbBase64(); //await this.$lib.app.extractStage();
      const cachedResource = await this.$lib.app.addToLoader(
        `${Date.now()}-thumb`,
        stageBase64
      );

      for (let i = 0; i < this.favoritesList.length; i++) {
        let preset = this.favoritesList[i];
        let STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(preset.thumb);

        if (!STexture) return;
        const result = await this.$lib.Mask.previewByParams(
          cachedResource.texture.clone(),
          STexture, JSON.parse(
            JSON.stringify({
              ...(preset.params || {}),
              opacity: this.defaultPopMaskConfig.opacity,
              x: this.defaultPopMaskConfig.x,
              y: this.defaultPopMaskConfig.y,
            })
          )
        );

        //this.favoritesList[i].url = result;
        this.favoritesList[i].imgPath = result;
      }

      this.panelLoading = false;
    },
  },
};
</script>
<style scoped>
  .hidden {
    display: none;
  }
</style>
