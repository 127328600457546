var Acc = function () {
	this.login = async (data) => {
		let resp = await sendPost("/login3", data);
		if(resp.data && resp.data.user){
			window.session_id = resp.data.user.session_id;
			window.user_id = resp.data.user.id;
			loginSocket();
		}
		return resp;
	};
	
	this.setupTrialPlan = async (data) => {
		let resp = await sendPost("/trial-plan", data);
		return resp;
	};
	
	this.register = async (data) => {
		let resp = await sendPost("/register_free_user", data);
		if(resp.data && resp.data.user){
			window.session_id = resp.data.user.session_id;
			window.user_id = resp.data.user.id;
			loginSocket();
		}
		return resp;
	};

	this.registerCheckout = async (data) => {
		let resp = await sendPost("/register_after_checkout", data);
		if(resp.data && resp.data.user){
			window.session_id = resp.data.user.session_id;
			window.user_id = resp.data.user.id;
			loginSocket();
		}
		return resp;
	};

	this.registerBeforeCheckout = async (data) => {
		let resp = await sendPost("/register_before_checkout", data);
		if(resp.data && resp.data.user){
			window.session_id = resp.data.user.session_id;
			window.user_id = resp.data.user.id;
			loginSocket();
		}
		return resp;
	};

	this.logout = () => {

	};

	this.getCardInfo = async () => {
		return await sendPost("/get_card", {});
	};
	this.updateUser = async (field = "email", value = "", password="") => {
		return await sendPost("/update_user", { "field": field, "value": value, "current_password": password });
	};
	this.removeAccount = async params => {
		return await sendPost("/remove_account", params || {});
	};
	this.cancelSubscription = async params => {
		return await sendPost("/cancel_subscribe", params || {});
	};

	this.createCustomer = async data => {
		return await sendPost("/create_customer", data || {});
	};
	
	this.updateCard = async data => {
		return await sendPost("/update_card", data || {});
	};

	this.resendVerificationEmail = async () => {
		return await sendPost("/resend_verification");
	};
	
	this.resetPassword = async email => {
		return await sendPost("/reset_password", { email });
	};

	//TODO допилить
	this.changePlan = async (plan_id = 0) => {
		return await sendPost("/change_subscribe", { plan_id: plan_id });
	};

	this.getPlans = async () => {
		return await sendPost("/get_plans");
	};

	return this;
}

async function sendPost(url, data = {}) {
	data['_token'] = $("[name='csrf-token']").attr("content");
	try {
		var temp = await $.ajax({
			url: window.prefixURL + url,
			data: data,
			method: "POST",
			dataType: "json",
		});
		return temp;
	} catch (e) {
		return new Promise(function (resolve, reject) {
			resolve(JSON.parse(e.responseText));
		});
	}

}

export default Acc;
